/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  PayoutHistoryResponseDto,
  StripeMetricsControllerGetMetricsParams,
  StripeMetricsControllerGetPayoutsMetricsParams,
  StripeMetricsControllerGetTransactionHistoryParams,
  StripeMetricsResponseDto,
  TransactionHistoryResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieves various financial and user metrics for the specified brand, allowing customizable comparison periods.
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const stripeMetricsControllerGetMetrics = (
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeMetricsResponseDto>(
    {
      url: `/stripe-metrics/metrics/${encodeURIComponent(String(brandId))}`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getStripeMetricsControllerGetMetricsQueryKey = (
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
) => {
  return [
    `/stripe-metrics/metrics/${brandId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getStripeMetricsControllerGetMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetMetrics(brandId, params, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetMetricsQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
>;
export type StripeMetricsControllerGetMetricsQueryError = unknown;

export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetMetricsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getStripeMetricsControllerGetMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const prefetchStripeMetricsControllerGetMetrics = async <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeMetricsControllerGetMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetMetricsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetMetrics(brandId, params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetMetricsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>
>;
export type StripeMetricsControllerGetMetricsSuspenseQueryError = unknown;

export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetMetricsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getStripeMetricsControllerGetMetricsSuspenseQueryOptions(
    brandId,
    params,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves various financial and user metrics for the specified brand, allowing customizable comparison periods.
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const stripeMetricsControllerGetPayoutsMetrics = (
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeMetricsResponseDto>(
    {
      url: `/stripe-metrics/payouts-metrics/${encodeURIComponent(String(brandId))}`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getStripeMetricsControllerGetPayoutsMetricsQueryKey = (
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
) => {
  return [
    `/stripe-metrics/payouts-metrics/${brandId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getStripeMetricsControllerGetPayoutsMetricsQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutsMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutsMetrics(
      brandId,
      params,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetPayoutsMetricsQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
>;
export type StripeMetricsControllerGetPayoutsMetricsQueryError = unknown;

export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetPayoutsMetricsParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetPayoutsMetrics<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getStripeMetricsControllerGetPayoutsMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Retrieve Stripe metrics for a specific brand
 */
export const prefetchStripeMetricsControllerGetPayoutsMetrics = async <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeMetricsControllerGetPayoutsMetricsQueryOptions(
    brandId,
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetPayoutsMetricsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutsMetricsQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutsMetrics(
      brandId,
      params,
      requestOptions,
      signal,
    );

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetPayoutsMetricsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>
  >;
export type StripeMetricsControllerGetPayoutsMetricsSuspenseQueryError =
  unknown;

export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetPayoutsMetricsParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Retrieve Stripe metrics for a specific brand
 */

export function useStripeMetricsControllerGetPayoutsMetricsSuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
  TError = unknown,
>(
  brandId: string,
  params?: StripeMetricsControllerGetPayoutsMetricsParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutsMetrics>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getStripeMetricsControllerGetPayoutsMetricsSuspenseQueryOptions(
      brandId,
      params,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get transaction history for creator
 */
export const stripeMetricsControllerGetTransactionHistory = (
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<TransactionHistoryResponseDto>(
    {
      url: `/stripe-metrics/transaction-history/${encodeURIComponent(String(brandId))}`,
      method: 'GET',
      params,
      signal,
    },
    options,
  );
};

export const getStripeMetricsControllerGetTransactionHistoryQueryKey = (
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
) => {
  return [
    `/stripe-metrics/transaction-history/${brandId}`,
    ...(params ? [params] : []),
  ] as const;
};

export const getStripeMetricsControllerGetTransactionHistoryQueryOptions = <
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetTransactionHistoryQueryKey(brandId, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>
  > = ({ signal }) =>
    stripeMetricsControllerGetTransactionHistory(
      brandId,
      params,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetTransactionHistoryQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>
  >;
export type StripeMetricsControllerGetTransactionHistoryQueryError = void;

export function useStripeMetricsControllerGetTransactionHistory<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetTransactionHistoryParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
          >,
          TError,
          Awaited<
            ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetTransactionHistory<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
          >,
          TError,
          Awaited<
            ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeMetricsControllerGetTransactionHistory<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get transaction history for creator
 */

export function useStripeMetricsControllerGetTransactionHistory<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getStripeMetricsControllerGetTransactionHistoryQueryOptions(
      brandId,
      params,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get transaction history for creator
 */
export const prefetchStripeMetricsControllerGetTransactionHistory = async <
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getStripeMetricsControllerGetTransactionHistoryQueryOptions(
      brandId,
      params,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetTransactionHistorySuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
    >,
    TError = void,
  >(
    brandId: string,
    params?: StripeMetricsControllerGetTransactionHistoryParams,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getStripeMetricsControllerGetTransactionHistoryQueryKey(brandId, params);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>
    > = ({ signal }) =>
      stripeMetricsControllerGetTransactionHistory(
        brandId,
        params,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type StripeMetricsControllerGetTransactionHistorySuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeMetricsControllerGetTransactionHistory>>
  >;
export type StripeMetricsControllerGetTransactionHistorySuspenseQueryError =
  void;

export function useStripeMetricsControllerGetTransactionHistorySuspense<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params: undefined | StripeMetricsControllerGetTransactionHistoryParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetTransactionHistorySuspense<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetTransactionHistorySuspense<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get transaction history for creator
 */

export function useStripeMetricsControllerGetTransactionHistorySuspense<
  TData = Awaited<
    ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
  >,
  TError = void,
>(
  brandId: string,
  params?: StripeMetricsControllerGetTransactionHistoryParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof stripeMetricsControllerGetTransactionHistory>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getStripeMetricsControllerGetTransactionHistorySuspenseQueryOptions(
      brandId,
      params,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get payout history for creator
 */
export const stripeMetricsControllerGetPayoutHistory = (
  brandId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PayoutHistoryResponseDto>(
    {
      url: `/stripe-metrics/${encodeURIComponent(String(brandId))}/payout-history`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getStripeMetricsControllerGetPayoutHistoryQueryKey = (
  brandId: string,
) => {
  return [`/stripe-metrics/${brandId}/payout-history`] as const;
};

export const getStripeMetricsControllerGetPayoutHistoryQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutHistoryQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutHistory(brandId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetPayoutHistoryQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
>;
export type StripeMetricsControllerGetPayoutHistoryQueryError = void;

export function useStripeMetricsControllerGetPayoutHistory<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutHistory<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
          TError,
          Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeMetricsControllerGetPayoutHistory<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get payout history for creator
 */

export function useStripeMetricsControllerGetPayoutHistory<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getStripeMetricsControllerGetPayoutHistoryQueryOptions(
    brandId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get payout history for creator
 */
export const prefetchStripeMetricsControllerGetPayoutHistory = async <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeMetricsControllerGetPayoutHistoryQueryOptions(
    brandId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeMetricsControllerGetPayoutHistorySuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeMetricsControllerGetPayoutHistoryQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
  > = ({ signal }) =>
    stripeMetricsControllerGetPayoutHistory(brandId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeMetricsControllerGetPayoutHistorySuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>
  >;
export type StripeMetricsControllerGetPayoutHistorySuspenseQueryError = void;

export function useStripeMetricsControllerGetPayoutHistorySuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutHistorySuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeMetricsControllerGetPayoutHistorySuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get payout history for creator
 */

export function useStripeMetricsControllerGetPayoutHistorySuspense<
  TData = Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeMetricsControllerGetPayoutHistory>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getStripeMetricsControllerGetPayoutHistorySuspenseQueryOptions(
      brandId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
