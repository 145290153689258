import { zodResolver } from '@hookform/resolvers/zod';
import { TabsContent } from '@radix-ui/react-tabs';
import { useQueryClient } from '@tanstack/react-query';
import { CopyIcon, MoreVertical, PencilIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import {
  PriceVariantDto,
  SpaceGroupWithSpaces,
  UpdateSpaceGroupMonetizationSettingsDto,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
  UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
  UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useSpaceGroupControllerUpdateSettings,
  useSpaceGroupControllerUpdateSpaceGroup,
} from '@/api';
import { usePriceVariantControllerGetPriceVariants } from '@/api/generated/api/price-variants/price-variants';
import { CalendarRoudedIcon } from '@/assets/icon/calendarRounded';
import { ChatBubbleEllipsisIcon } from '@/assets/icon/chat-bubble-ellipsis';
import { PlusIcon } from '@/assets/icon/plusIcon';
import { Button } from '@/components/ui/button';
import {
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  Form,
  FormControl,
  FormField,
  FormInput,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toast } from '@/hooks/use-toast';
import { cn, onlyFirstLetterCapital, valueOrUndefined } from '@/lib/utils';
import { CreatePricingVariant } from '@/modules/spaceGroups/steps';

import InputField from './ui/InputField';
import { Switch } from './ui/switch';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';

// Constantes y tipos
const PRICINGS = ['FREE', 'PAID'] as const;
const BILLING_FREQUENCIES = ['one-time', 'weekly', 'monthly'] as const;
type DialogView = 'settings' | 'createPaymentLink' | 'editPaymentLink';

// Esquema de validación del formulario
const formSchema = z
  .object({
    name: z.string().min(1, {
      message: 'Name is required',
    }),
    pricing: z.enum(PRICINGS).optional(),
    price: z.coerce
      .number({
        message: 'Price must be a number',
      })
      .optional(),
    billingFrequency: z.enum(BILLING_FREQUENCIES).optional(),
    isPrivate: z.boolean().optional(),
  })
  .refine(
    (data) => {
      return data.pricing === 'FREE' || data.billingFrequency;
    },
    {
      message: 'Billing frequency is required for paid plans',
      path: ['billingFrequency'],
    },
  )
  .refine(
    (data) => {
      return data.pricing === 'FREE' || data.price;
    },
    {
      message: 'Price is required for paid plans',
      path: ['price'],
    },
  );

type FormSchema = z.infer<typeof formSchema>;

// Mapeo de valores del servidor a valores del formulario
const mapBillingFrequencyFromServer = (
  billingFrequency: string | null | undefined,
): (typeof BILLING_FREQUENCIES)[number] | undefined => {
  switch (billingFrequency) {
    case 'ONE_TIME_PAYMENT':
      return 'one-time';
    case 'WEEKLY':
      return 'weekly';
    case 'MONTHLY':
      return 'monthly';
    default:
      return undefined;
  }
};

// Mapeo de valores del formulario a valores para el servidor
const mapBillingFrequencyToServer = (
  billingFrequency: string | null | undefined,
): string | undefined => {
  switch (billingFrequency) {
    case 'one-time':
      return 'ONE_TIME_PAYMENT';
    case 'weekly':
      return 'WEEKLY';
    case 'monthly':
      return 'MONTHLY';
    default:
      return undefined;
  }
};

// Componente principal
export const UpdateSpaceGroupDialog = ({
  spaceGroup,
  onClose,
}: {
  spaceGroup: SpaceGroupWithSpaces;
  onClose: () => void;
}) => {
  // Hooks y estado
  const updateSpaceGroupMutation = useSpaceGroupControllerUpdateSpaceGroup();
  const updateSpaceGroupMonetizationMutation =
    useSpaceGroupControllerUpdateSettings();
  const queryClient = useQueryClient();
  const [currentView, setCurrentView] = useState<DialogView>('settings');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState('general');

  const [selectedVariant, setSelectedVariant] =
    useState<PriceVariantDto | null>(null);

  // Cargar datos de price variants
  const {
    data: priceVariants,
    isLoading: isPriceVariantsLoading,
    refetch: refetchPriceVariants,
  } = usePriceVariantControllerGetPriceVariants(spaceGroup.id);

  // Estado para configuración de monetización
  const [monetizationData, setMonetizationData] =
    useState<UpdateSpaceGroupMonetizationSettingsDto>();

  // Inicializar datos de monetización al cargar el componente
  useEffect(() => {
    setMonetizationData({
      allowDirectMessaging: spaceGroup.allowDirectMessaging,
      messageFrequencyType: spaceGroup.messageFrequencyType || 'CAPPED',
      freeMessagesLimit: spaceGroup.freeMessagesLimit ?? 0,
      freeMessagesFrequency: spaceGroup.freeMessagesFrequency || 'PER_DAY',
      allowCallMeetings: spaceGroup.allowCallMeetings,
      freeCallsLimit: spaceGroup.freeCallsLimit ?? 0,
      freeCallsFrequency: spaceGroup.freeCallsFrequency || 'PER_DAY',
      freeCallDuration: spaceGroup.freeCallDuration ?? 0,
      freeCallDurationType: spaceGroup.freeCallDurationType || 'MINUTES',
    });
  }, [spaceGroup]);

  // Configuración y estado del formulario
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: spaceGroup.name,
      pricing: spaceGroup.pricingType,
      price: spaceGroup.price ?? undefined,
      billingFrequency: mapBillingFrequencyFromServer(
        spaceGroup.billingFrequency,
      ),
      isPrivate: spaceGroup.isPrivate ?? false,
    },
  });

  // Guardar configuración de monetización
  const handleSaveMonetizationSettings = async (): Promise<void> => {
    if (!monetizationData) return;

    try {
      await updateSpaceGroupMonetizationMutation.mutateAsync({
        spaceGroupId: spaceGroup.id,
        data: monetizationData,
      });
    } catch (error) {
      console.error('Error saving monetization settings:', error);
      throw error; // Re-throw to handle in the parent function
    }
  };

  // Manejar envío del formulario
  const handleSubmit = form.handleSubmit(async (data) => {
    // Verificar si ha habido cambios
    if (
      data.name === spaceGroup.name &&
      data.pricing === valueOrUndefined(spaceGroup.pricingType) &&
      data.price === valueOrUndefined(spaceGroup.price) &&
      data.billingFrequency ===
        mapBillingFrequencyFromServer(spaceGroup.billingFrequency) &&
      monetizationData?.allowCallMeetings === spaceGroup.allowCallMeetings &&
      monetizationData?.allowDirectMessaging ===
        spaceGroup.allowDirectMessaging &&
      monetizationData?.freeCallDuration === spaceGroup.freeCallDuration &&
      monetizationData?.freeCallDurationType ===
        spaceGroup.freeCallDurationType &&
      monetizationData?.freeCallsFrequency === spaceGroup.freeCallsFrequency &&
      monetizationData?.freeCallsLimit === spaceGroup.freeCallsLimit &&
      monetizationData?.freeMessagesFrequency ===
        spaceGroup.freeMessagesFrequency &&
      monetizationData?.freeMessagesLimit === spaceGroup.freeMessagesLimit &&
      monetizationData?.messageFrequencyType ===
        spaceGroup.messageFrequencyType &&
      data.isPrivate === spaceGroup.isPrivate
    ) {
      clearFormAndCloseDialog();
      return;
    }

    setIsSubmitting(true);

    try {
      const pricingType = data.pricing;
      const isPaid = pricingType === 'PAID';
      const price = isPaid ? data.price : null;
      const billingFrequency = isPaid
        ? mapBillingFrequencyToServer(data.billingFrequency)
        : null;

      // Actualizar spaceGroup
      await updateSpaceGroupMutation.mutateAsync({
        spaceGroupId: spaceGroup.id,
        data: {
          name: data.name,
          pricingType,
          price: price as number | undefined,
          billingFrequency: billingFrequency as string | undefined,
          isPrivate: data.isPrivate,
        },
      });

      // Actualizar configuración de monetización
      await handleSaveMonetizationSettings();

      // Invalidar consultas para actualizar la UI
      await queryClient.invalidateQueries({
        queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(
          spaceGroup.brandId,
        ),
      });

      toast({
        title: 'Settings updated',
        description: 'Space group settings have been updated successfully.',
        variant: 'success',
      });

      clearFormAndCloseDialog();
    } catch (error: any) {
      toast({
        title: 'Update failed',
        description:
          error?.message || 'An error occurred while updating settings.',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
  });

  // Limpiar formulario y cerrar diálogo
  const clearFormAndCloseDialog = () => {
    form.reset();
    onClose();
  };

  // Funciones para gestión de payment links
  const handleAddPaymentLink = () => {
    setCurrentView('createPaymentLink');
  };

  const handleReturnToSettings = async () => {
    setCurrentView('settings');
    // Refrescar explícitamente los datos de price variants
    await refetchPriceVariants();
  };

  const handleReturnToPaymentLink = async () => {
    setCurrentView('settings');
    setActiveTab('payment-links');
    // Refrescar explícitamente los datos de price variants
    await refetchPriceVariants();
  };

  // Funciones para acciones en dropdown de payment links
  const handleCopyLink = (variantId: string) => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const linkUrl = `${protocol}//${host}/checkout/brands/${spaceGroup.brandId}/space-groups/${spaceGroup.id}?variantId=${variantId}`;

    navigator.clipboard.writeText(linkUrl);
    toast({
      title: 'Link copied',
      description: 'Payment link copied to clipboard.',
    });
  };

  const handleEditLink = (variantId: string) => {
    const foundVariant = priceVariants?.find((v) => v.id === variantId);
    if (foundVariant) {
      setSelectedVariant(foundVariant as PriceVariantDto);
      setCurrentView('editPaymentLink');
    }
  };

  const isPricingTypePaid = form.watch('pricing') === 'PAID';

  // Renderizado condicional basado en la vista actual
  if (currentView === 'createPaymentLink') {
    return (
      <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
        <CreatePricingVariant
          provvisionalSpaceGroupId={spaceGroup.id}
          onClose={handleReturnToSettings}
          onBack={handleReturnToSettings}
        />
      </DialogContent>
    );
  }

  if (currentView === 'editPaymentLink' && selectedVariant) {
    return (
      <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
        <CreatePricingVariant
          provvisionalSpaceGroupId={spaceGroup.id}
          onClose={handleReturnToPaymentLink}
          onBack={handleReturnToPaymentLink}
          mode="edit"
          variantToEdit={selectedVariant as any}
        />
      </DialogContent>
    );
  }

  // Vista principal de configuración
  return (
    <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="text-2xl font-semibold">Settings</DialogTitle>
        <Button
          onClick={clearFormAndCloseDialog}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>

      <div className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
        <Form {...form}>
          <form onSubmit={handleSubmit} className="space-y-5">
            <Tabs
              defaultValue={activeTab}
              className="h-fit w-full"
              onValueChange={setActiveTab}
            >
              <TabsList className="flex justify-start gap-8 rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
                <TabsTrigger
                  value="general"
                  className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
                >
                  General
                </TabsTrigger>
                <TabsTrigger
                  value="monetization"
                  className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
                >
                  Monetization
                </TabsTrigger>
                <TabsTrigger
                  value="payment-links"
                  className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
                >
                  Payment Links
                </TabsTrigger>
              </TabsList>

              {/* Pestaña General */}
              <TabsContent value="general" className="space-y-5 py-5">
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field, fieldState }) => (
                    <FormItem className="space-y-1">
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Name
                      </FormLabel>
                      <FormControl>
                        <FormInput
                          type="text"
                          placeholder="Enter brand name"
                          className={cn(
                            'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="pricing"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel className="text-base font-medium text-black dark:text-white">
                        Pricing
                      </FormLabel>
                      <FormControl>
                        <div className="space-x-2">
                          {PRICINGS.map((pricing) => (
                            <Button
                              type="button"
                              key={pricing}
                              onClick={() => field.onChange(pricing)}
                              variant={
                                field.value === pricing ? 'default' : 'outline'
                              }
                              disabled={
                                spaceGroup.pricingType === 'PAID' &&
                                pricing === 'FREE'
                              } // Disable "FREE" if current pricing is "PAID"
                            >
                              <span>{onlyFirstLetterCapital(pricing)}</span>
                            </Button>
                          ))}
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="price"
                  render={({ field, fieldState }) => (
                    <FormItem
                      className={cn(
                        'space-y-1',
                        form.watch('pricing') === 'FREE' && 'hidden',
                      )}
                    >
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Price
                      </FormLabel>
                      <FormControl>
                        <FormInput
                          type="text"
                          placeholder="100"
                          className={cn(
                            'p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="billingFrequency"
                  render={({ field, fieldState }) => (
                    <FormItem
                      className={cn(
                        'space-y-1',
                        form.watch('pricing') === 'FREE' && 'hidden',
                      )}
                    >
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Billing frequency
                      </FormLabel>
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger
                            className={cn(
                              'h-12 text-base text-black dark:text-white',
                              fieldState.error &&
                                'border-destructive dark:border-destructive',
                            )}
                          >
                            <SelectValue />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          {BILLING_FREQUENCIES.map((billingFrequency) => (
                            <SelectItem
                              key={billingFrequency}
                              value={billingFrequency}
                            >
                              {onlyFirstLetterCapital(billingFrequency)} Payment
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="isPrivate"
                  render={({ field, fieldState }) => (
                    <FormItem className="space-y-1">
                      <FormLabel
                        className={cn(
                          'text-base font-medium text-black dark:text-white',
                          fieldState.error &&
                            'text-destructive dark:text-destructive',
                        )}
                      >
                        Visibility
                      </FormLabel>
                      <FormControl>
                        <div className="flex items-center justify-between">
                          <span className="text-sm text-black dark:text-white">
                            Hidden for non-members
                          </span>
                          <Switch
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </TabsContent>

              {/* Pestaña Monetization */}
              <TabsContent value="monetization" className="space-y-5 py-5">
                <div className="flex w-full flex-col gap-8 font-medium">
                  {/* Direct Messaging Section */}
                  <div className="flex w-full items-center gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FFDAF5]">
                      <ChatBubbleEllipsisIcon className="h-6 w-6 stroke-[#EF38BE]" />
                    </div>
                    <div className="w-full text-lg font-medium">
                      Direct messaging
                    </div>
                    <Switch
                      checked={monetizationData?.allowDirectMessaging ?? false}
                      onClick={() => {
                        setMonetizationData({
                          ...monetizationData,
                          allowDirectMessaging:
                            !monetizationData?.allowDirectMessaging,
                        });
                      }}
                    />
                  </div>

                  {monetizationData?.allowDirectMessaging && (
                    <div className="flex w-full flex-col gap-8">
                      <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
                        Configure messaging
                      </h3>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Set messaging frequency
                        </div>
                        <Select
                          value={monetizationData?.messageFrequencyType}
                          onValueChange={(value) => {
                            setMonetizationData({
                              ...monetizationData,
                              messageFrequencyType: value as
                                | 'CAPPED'
                                | 'UNLIMITED',
                            });
                          }}
                        >
                          <SelectTrigger className="h-fit border-light p-4 capitalize dark:border-dark-light">
                            {monetizationData?.messageFrequencyType?.toLowerCase() ??
                              'Select frequency'}
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value="CAPPED">Capped</SelectItem>
                            <SelectItem value="UNLIMITED">Unlimited</SelectItem>
                          </SelectContent>
                        </Select>
                      </div>

                      {monetizationData?.messageFrequencyType === 'CAPPED' && (
                        <div className="flex flex-col gap-2.5">
                          <div className="text-base font-medium">
                            Set number of messages allowed
                          </div>
                          <div className="flex gap-2.5">
                            <InputField
                              type="number"
                              regex={/^\d+$/}
                              value={monetizationData?.freeMessagesLimit}
                              onChange={(e) => {
                                setMonetizationData({
                                  ...monetizationData,
                                  freeMessagesLimit: parseInt(e.target.value),
                                });
                              }}
                              inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                              className="!gap-0"
                            />
                            <Select
                              value={monetizationData?.freeMessagesFrequency}
                              onValueChange={(value) => {
                                setMonetizationData({
                                  ...monetizationData,
                                  freeMessagesFrequency:
                                    value as UpdateSpaceGroupMonetizationSettingsDtoFreeMessagesFrequency,
                                });
                              }}
                            >
                              <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                                {monetizationData?.freeMessagesFrequency
                                  ?.replace('_', ' ')
                                  .toLowerCase() ?? 'Select type'}
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="PER_DAY">Per day</SelectItem>
                                <SelectItem value="PER_WEEK">
                                  Per week
                                </SelectItem>
                                <SelectItem value="PER_MONTH">
                                  Per month
                                </SelectItem>
                                <SelectItem value="PER_YEAR">
                                  Per year
                                </SelectItem>
                                <SelectItem value="TOTAL">Total</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Calendar Access Section */}
                  <div className="flex w-full items-center gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FBEDFF]">
                      <CalendarRoudedIcon className="h-6 w-6 stroke-[#C413F5]" />
                    </div>
                    <div className="w-full text-lg font-medium">
                      Calendar access
                    </div>
                    <Switch
                      checked={monetizationData?.allowCallMeetings ?? false}
                      onClick={() => {
                        setMonetizationData({
                          ...monetizationData,
                          allowCallMeetings:
                            !monetizationData?.allowCallMeetings,
                        });
                      }}
                    />
                  </div>

                  {monetizationData?.allowCallMeetings && (
                    <div className="flex w-full flex-col gap-8">
                      <h3 className="text-lg font-medium text-textParagraph dark:text-dark-textParagraph">
                        Configure booking
                      </h3>
                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Allow each member to book
                        </div>
                        <div className="flex gap-2.5">
                          <InputField
                            type="number"
                            regex={/^\d+$/}
                            value={monetizationData?.freeCallsLimit}
                            onChange={(e) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallsLimit: parseInt(e.target.value),
                              });
                            }}
                            inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                            className="!gap-0"
                          />
                          <Select
                            value={monetizationData?.freeCallsFrequency}
                            onValueChange={(value) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallsFrequency:
                                  value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallsFrequency,
                              });
                            }}
                          >
                            <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                              {monetizationData?.freeCallsFrequency
                                ?.replace('_', ' ')
                                .toLowerCase() ?? 'Select type'}
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="PER_DAY">Per day</SelectItem>
                              <SelectItem value="PER_WEEK">Per week</SelectItem>
                              <SelectItem value="PER_MONTH">
                                Per month
                              </SelectItem>
                              <SelectItem value="PER_YEAR">Per year</SelectItem>
                              <SelectItem value="TOTAL">Total</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>

                      <div className="flex flex-col gap-2.5">
                        <div className="text-base font-medium">
                          Set call duration
                        </div>
                        <div className="flex gap-2.5">
                          <InputField
                            type="number"
                            regex={/^\d+$/}
                            value={monetizationData?.freeCallDuration}
                            onChange={(e) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallDuration: parseInt(e.target.value),
                              });
                            }}
                            inputClassName="!bg-transparent border-light dark:border-dark-light !p-2.5"
                            className="!gap-0"
                          />
                          <Select
                            value={monetizationData?.freeCallDurationType}
                            onValueChange={(value) => {
                              setMonetizationData({
                                ...monetizationData,
                                freeCallDurationType:
                                  value as UpdateSpaceGroupMonetizationSettingsDtoFreeCallDurationType,
                              });
                            }}
                          >
                            <SelectTrigger className="h-fit border-light p-2.5 text-base capitalize dark:border-dark-light">
                              {monetizationData?.freeCallDurationType?.toLowerCase() ??
                                'Select type'}
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="MINUTES">Minutes</SelectItem>
                              <SelectItem value="HOURS">Hours</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </TabsContent>
              {/* Pestaña Payment Links */}
              <TabsContent value="payment-links" className="space-y-5 py-5">
                <div className="flex w-full flex-col gap-5">
                  <div className="flex items-center justify-between">
                    <h3 className="text-lg font-medium text-black dark:text-white">
                      Payment Links
                    </h3>
                  </div>

                  {!isPricingTypePaid && (
                    <div className="rounded-md bg-yellow-50 p-4 text-sm text-yellow-800 dark:bg-yellow-900/30 dark:text-yellow-200">
                      Payment links are only available for paid space groups.
                      Change the pricing type to "Paid" in the General tab to
                      add payment links.
                    </div>
                  )}

                  {isPricingTypePaid && isPriceVariantsLoading && (
                    <div className="flex justify-center py-8 text-gray-500">
                      Loading payment links...
                    </div>
                  )}

                  {isPricingTypePaid &&
                    !isPriceVariantsLoading &&
                    (!priceVariants || priceVariants.length === 0) && (
                      <div className="rounded-md bg-gray-50 p-6 text-center text-gray-500 dark:bg-gray-800/30">
                        No payment links available. Click "Add Payment Link" to
                        create one.
                      </div>
                    )}

                  {isPricingTypePaid &&
                    !isPriceVariantsLoading &&
                    priceVariants &&
                    priceVariants.length > 0 && (
                      <div className="flex flex-col gap-4">
                        {priceVariants.map((variant) => {
                          // Verificamos si hay split payments
                          const splitPaymentsCount =
                            typeof variant.numberOfSplitPayments === 'number'
                              ? variant.numberOfSplitPayments
                              : 0;

                          const hasSplitPayments =
                            variant.billingType === 'ONE_TIME_PAYMENT' &&
                            splitPaymentsCount > 1;

                          // Precio total (por ejemplo, 100)
                          const totalPrice = variant.price || 0;
                          // Precio por pago (por ejemplo, 50 si son 2 pagos)
                          const pricePerPayment =
                            hasSplitPayments && splitPaymentsCount > 0
                              ? totalPrice / splitPaymentsCount
                              : totalPrice;

                          // Determinamos la frecuencia de pago (weekly/monthly) para mostrárselo al usuario
                          const frequencyText =
                            variant.splitPaymentPeriod === 'WEEK'
                              ? 'weekly'
                              : 'monthly';

                          return (
                            <div
                              key={variant.id}
                              className="flex items-center justify-between rounded-lg border border-gray-200 p-4 dark:border-gray-700"
                            >
                              <div className="flex flex-col gap-2">
                                <span className="font-medium text-black dark:text-white">
                                  {variant.name || 'Unnamed Link'}
                                </span>

                                {/* Precio total */}
                                <span className="text-sm text-gray-500">
                                  Price: ${totalPrice}
                                </span>

                                {/* Tipo de facturación: ONE_TIME_PAYMENT o RECURRING */}
                                <div className="mt-1 flex items-center">
                                  <div
                                    className={`mr-2 h-2 w-2 rounded-full ${
                                      variant.billingType === 'ONE_TIME_PAYMENT'
                                        ? 'bg-blue-500'
                                        : 'bg-purple-500'
                                    }`}
                                  />
                                  <span className="text-sm font-medium">
                                    {variant.billingType === 'ONE_TIME_PAYMENT'
                                      ? 'One Time Payment'
                                      : 'Recurring Payment'}
                                  </span>
                                </div>

                                {/* Informacion de pagos RECURRING (subscriptionPeriod) */}
                                {variant.billingType === 'RECURRING' &&
                                  variant.subscriptionPeriod && (
                                    <div className="ml-4 text-sm">
                                      <span className="inline-block rounded bg-gray-100 px-2 py-1 text-gray-700 dark:bg-gray-800 dark:text-gray-300">
                                        {variant.subscriptionPeriod ===
                                          'DAY_7' && 'Billed weekly'}
                                        {variant.subscriptionPeriod ===
                                          'MONTH_1' && 'Billed Monthly'}
                                        {variant.subscriptionPeriod ===
                                          'MONTH_3' && 'Billed every 3 Months'}
                                        {variant.subscriptionPeriod ===
                                          'MONTH_6' && 'Billed every 6 Months'}
                                        {variant.subscriptionPeriod ===
                                          'YEAR_1' && 'Billed Annually'}
                                        {variant.subscriptionPeriod ===
                                          'CUSTOM' && 'Custom billing period'}
                                        {variant.subscriptionPeriod ===
                                          'NEVER' && 'No recurring billing'}
                                      </span>
                                    </div>
                                  )}

                                {/* Informacion de SPLIT PAYMENTS cuando es ONE_TIME_PAYMENT y >1 pagos */}
                                {variant.billingType === 'ONE_TIME_PAYMENT' &&
                                  hasSplitPayments && (
                                    <div className="ml-4 text-sm text-gray-500 dark:text-gray-400">
                                      <span>
                                        (
                                        {`$${pricePerPayment.toFixed(2)} ${frequencyText} × ${splitPaymentsCount} payments`}
                                        )
                                      </span>
                                    </div>
                                  )}
                              </div>

                              <div className="flex items-center gap-2">
                                <DropdownMenu>
                                  <DropdownMenuTrigger asChild>
                                    <Button
                                      variant="ghost"
                                      className="h-8 w-8 p-0"
                                    >
                                      <span className="sr-only">Open menu</span>
                                      <MoreVertical className="h-4 w-4" />
                                    </Button>
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent align="end">
                                    <DropdownMenuItem
                                      onClick={() => handleCopyLink(variant.id)}
                                      className="flex cursor-pointer items-center gap-2"
                                    >
                                      <CopyIcon className="h-4 w-4" />
                                      <span>Copy Link</span>
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                      onClick={() => handleEditLink(variant.id)}
                                      className="flex cursor-pointer items-center gap-2"
                                    >
                                      <PencilIcon className="h-4 w-4" />
                                      <span>Edit</span>
                                    </DropdownMenuItem>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                </div>

                {isPricingTypePaid && (
                  <Button
                    variant="outline"
                    onClick={handleAddPaymentLink}
                    className="flex items-center gap-2"
                  >
                    <PlusIcon className="h-5 w-5 dark:fill-white" />
                    Add Payment Link
                  </Button>
                )}
              </TabsContent>
            </Tabs>

            <Button
              type="submit"
              className="w-full rounded-lg"
              disabled={isSubmitting || updateSpaceGroupMutation.isPending}
            >
              {isSubmitting ? 'Saving...' : 'Continue'}
            </Button>
          </form>
        </Form>
      </div>
    </DialogContent>
  );
};
