import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  CourseWithChaptersAndLessons,
  DurationAvailability,
  MeetingCreatorDto,
  SpaceGroupResponseDto,
  checkoutEndpointControllerGetSpaceGroupWithVariant,
  coursesSpacesControllerGetCourseWithLessonsByCourseId,
  monetizationControllerBuyMessages,
  monetizationControllerCanBuyMessages,
  stripeControllerCreateCoursePaymentIntent,
  stripeControllerCreatePayment,
} from '@/api';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { CalendarIcon } from '@/assets/icon/calendar';
import { CalendarRoudedIcon } from '@/assets/icon/calendarRounded';
import { ChatIcon } from '@/assets/icon/chat';
import { ChatBubbleEllipsisIcon } from '@/assets/icon/chat-bubble-ellipsis';
import { CheckIcon } from '@/assets/icon/check-alt';
import { CourseSpaceIcon } from '@/assets/icon/course-space';
import { LockIcon } from '@/assets/icon/lock';
import { MembersSapceIcon } from '@/assets/icon/member-space';
import { PostIcon } from '@/assets/icon/post';
import CountryDropdown from '@/components/ui/CountryDropdown';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useContext';

import PaymentSuccessModal from './PaymentSuccessModal';

// ======= TYPES AND CONSTANTS =======
type CheckoutType = 'chat' | 'spaceGroup' | 'meet' | 'course';

type SpaceInfo = {
  name: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  color: string;
  background: string;
};

type PaymentFormData = {
  email: string;
  cardHolder: string;
  country: string;
  city: string;
  zipCode: string;
  address: string;
};

type MeetingData = {
  creator: MeetingCreatorDto;
  selectedDate: Date;
  selectedTime: Date;
  selectedDuration: number;
  selectedSlotDuration: DurationAvailability;
  clientSecret: string;
};

// Constants moved outside component
const SPACES: SpaceInfo[] = [
  {
    name: 'events',
    Icon: CalendarIcon,
    color: '#4D8EED',
    background: '#CDE3FD',
  },
  {
    name: 'posts',
    Icon: PostIcon,
    color: '#D55F2A',
    background: '#F4DDCF',
  },
  {
    name: 'chats',
    Icon: ChatIcon,
    color: '#65A96E',
    background: '#DDECE0',
  },
  {
    name: 'courses',
    Icon: CourseSpaceIcon,
    color: '#8A69E9',
    background: '#E7E2F4',
  },
  {
    name: 'members',
    Icon: MembersSapceIcon,
    color: '#F32222',
    background: '#FFD8D8',
  },
];

// ======= UTILITY FUNCTIONS =======
const generateUUID = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email) || email === '';
};

// ======= MAIN COMPONENT =======
const CheckoutPage = ({ type }: { type: CheckoutType }) => {
  // ======= HOOKS =======
  const { toast } = useToast();
  const stripe = useStripe();
  const elements = useElements();
  const { brandId, spaceGroupId, userId, creatorId, courseId } = useParams();
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // ======= URL PARAMS =======
  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get('ref');
  const variantId = searchParams.get('variantId');

  // ======= STATE =======
  const [promoCode, setPromoCode] = useState('');
  const [paymentData, setPaymentData] = useState<PaymentFormData>({
    email: '',
    cardHolder: '',
    country: 'United States',
    city: '',
    zipCode: '',
    address: '',
  });
  const [hasAgreed, setHasAgreed] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Type-specific states
  const [spaceGroupData, setSpaceGroupData] = useState<SpaceGroupResponseDto>();
  const [messagePrice, setMessagePrice] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(1);
  const [meetingData, setMeetingData] = useState<MeetingData>();
  const [meetingClientSecret, setMeetingClientSecret] = useState<string>();
  const [courseData, setCourseData] = useState<CourseWithChaptersAndLessons>();
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  // Session ID for checkout
  const customSessionId = useMemo(() => generateUUID(), []);

  // ======= EFFECTS =======
  // Initial data fetching based on checkout type
  useEffect(() => {
    const fetchInitialData = async () => {
      switch (type) {
        case 'spaceGroup':
          await handleFetchSpaceGroup();
          break;
        case 'chat':
          await handleFetchMessagePrice();
          break;
        case 'meet':
          setMeetingData(location.state as MeetingData);
          setMeetingClientSecret(location.state?.clientSecret);
          break;
        case 'course':
          await handleFetchCourse();
          break;
      }
    };

    fetchInitialData();
  }, [type]);

  // Set email from user data if authenticated
  useEffect(() => {
    if (isAuthenticated && user?.email) {
      setPaymentData((prev) => ({
        ...prev,
        email: user.email || '',
      }));
    }
  }, [isAuthenticated, user]);

  // ======= DATA FETCHING HANDLERS =======
  const handleFetchSpaceGroup = async () => {
    if (!brandId || !spaceGroupId) return;

    try {
      setLoading(true);

      // Only pass variantId parameter if it's a valid value (not null, undefined, or the string "undefined")
      let params = {};
      if (variantId && variantId !== 'undefined') {
        params = { variantId };
      }

      const response = await checkoutEndpointControllerGetSpaceGroupWithVariant(
        brandId,
        spaceGroupId,
        params,
      );

      setSpaceGroupData(response);
    } catch (error) {
      console.error('Error fetching space group:', error);
      toast({
        title: 'Error al cargar el espacio',
        variant: 'destructive',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFetchMessagePrice = async () => {
    if (!userId) return;

    try {
      const priceData = await monetizationControllerCanBuyMessages(userId);

      if (!priceData.canBuy) {
        toast({
          title: 'You cannot buy messages',
          variant: 'destructive',
        });
        navigate(`/brands/${brandId}`);
        return;
      }

      setMessagePrice(priceData.pricePerMessage);
    } catch (error) {
      console.error('Error fetching message price:', error);
      toast({
        title: 'Failed to fetch creator price',
        variant: 'destructive',
      });
    }
  };

  const handleFetchCourse = async () => {
    if (!courseId) return;

    try {
      const course =
        await coursesSpacesControllerGetCourseWithLessonsByCourseId(courseId);
      setCourseData(course);
    } catch (error) {
      console.error('Error fetching course:', error);
      toast({
        title: 'Failed to fetch course',
        variant: 'destructive',
      });
    }
  };

  // ======= FORM VALIDATION =======
  const validatePaymentForm = (): boolean => {
    if (!hasAgreed) {
      toast({
        title: 'Please accept the terms and conditions to proceed',
      });
      return false;
    }

    if (!validateEmail(paymentData.email)) {
      toast({
        title: 'Please enter a valid email address',
      });
      return false;
    }

    if (!paymentData.cardHolder) {
      toast({
        title: 'Please complete your card information',
      });
      return false;
    }

    const { country, city, zipCode, address } = paymentData;
    if (!country || !city || !zipCode || !address) {
      toast({
        title: 'Please complete your billing address',
      });
      return false;
    }

    return true;
  };

  // ======= PAYMENT HANDLERS =======
  const handlePay = async () => {
    console.log('handlePay');
    if (!validatePaymentForm()) return;
    switch (type) {
      case 'chat':
        await handleBuyMessages();
        break;
      case 'spaceGroup':
        await handleSubscribe();
        break;
      case 'meet':
        await handleBookNow();
        break;
      case 'course':
        await handleBuyCourse();
        break;
    }
  };

  const handleBuyMessages = async () => {
    try {
      setLoading(true);
      setError('');

      const response = await monetizationControllerBuyMessages({
        creatorId: userId!,
        quantity: messageCount,
      });

      await confirmPayment(
        response.clientSecret,
        `/brands/${brandId}/chats/${userId}`,
      );
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const handleSubscribe = async () => {
    if (!spaceGroupData?.id) return;
    try {
      setLoading(true);
      setError('');

      const response = await stripeControllerCreatePayment({
        address: paymentData.address,
        city: paymentData.city,
        country: paymentData.country,
        email: paymentData.email,
        cardHolder: paymentData.cardHolder,
        sessionId: customSessionId,
        userId: isAuthenticated ? user!.id : undefined,
        zipCode: paymentData.zipCode,
        spaceGroupId: spaceGroupData.id,
        priceId: spaceGroupData.stripePriceId,
        referralCode: referralCode || undefined,
        variantId: variantId || '',
      });

      await confirmPayment(
        response.clientSecret,
        `/brands/${brandId}/space-groups/${spaceGroupId}`,
      );
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const handleBookNow = async () => {
    try {
      setLoading(true);
      if (meetingClientSecret) {
        await confirmPayment(meetingClientSecret);
      }
    } catch (error) {
      setError('Failed to process payment');
      setLoading(false);
    }
  };

  const handleBuyCourse = async () => {
    if (!courseId || !user?.id) return;

    try {
      setLoading(true);
      setError('');

      const response = await stripeControllerCreateCoursePaymentIntent({
        courseId: courseId,
        userId: user.id,
      });

      await confirmPayment(response.clientSecret, '-1');
    } catch (error) {
      setError('Failed to process course payment');
      setLoading(false);
    }
  };

  const confirmPayment = async (clientSecret: string, redirectUrl?: string) => {
    if (!stripe || !elements) {
      setError('Payment system not available');
      setLoading(false);
      return;
    }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement) as StripeCardElement,
        billing_details: {
          email: paymentData.email,
        },
      },
    });

    if (result?.error) {
      setError(result.error.message || 'Error processing payment');
      setLoading(false);
    } else {
      // Process successful payment
      if (isAuthenticated) {
        toast({
          title: 'Processing your payment, it may take a while',
          variant: 'default',
          duration: 5000,
        });

        setTimeout(() => {
          if (redirectUrl === '-1') {
            navigate(-1);
          } else {
            navigate(redirectUrl || `/`);
          }
          setLoading(false);
        }, 5000);
      } else {
        setPaymentSuccessful(true);
        setLoading(false);
      }
    }
  };

  const handleContinueToRegister = () => {
    navigate(`/login?callbackUrl=/process-checkout/${customSessionId}`);
  };

  // ======= CALCULATION HELPERS =======
  const calculatePlatformFee = useMemo(() => {
    const platformFeePercent =
      (spaceGroupData?.brand.platformFee as number) / 100 || 0;

    // Check if this is a split payment
    const isSplitPayment =
      type === 'spaceGroup' &&
      spaceGroupData?.billingType === 'ONE_TIME_PAYMENT' &&
      spaceGroupData?.priceVariantNumberOfSplitPayments &&
      spaceGroupData?.priceVariantNumberOfSplitPayments > 1 &&
      spaceGroupData?.priceVariantPriceSplitted;

    const getBaseAmount = () => {
      switch (type) {
        case 'chat':
          return messagePrice * messageCount;
        case 'meet':
          return meetingData?.selectedSlotDuration?.price || 0;
        case 'spaceGroup':
          // Use the split price if it's a split payment
          if (isSplitPayment) {
            return spaceGroupData?.priceVariantPriceSplitted || 0;
          }
          return spaceGroupData?.price || 0;
        case 'course':
          return courseData?.price || 0;
        default:
          return 0;
      }
    };

    const baseAmount = getBaseAmount();
    const fee = baseAmount * platformFeePercent;
    const total = baseAmount + fee;

    return {
      baseAmount: parseFloat(baseAmount.toFixed(2)),
      fee: parseFloat(fee.toFixed(2)),
      total: parseFloat(total.toFixed(2)),
      isSplitPayment, // Include this flag to use in the UI
    };
  }, [
    type,
    spaceGroupData,
    messagePrice,
    messageCount,
    meetingData,
    courseData,
  ]);

  // ======= NAVIGATION HANDLERS =======
  const handleBackNavigation = () => {
    switch (type) {
      case 'meet':
        navigate(`/book-meeting/${creatorId}`);
        break;
      case 'chat':
        navigate(`/brands/${brandId}/chats/${userId}`);
        break;
      case 'spaceGroup':
        navigate(`/brands/space-groups/${spaceGroupId}`);
        break;
      case 'course':
        navigate(-1);
        break;
    }
  };

  // ======= RENDER HELPERS =======
  const renderCheckoutTitle = () => {
    switch (type) {
      case 'spaceGroup':
        return "Subscribe to Creator's plan";
      case 'chat':
        return 'Buy credits';
      case 'meet':
        return 'Book a meeting';
      case 'course':
        return 'Subscribe to course';
    }
  };

  const renderItemName = () => {
    switch (type) {
      case 'spaceGroup':
        return spaceGroupData?.name;
      case 'chat':
        return 'Creator DM access';
      case 'meet':
        return 'Creator 1:1 meeting';
      case 'course':
        return courseData?.name;
    }
  };

  const renderItemPrice = () => {
    switch (type) {
      case 'spaceGroup':
        return spaceGroupData?.price?.toFixed(2);
      case 'chat':
        return messagePrice;
      case 'meet':
        return meetingData?.selectedSlotDuration?.isFree
          ? 'FREE'
          : meetingData?.selectedSlotDuration?.price;
      case 'course':
        return courseData?.price?.toFixed(2);
    }
  };

  const renderButtonText = () => {
    switch (type) {
      case 'chat':
        return 'Buy credits';
      case 'meet':
        return 'Book now';
      case 'spaceGroup':
        return 'Confirm purchase';
      case 'course':
        return 'Buy course';
    }
  };

  const renderSpaceItem = (space: any, index: number, spaceType: number) => (
    <div key={index} className="flex w-full items-center gap-2.5">
      <div
        className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md fill-black"
        style={{
          backgroundColor: SPACES[spaceType].background,
        }}
      >
        {SPACES[spaceType].Icon({
          className: 'h-5 w-5',
          style: {
            fill: SPACES[spaceType].color,
          },
        })}
      </div>
      <div className="w-full capitalize">
        {space.name || SPACES[spaceType].name}
      </div>
    </div>
  );

  function mapRecurringInterval(
    priceVariantRecurringInterval: string,
    priceVariantIntervalCount: number,
  ) {
    if (
      priceVariantRecurringInterval === 'MONTH' &&
      priceVariantIntervalCount === 6
    ) {
      return 'Every 6 Months';
    }
    if (
      priceVariantRecurringInterval === 'MONTH' &&
      priceVariantIntervalCount === 3
    ) {
      return 'Every 3 Months';
    }
    if (
      priceVariantRecurringInterval === 'MONTH' &&
      priceVariantIntervalCount === 1
    ) {
      return 'Monthly';
    }
    if (
      priceVariantRecurringInterval === 'YEAR' &&
      priceVariantIntervalCount === 1
    ) {
      return 'Annually';
    }
    if (
      priceVariantRecurringInterval === 'WEEK' &&
      priceVariantIntervalCount === 1
    ) {
      return 'Weekly';
    }

    return null; // Retornar null si no hay coincidencia
  }

  // ======= MAIN RENDER =======
  return (
    <div className="flex flex-col overflow-auto lg:flex-row">
      {/* Left panel - Order Summary */}
      <div className="w-full p-3 lg:h-full">
        <div className="flex w-full flex-col gap-6 rounded-3xl bg-light-2 px-4 py-6 dark:bg-dark-2 md:px-6 lg:justify-between">
          <div className="flex w-full flex-col gap-6">
            {/* Header and Back Button */}
            <div className="flex w-full flex-col gap-2">
              <div
                className="flex cursor-pointer items-center gap-1"
                onClick={handleBackNavigation}
              >
                <ArrowLeftIcon className="h-6 w-6 fill-black dark:fill-white" />
                Back
              </div>
              <div className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                {renderCheckoutTitle()}
              </div>

              {/* Item Summary Box */}
              <div className="flex w-full gap-4 rounded-2xl border-2 border-light p-3 dark:border-dark-light">
                {/* Item Image */}
                {spaceGroupData?.avatarUrl ||
                meetingData?.creator.avatarUrl ||
                courseData?.image ? (
                  <img
                    src={
                      spaceGroupData?.avatarUrl ||
                      meetingData?.creator.avatarUrl ||
                      (courseData?.image ?? '')
                    }
                    alt="logo"
                    className="h-10 w-10 flex-shrink-0 rounded-lg object-cover"
                  />
                ) : (
                  <div className="h-10 w-10 flex-shrink-0 rounded-lg bg-light dark:bg-dark-light" />
                )}

                {/* Item Details */}
                <div className="flex w-full flex-col gap-1">
                  <div className="flex w-full gap-4 text-base font-medium">
                    <div className="w-full">{renderItemName()}</div>
                    <div className="text-nowrap">$ {renderItemPrice()}</div>
                  </div>
                  {/* Additional Details */}

                  {spaceGroupData?.billingType === 'ONE_TIME_PAYMENT' &&
                  spaceGroupData?.priceVariantNumberOfSplitPayments &&
                  spaceGroupData?.priceVariantNumberOfSplitPayments > 1 &&
                  spaceGroupData?.priceVariantSplitPaymentPeriod ? (
                    <div className="flex w-full flex-col text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      <div>
                        Split into{' '}
                        {spaceGroupData.priceVariantNumberOfSplitPayments}{' '}
                        {spaceGroupData.priceVariantSplitPaymentPeriod ===
                        'WEEK'
                          ? 'Weekly'
                          : 'Monthly'}{' '}
                        payments of $
                        {(
                          spaceGroupData.price /
                          spaceGroupData.priceVariantNumberOfSplitPayments
                        ).toFixed(2)}
                      </div>
                      <div className="mt-1 text-xs italic">
                        After completing all payments, you keep access forever -
                        no additional charges.
                      </div>
                    </div>
                  ) : spaceGroupData?.priceVariantRecurringInterval &&
                    spaceGroupData?.priceVariantIntervalCount ? (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      Billed{' '}
                      {mapRecurringInterval(
                        spaceGroupData.priceVariantRecurringInterval,
                        spaceGroupData.priceVariantIntervalCount,
                      ) || 'regularly'}
                    </div>
                  ) : spaceGroupData?.billingFrequency ? (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      Billed{' '}
                      {spaceGroupData.billingFrequency
                        .replace('_', ' ')
                        .replace('_', ' ')
                        .toLowerCase()}
                    </div>
                  ) : null}
                  {type === 'meet' && meetingData && (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      {meetingData.selectedDate?.toDateString()} at{' '}
                      {meetingData.selectedTime?.toTimeString().split(' ')[0]} -{' '}
                      {meetingData.selectedDuration} minutes
                    </div>
                  )}
                  {type === 'course' && courseData && (
                    <div className="w-full text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
                      {courseData.lessons?.length} Lessons
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Type-specific Content */}
            {type === 'spaceGroup' && (
              <div className="hidden w-full flex-col gap-2 font-bold lg:flex">
                {/* Chat Spaces */}
                {spaceGroupData?.chatsSpaces?.map((item, i) =>
                  renderSpaceItem(item, i, 2),
                )}

                {/* Course Spaces */}
                {spaceGroupData?.coursesSpaces?.map((item, i) =>
                  renderSpaceItem(item, i, 3),
                )}

                {/* Event Spaces */}
                {spaceGroupData?.eventSpaces?.map((item, i) =>
                  renderSpaceItem(item, i, 0),
                )}

                {/* Post Spaces */}
                {spaceGroupData?.postsSpaces?.map((item, i) =>
                  renderSpaceItem(item, i, 1),
                )}

                {/* Member Spaces */}
                {spaceGroupData?.membersSpaces?.map((item, i) =>
                  renderSpaceItem(item, i, 4),
                )}

                {/* Direct Messaging */}
                {spaceGroupData?.allowDirectMessaging && (
                  <div className="flex gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FFDAF5]">
                      <ChatBubbleEllipsisIcon className="h-6 w-6 stroke-[#EF38BE]" />
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="text-sm font-semibold">
                        Direct Messages
                      </div>
                      <div className="text-xs font-normal">
                        {spaceGroupData.messageFrequencyType === 'UNLIMITED' ? (
                          <p>Unlimited free messages!</p>
                        ) : (
                          <p>
                            Allowed{' '}
                            <strong>{spaceGroupData.freeMessagesLimit}</strong>{' '}
                            free messages per{' '}
                            <strong>
                              {spaceGroupData.freeMessagesFrequency
                                .replace('PER_', '')
                                .toLowerCase()}
                            </strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Call Meetings */}
                {spaceGroupData?.allowCallMeetings && (
                  <div className="flex gap-2.5">
                    <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-md bg-[#FBEDFF]">
                      <CalendarRoudedIcon className="h-6 w-6 stroke-[#C413F5]" />
                    </div>
                    <div className="flex flex-col gap-1 rounded-md">
                      <div className="text-sm font-semibold">Calls</div>
                      <div className="text-xs font-normal">
                        {spaceGroupData.messageFrequencyType === 'UNLIMITED' ? (
                          <p>Unlimited free calls!</p>
                        ) : (
                          <p>
                            Allowed{' '}
                            <strong>{spaceGroupData.freeCallsLimit}</strong>{' '}
                            free calls of{' '}
                            <strong>
                              {spaceGroupData.freeCallDuration}{' '}
                              {spaceGroupData.freeCallDurationType.toLowerCase()}{' '}
                              each
                            </strong>{' '}
                            per{' '}
                            <strong>
                              {spaceGroupData.freeCallsFrequency
                                .replace('PER_', '')
                                .toLowerCase()}
                            </strong>
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* Chat-specific Message Count */}
            {type === 'chat' && (
              <div className="flex w-full items-center justify-between">
                <div>Number of messages</div>
                <div className="flex items-center">
                  <Button
                    variant={'none'}
                    className="h-fit rounded-r-none bg-black py-1 text-xl font-medium text-white dark:bg-white dark:text-black"
                    onClick={() => {
                      if (messageCount > 1) {
                        setMessageCount((prev) => prev - 1);
                      }
                    }}
                  >
                    -
                  </Button>
                  <div className="flex h-9 w-10 flex-shrink-0 items-center justify-center border border-black px-3 dark:border-white">
                    {messageCount}
                  </div>
                  <Button
                    variant={'none'}
                    className="h-fit rounded-l-none bg-black py-1 text-xl text-white dark:bg-white dark:text-black"
                    onClick={() => {
                      if (messageCount < 100) {
                        setMessageCount((prev) => prev + 1);
                      }
                    }}
                  >
                    +
                  </Button>
                </div>
              </div>
            )}

            {/* Meeting-specific Warning */}
            {type === 'meet' && (
              <div className="flex w-full items-center justify-between">
                IMPORTANT: If you don't complete the payment within 10 minutes,
                the slot will be released.
              </div>
            )}

            {/* Promo Code */}
            <div className="flex w-full items-center gap-2.5">
              <InputField
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                type="text"
                inputClassName="!bg-transparent border-light dark:border-dark-light !py-2"
                placeholder="Enter your promo code"
              />
              <Button
                variant={'none'}
                className="mt-1 h-fit bg-black px-6 py-2.5 text-white dark:bg-white dark:text-black"
              >
                Apply
              </Button>
            </div>
          </div>

          {/* Price Summary */}
          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-2 text-base font-normal text-textParagraph dark:text-dark-textParagraph">
              <div className="flex justify-between">
                {calculatePlatformFee.isSplitPayment ? (
                  <div className="flex items-center">
                    Sub total{' '}
                    <span className="ml-2 text-xs italic">
                      (per installment)
                    </span>
                  </div>
                ) : (
                  'Sub total'
                )}
                <span className="font-medium text-black dark:text-white">
                  $ {calculatePlatformFee.baseAmount.toFixed(2)}
                </span>
              </div>
              <div className="flex justify-between">
                Platform Fee
                <span className="font-medium text-black dark:text-white">
                  $ {calculatePlatformFee.fee.toFixed(2)}
                </span>
              </div>
            </div>
            <div className="flex justify-between text-base font-medium">
              {calculatePlatformFee.isSplitPayment ? (
                <div className="flex items-center">
                  Total{' '}
                  <span className="ml-2 text-xs italic">(per installment)</span>
                </div>
              ) : (
                'Total'
              )}
              <span>$ {calculatePlatformFee.total.toFixed(2)}</span>
            </div>

            {calculatePlatformFee.isSplitPayment && (
              <div className="mt-2 text-xs text-textParagraph dark:text-dark-textParagraph">
                <div className="flex justify-between border-t pt-2">
                  <span>
                    Full price (all{' '}
                    {spaceGroupData?.priceVariantNumberOfSplitPayments}{' '}
                    payments)
                  </span>
                  <span className="font-medium">
                    $ {spaceGroupData?.price.toFixed(2)}
                  </span>
                </div>
                <div className="mt-1 italic">
                  You'll be charged ${calculatePlatformFee.total.toFixed(2)}{' '}
                  today, followed by
                  {spaceGroupData?.priceVariantNumberOfSplitPayments &&
                  spaceGroupData.priceVariantNumberOfSplitPayments > 1
                    ? ` ${spaceGroupData.priceVariantNumberOfSplitPayments - 1} more`
                    : ''}
                  {spaceGroupData?.priceVariantSplitPaymentPeriod === 'WEEK'
                    ? ' weekly'
                    : ' monthly'}{' '}
                  payments.
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Right panel - Payment Form */}
      <div className="relative flex w-full flex-col gap-2 p-4 pb-32 md:px-6 lg:overflow-auto lg:pb-4">
        {/* Email Input */}
        <InputField
          type="email"
          title="Email Address"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.email}
          onChange={(e) =>
            setPaymentData((prev) => ({ ...prev, email: e.target.value }))
          }
        />

        {/* Card Element */}
        <div className="flex flex-col gap-1">
          <div className="text-sm">Card information</div>
          <CardElement
            className="rounded-lg border border-light p-3 dark:border-dark-light"
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#777',
                  '::placeholder': {
                    color: '#9ca3af44',
                  },
                },
              },
            }}
          />
        </div>

        {/* Cardholder Name */}
        <InputField
          type="text"
          title="Cardholder name"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.cardHolder}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              cardHolder: e.target.value,
            }))
          }
        />

        {/* Country Dropdown */}
        <CountryDropdown
          country={{
            name: paymentData.country,
            flag: '',
          }}
          setCountry={(country: { name: string; flag: string }) =>
            setPaymentData((prev) => ({
              ...prev,
              country: country.name,
            }))
          }
          titleClassName="!text-sm"
          inputClassName="!p-2.5"
        />

        {/* Address */}
        <InputField
          type="text"
          title="Address"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.address}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              address: e.target.value,
            }))
          }
        />

        {/* City */}
        <InputField
          type="text"
          title="City"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.city}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              city: e.target.value,
            }))
          }
        />

        {/* Zip Code */}
        <InputField
          type="text"
          title="Zip code"
          inputClassName="!bg-transparent border-light dark:border-dark-light !p-2"
          titleClassName="!text-sm"
          value={paymentData.zipCode}
          onChange={(e) =>
            setPaymentData((prev) => ({
              ...prev,
              zipCode: e.target.value,
            }))
          }
        />

        {/* Terms Agreement */}
        <div className="flex w-full gap-2.5 text-sm font-medium text-textParagraph dark:text-dark-textParagraph">
          <div
            className={`flex h-5 w-5 flex-shrink-0 cursor-pointer items-center justify-center rounded-sm border border-textParagraph dark:border-dark-textParagraph ${hasAgreed && 'bg-brand'}`}
            onClick={() => setHasAgreed(!hasAgreed)}
          >
            {hasAgreed && (
              <CheckIcon className="h-4 w-4 flex-shrink-0 stroke-white" />
            )}
          </div>
          I agree to {spaceGroupData?.name} and teachly's terms and conditions
          and allow them to charge your card for this payment.
        </div>

        {/* Terms Links */}
        <div className="flex gap-4 font-medium text-textParagraph dark:text-dark-textParagraph">
          <a
            href="/terms-of-service"
            className="cursor-pointer"
            target="_blank"
          >
            Terms
          </a>
          <a href="/privacy-policy" className="cursor-pointer" target="_blank">
            Privacy
          </a>
        </div>

        {/* Error Display */}
        {error && <div className="text-sm text-red-400">{error}</div>}

        {/* Submit Button and Secure Payment Notice */}
        <div className="fixed bottom-0 left-0 right-0 flex w-full flex-col gap-4 bg-white px-4 dark:bg-dark-1 lg:static">
          <Button
            className="h-fit text-base"
            onClick={handlePay}
            disabled={loading}
          >
            {loading ? 'Processing...' : renderButtonText()}
          </Button>
          <div className="flex w-full items-center justify-center gap-2 py-2.5 text-base font-medium text-textParagraph dark:text-dark-textParagraph">
            <LockIcon className="h-6 w-6 fill-green-100" />
            Payments are secured and encrypted
          </div>
        </div>
      </div>
      <PaymentSuccessModal
        open={paymentSuccessful}
        onContinue={handleContinueToRegister}
      />
    </div>
  );
};

export default CheckoutPage;
