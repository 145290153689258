/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  NotificationCounters,
  NotificationsControllerGetAllUserNotifications200,
  SuccessMessageResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Get all my notifications.
 * @summary Get all my notifications
 */
export const notificationsControllerGetAllUserNotifications = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<NotificationsControllerGetAllUserNotifications200>(
    { url: `/notifications/all`, method: 'GET', signal },
    options,
  );
};

export const getNotificationsControllerGetAllUserNotificationsQueryKey = () => {
  return [`/notifications/all`] as const;
};

export const getNotificationsControllerGetAllUserNotificationsQueryOptions = <
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getNotificationsControllerGetAllUserNotificationsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof notificationsControllerGetAllUserNotifications>>
  > = ({ signal }) =>
    notificationsControllerGetAllUserNotifications(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof notificationsControllerGetAllUserNotifications>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type NotificationsControllerGetAllUserNotificationsQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationsControllerGetAllUserNotifications>>
  >;
export type NotificationsControllerGetAllUserNotificationsQueryError = void;

export function useNotificationsControllerGetAllUserNotifications<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >,
        TError,
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetAllUserNotifications<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >,
        TError,
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationsControllerGetAllUserNotifications<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all my notifications
 */

export function useNotificationsControllerGetAllUserNotifications<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getNotificationsControllerGetAllUserNotificationsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all my notifications
 */
export const prefetchNotificationsControllerGetAllUserNotifications = async <
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getNotificationsControllerGetAllUserNotificationsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getNotificationsControllerGetAllUserNotificationsSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof notificationsControllerGetAllUserNotifications>
    >,
    TError = void,
  >(options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof notificationsControllerGetAllUserNotifications>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  }) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getNotificationsControllerGetAllUserNotificationsQueryKey();

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof notificationsControllerGetAllUserNotifications>>
    > = ({ signal }) =>
      notificationsControllerGetAllUserNotifications(requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type NotificationsControllerGetAllUserNotificationsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationsControllerGetAllUserNotifications>>
  >;
export type NotificationsControllerGetAllUserNotificationsSuspenseQueryError =
  void;

export function useNotificationsControllerGetAllUserNotificationsSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetAllUserNotificationsSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetAllUserNotificationsSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all my notifications
 */

export function useNotificationsControllerGetAllUserNotificationsSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetAllUserNotifications>
  >,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof notificationsControllerGetAllUserNotifications>
      >,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getNotificationsControllerGetAllUserNotificationsSuspenseQueryOptions(
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Mark a notification as read.
 * @summary Mark a notification as read
 */
export const notificationsControllerMarkAsRead = (
  notificationId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/read/${encodeURIComponent(String(notificationId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerMarkAsReadMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>,
    TError,
    { notificationId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const mutationKey = ['notificationsControllerMarkAsRead'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>,
    { notificationId: string }
  > = (props) => {
    const { notificationId } = props ?? {};

    return notificationsControllerMarkAsRead(notificationId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerMarkAsReadMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>
>;

export type NotificationsControllerMarkAsReadMutationError = void;

/**
 * @summary Mark a notification as read
 */
export const useNotificationsControllerMarkAsRead = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>,
    TError,
    { notificationId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerMarkAsRead>>,
  TError,
  { notificationId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerMarkAsReadMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Mark all notifications as read.
 * @summary Mark all notifications as read
 */
export const notificationsControllerMarkAsReadAll = (
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    { url: `/notifications/read-all`, method: 'PATCH' },
    options,
  );
};

export const getNotificationsControllerMarkAsReadAllMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['notificationsControllerMarkAsReadAll'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>,
    void
  > = () => {
    return notificationsControllerMarkAsReadAll(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerMarkAsReadAllMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>
>;

export type NotificationsControllerMarkAsReadAllMutationError = void;

/**
 * @summary Mark all notifications as read
 */
export const useNotificationsControllerMarkAsReadAll = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerMarkAsReadAll>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerMarkAsReadAllMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get notification counters for a space group.
 * @summary Get notification counters for a space group
 */
export const notificationsControllerGetNotificationCountersForSpaceGroup = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<NotificationCounters>(
    {
      url: `/notifications/counters/space-group/${encodeURIComponent(String(spaceGroupId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getNotificationsControllerGetNotificationCountersForSpaceGroupQueryKey =
  (spaceGroupId: string) => {
    return [`/notifications/counters/space-group/${spaceGroupId}`] as const;
  };

export const getNotificationsControllerGetNotificationCountersForSpaceGroupQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof notificationsControllerGetNotificationCountersForSpaceGroup
      >
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getNotificationsControllerGetNotificationCountersForSpaceGroupQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForSpaceGroup
        >
      >
    > = ({ signal }) =>
      notificationsControllerGetNotificationCountersForSpaceGroup(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!spaceGroupId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForSpaceGroup
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type NotificationsControllerGetNotificationCountersForSpaceGroupQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof notificationsControllerGetNotificationCountersForSpaceGroup
      >
    >
  >;
export type NotificationsControllerGetNotificationCountersForSpaceGroupQueryError =
  void;

export function useNotificationsControllerGetNotificationCountersForSpaceGroup<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForSpaceGroup<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationsControllerGetNotificationCountersForSpaceGroup<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get notification counters for a space group
 */

export function useNotificationsControllerGetNotificationCountersForSpaceGroup<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getNotificationsControllerGetNotificationCountersForSpaceGroupQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get notification counters for a space group
 */
export const prefetchNotificationsControllerGetNotificationCountersForSpaceGroup =
  async <
    TData = Awaited<
      ReturnType<
        typeof notificationsControllerGetNotificationCountersForSpaceGroup
      >
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getNotificationsControllerGetNotificationCountersForSpaceGroupQueryOptions(
        spaceGroupId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getNotificationsControllerGetNotificationCountersForSpaceGroupSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof notificationsControllerGetNotificationCountersForSpaceGroup
      >
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getNotificationsControllerGetNotificationCountersForSpaceGroupQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForSpaceGroup
        >
      >
    > = ({ signal }) =>
      notificationsControllerGetNotificationCountersForSpaceGroup(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForSpaceGroup
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type NotificationsControllerGetNotificationCountersForSpaceGroupSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof notificationsControllerGetNotificationCountersForSpaceGroup
      >
    >
  >;
export type NotificationsControllerGetNotificationCountersForSpaceGroupSuspenseQueryError =
  void;

export function useNotificationsControllerGetNotificationCountersForSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get notification counters for a space group
 */

export function useNotificationsControllerGetNotificationCountersForSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<
      typeof notificationsControllerGetNotificationCountersForSpaceGroup
    >
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getNotificationsControllerGetNotificationCountersForSpaceGroupSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Get notification counters for a brand.
 * @summary Get notification counters for a brand
 */
export const notificationsControllerGetNotificationCountersForBrand = (
  brandId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<NotificationCounters>(
    {
      url: `/notifications/counters/brand/${encodeURIComponent(String(brandId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getNotificationsControllerGetNotificationCountersForBrandQueryKey =
  (brandId: string) => {
    return [`/notifications/counters/brand/${brandId}`] as const;
  };

export const getNotificationsControllerGetNotificationCountersForBrandQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
    >,
    TError = void,
  >(
    brandId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getNotificationsControllerGetNotificationCountersForBrandQueryKey(
        brandId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForBrand
        >
      >
    > = ({ signal }) =>
      notificationsControllerGetNotificationCountersForBrand(
        brandId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!brandId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForBrand
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type NotificationsControllerGetNotificationCountersForBrandQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
    >
  >;
export type NotificationsControllerGetNotificationCountersForBrandQueryError =
  void;

export function useNotificationsControllerGetNotificationCountersForBrand<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForBrand<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useNotificationsControllerGetNotificationCountersForBrand<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get notification counters for a brand
 */

export function useNotificationsControllerGetNotificationCountersForBrand<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getNotificationsControllerGetNotificationCountersForBrandQueryOptions(
      brandId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get notification counters for a brand
 */
export const prefetchNotificationsControllerGetNotificationCountersForBrand =
  async <
    TData = Awaited<
      ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    brandId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getNotificationsControllerGetNotificationCountersForBrandQueryOptions(
        brandId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getNotificationsControllerGetNotificationCountersForBrandSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
    >,
    TError = void,
  >(
    brandId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof notificationsControllerGetNotificationCountersForBrand
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getNotificationsControllerGetNotificationCountersForBrandQueryKey(
        brandId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForBrand
        >
      >
    > = ({ signal }) =>
      notificationsControllerGetNotificationCountersForBrand(
        brandId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          typeof notificationsControllerGetNotificationCountersForBrand
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type NotificationsControllerGetNotificationCountersForBrandSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
    >
  >;
export type NotificationsControllerGetNotificationCountersForBrandSuspenseQueryError =
  void;

export function useNotificationsControllerGetNotificationCountersForBrandSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForBrandSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useNotificationsControllerGetNotificationCountersForBrandSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get notification counters for a brand
 */

export function useNotificationsControllerGetNotificationCountersForBrandSuspense<
  TData = Awaited<
    ReturnType<typeof notificationsControllerGetNotificationCountersForBrand>
  >,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof notificationsControllerGetNotificationCountersForBrand
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getNotificationsControllerGetNotificationCountersForBrandSuspenseQueryOptions(
      brandId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Reset post counter.
 * @summary Reset post counter
 */
export const notificationsControllerResetPostCounter = (
  postSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/post-counter/reset/${encodeURIComponent(String(postSpaceId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerResetPostCounterMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>,
    TError,
    { postSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>,
  TError,
  { postSpaceId: string },
  TContext
> => {
  const mutationKey = ['notificationsControllerResetPostCounter'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>,
    { postSpaceId: string }
  > = (props) => {
    const { postSpaceId } = props ?? {};

    return notificationsControllerResetPostCounter(postSpaceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerResetPostCounterMutationResult = NonNullable<
  Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>
>;

export type NotificationsControllerResetPostCounterMutationError = void;

/**
 * @summary Reset post counter
 */
export const useNotificationsControllerResetPostCounter = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>,
    TError,
    { postSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerResetPostCounter>>,
  TError,
  { postSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerResetPostCounterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reset group chat message counter.
 * @summary Reset group chat message counter
 */
export const notificationsControllerResetGroupChatMessageCounter = (
  chatsSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/group-chat-message-counter/reset/${encodeURIComponent(String(chatsSpaceId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerResetGroupChatMessageCounterMutationOptions =
  <TError = void, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
      >,
      TError,
      { chatsSpaceId: string },
      TContext
    >;
    request?: SecondParameter<typeof customFetch>;
  }): UseMutationOptions<
    Awaited<
      ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
    >,
    TError,
    { chatsSpaceId: string },
    TContext
  > => {
    const mutationKey = ['notificationsControllerResetGroupChatMessageCounter'];
    const { mutation: mutationOptions, request: requestOptions } = options
      ? options.mutation &&
        'mutationKey' in options.mutation &&
        options.mutation.mutationKey
        ? options
        : { ...options, mutation: { ...options.mutation, mutationKey } }
      : { mutation: { mutationKey }, request: undefined };

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
      >,
      { chatsSpaceId: string }
    > = (props) => {
      const { chatsSpaceId } = props ?? {};

      return notificationsControllerResetGroupChatMessageCounter(
        chatsSpaceId,
        requestOptions,
      );
    };

    return { mutationFn, ...mutationOptions };
  };

export type NotificationsControllerResetGroupChatMessageCounterMutationResult =
  NonNullable<
    Awaited<
      ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
    >
  >;

export type NotificationsControllerResetGroupChatMessageCounterMutationError =
  void;

/**
 * @summary Reset group chat message counter
 */
export const useNotificationsControllerResetGroupChatMessageCounter = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
    >,
    TError,
    { chatsSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<
    ReturnType<typeof notificationsControllerResetGroupChatMessageCounter>
  >,
  TError,
  { chatsSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerResetGroupChatMessageCounterMutationOptions(
      options,
    );

  return useMutation(mutationOptions);
};
/**
 * Reset event counter.
 * @summary Reset event counter
 */
export const notificationsControllerResetEventCounter = (
  eventSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/event-counter/reset/${encodeURIComponent(String(eventSpaceId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerResetEventCounterMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>,
    TError,
    { eventSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>,
  TError,
  { eventSpaceId: string },
  TContext
> => {
  const mutationKey = ['notificationsControllerResetEventCounter'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>,
    { eventSpaceId: string }
  > = (props) => {
    const { eventSpaceId } = props ?? {};

    return notificationsControllerResetEventCounter(
      eventSpaceId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerResetEventCounterMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>
  >;

export type NotificationsControllerResetEventCounterMutationError = void;

/**
 * @summary Reset event counter
 */
export const useNotificationsControllerResetEventCounter = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>,
    TError,
    { eventSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerResetEventCounter>>,
  TError,
  { eventSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerResetEventCounterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reset course counter.
 * @summary Reset course counter
 */
export const notificationsControllerResetCourseCounter = (
  coursesSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/course-counter/reset/${encodeURIComponent(String(coursesSpaceId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerResetCourseCounterMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>,
    TError,
    { coursesSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>,
  TError,
  { coursesSpaceId: string },
  TContext
> => {
  const mutationKey = ['notificationsControllerResetCourseCounter'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>,
    { coursesSpaceId: string }
  > = (props) => {
    const { coursesSpaceId } = props ?? {};

    return notificationsControllerResetCourseCounter(
      coursesSpaceId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerResetCourseCounterMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>
  >;

export type NotificationsControllerResetCourseCounterMutationError = void;

/**
 * @summary Reset course counter
 */
export const useNotificationsControllerResetCourseCounter = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>,
    TError,
    { coursesSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerResetCourseCounter>>,
  TError,
  { coursesSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerResetCourseCounterMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Reset discussion counter.
 * @summary Reset discussion counter
 */
export const notificationsControllerResetDiscussionCounter = (
  discussionId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/notifications/discussion-counter/reset/${encodeURIComponent(String(discussionId))}`,
      method: 'PATCH',
    },
    options,
  );
};

export const getNotificationsControllerResetDiscussionCounterMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>,
    TError,
    { discussionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>,
  TError,
  { discussionId: string },
  TContext
> => {
  const mutationKey = ['notificationsControllerResetDiscussionCounter'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>,
    { discussionId: string }
  > = (props) => {
    const { discussionId } = props ?? {};

    return notificationsControllerResetDiscussionCounter(
      discussionId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type NotificationsControllerResetDiscussionCounterMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>
  >;

export type NotificationsControllerResetDiscussionCounterMutationError = void;

/**
 * @summary Reset discussion counter
 */
export const useNotificationsControllerResetDiscussionCounter = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>,
    TError,
    { discussionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof notificationsControllerResetDiscussionCounter>>,
  TError,
  { discussionId: string },
  TContext
> => {
  const mutationOptions =
    getNotificationsControllerResetDiscussionCounterMutationOptions(options);

  return useMutation(mutationOptions);
};
