export const TextCircleIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.879 11.819H7.97773M7.97773 11.819H9.0874M7.97773 11.819V5.49972M4.67056 6.64582C4.57876 5.94109 4.76269 5.62986 5.41067 5.45562C7.07106 5.00911 8.87246 5.0838 10.5417 5.49589C11.1703 5.6511 11.385 5.92769 11.2958 6.64582"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M7.9987 15.1654C11.6806 15.1654 14.6654 12.1806 14.6654 8.4987C14.6654 4.8168 11.6806 1.83203 7.9987 1.83203C4.3168 1.83203 1.33203 4.8168 1.33203 8.4987C1.33203 12.1806 4.3168 15.1654 7.9987 15.1654Z" />
  </svg>
);
