import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';

import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useToast } from '@/hooks/use-toast';
import { useAuth } from '@/hooks/useContext';

const AffiliatePage = () => {
  const [brands, setBrands] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentBrand, setCurrentBrand] = useState<any>(null);
  const [creatorShare, setCreatorShare] = useState('');
  const [teachlyShare, setTeachlyShare] = useState('');
  const [platformFee, setPlatformFee] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const token = localStorage.getItem('token');
  const { user } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'https://7up3mqhbnt.us-west-2.awsapprunner.com/space-groups/all',
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (!response.ok) {
          throw new Error('Error fetching brand data');
        }
        const data = await response.json();
        setBrands(data);
      } catch (error) {
        console.error('Error fetching brand data:', error);
        setErrorMessage('Error loading data. Please try again later.');
      }
    };
    if (token) {
      fetchData();
    }
  }, [token]);

  console.log('user', user);

  const allowedEmails = ['manuelg9704@gmail.com', 'spencer@teachly.ai'];
  if (!user || !allowedEmails.includes(user.email)) {
    return (
      <div className="overflow-auto px-6 py-12">
        <h1 className="mb-4 text-2xl font-bold">Access Denied</h1>
        <p className="text-red-500">
          You are not authorized to view this page.
        </p>
      </div>
    );
  }

  const handleEdit = (brand: any) => {
    setCurrentBrand(brand);
    setCreatorShare(brand.creatorShare?.toString() ?? '');
    setTeachlyShare(brand.teachlyShare?.toString() ?? '');
    setPlatformFee(brand.platformFee?.toString() ?? '');
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (!currentBrand) return;
    const updateData: any = {};
    const parsedCreatorShare = parseFloat(creatorShare);
    const parsedTeachlyShare = parseFloat(teachlyShare);
    const parsedPlatformFee = parseFloat(platformFee);
    if (parsedCreatorShare !== currentBrand.creatorShare) {
      updateData.creatorShare = parsedCreatorShare;
    }
    if (parsedTeachlyShare !== currentBrand.teachlyShare) {
      updateData.teachlyShare = parsedTeachlyShare;
    }
    if (parsedPlatformFee !== currentBrand.platformFee) {
      updateData.platformFee = parsedPlatformFee;
    }
    try {
      const response = await fetch(
        `https://7up3mqhbnt.us-west-2.awsapprunner.com/space-groups/${currentBrand.brandId}/update-shares`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updateData),
        },
      );
      if (!response.ok) {
        const errorData = await response.json();
        const errMessage = errorData.message || 'Error updating brand data.';
        toast({
          title: 'Update Error',
          description: errMessage,
          variant: 'destructive',
        });
        throw new Error(errMessage);
      }
      const updatedBrand = await response.json();
      setBrands((prevBrands) =>
        prevBrands.map((b) =>
          b.brandId === updatedBrand.brandId ? updatedBrand : b,
        ),
      );
      toast({
        title: `Shares updated successfully for ${updatedBrand.brandName}`,
        description: 'All changes have been saved.',
      });
      setIsModalOpen(false);
      setErrorMessage('');
    } catch (error: any) {
      console.error('Error updating brand data:', error);
      setErrorMessage(
        error.message || 'Error updating brand data. Please try again.',
      );
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setErrorMessage('');
  };

  return (
    <div className="overflow-auto px-6 py-12">
      <h1 className="mb-4 text-2xl font-bold">Split Share Settings</h1>
      <p className="mb-4 rounded-lg border border-indigo-500/20 bg-gradient-to-r from-indigo-500/10 to-purple-500/10 p-4 text-gray-200 shadow-sm">
        <span className="font-semibold text-indigo-400">
          By default, the revenue distribution is:
        </span>
        <br />• Creators receive{' '}
        <span className="font-medium text-green-400">80%</span> of earnings
        <br />• Teachly takes{' '}
        <span className="font-medium text-blue-400">20%</span> as commission
        <br />• A <span className="font-medium text-purple-400">7.5%</span>{' '}
        platform fee is applied to all transactions
      </p>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      {brands.map((brand) => (
        <div key={brand.brandId} className="mb-8 border-b pb-4">
          <div className="mb-2 flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
            <div>
              <div className="font-semibold">
                Brand:{' '}
                <span className="rounded bg-yellow-200 px-2 py-1 font-normal text-black">
                  {brand.brandName}
                </span>
              </div>
              <div>
                Creator Email:{' '}
                <span className="font-normal">
                  {brand.brandCreatorEmail || '-'}
                </span>
              </div>
              <div>
                Creator Share:{' '}
                <span className="font-normal">
                  {brand.creatorShare ? `${brand.creatorShare}%` : '-'}
                </span>
              </div>
              <div>
                Teachly Share:{' '}
                <span className="font-normal">
                  {brand.teachlyShare ? `${brand.teachlyShare}%` : '-'}
                </span>
              </div>
              <div>
                Platform Fee:{' '}
                <span className="font-normal">
                  {brand.platformFee ? `${brand.platformFee}%` : '-'}
                </span>
              </div>
            </div>
            <Button onClick={() => handleEdit(brand)} className="mt-2 md:mt-0">
              Edit shares
            </Button>
          </div>
          <div className="overflow-auto rounded-lg border">
            <Table className="min-w-full">
              <TableHeader>
                <TableRow className="bg-light-2 text-dark-2 dark:bg-dark-2 dark:text-light-2">
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Space Group Name
                  </TableHead>
                  <TableHead className="px-4 py-2 text-left font-medium uppercase">
                    Price
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {brand.spaceGroups && brand.spaceGroups.length > 0 ? (
                  brand.spaceGroups.map((sg: any) => (
                    <TableRow
                      key={sg.spaceGroupId}
                      className="hover:bg-light-2 hover:dark:bg-dark-2"
                    >
                      <TableCell className="px-4 py-2 text-sm">
                        {sg.name}
                      </TableCell>
                      <TableCell className="px-4 py-2 text-sm">
                        {sg.price !== undefined && sg.price !== null
                          ? `$ ${sg.price} USD`
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      className="px-4 py-2 text-center text-sm"
                    >
                      No Space Groups
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      ))}
      <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
        <DialogContent className="flex flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
          <DialogHeader className="flex-row items-center justify-between space-y-0">
            <DialogTitle className="text-2xl font-semibold">
              Edit Brand Shares
            </DialogTitle>
            <Button
              onClick={() => setIsModalOpen(false)}
              variant="icon"
              size="icon"
              className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
            >
              <XIcon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <div className="flex w-full max-w-md flex-col gap-4 rounded-lg bg-white dark:bg-dark-1">
            {errorMessage && (
              <p className="mb-2 text-red-500">{errorMessage}</p>
            )}
            <InputField
              title="Creator Share"
              type="number"
              value={creatorShare}
              onChange={(e) => setCreatorShare(e.target.value)}
            />
            <InputField
              title="Teachly Share"
              type="number"
              value={teachlyShare}
              onChange={(e) => setTeachlyShare(e.target.value)}
            />
            <InputField
              title="Platform Fee"
              type="number"
              value={platformFee}
              onChange={(e) => setPlatformFee(e.target.value)}
            />
            <div className="flex justify-end space-x-2">
              <Button onClick={handleCloseModal} variant="outline">
                Cancel
              </Button>
              <Button onClick={handleSaveChanges}>Save changes</Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AffiliatePage;
