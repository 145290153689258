/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreateDiscussionDto,
  Discussion,
  DiscussionDetails,
  UserDiscussion,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new discussion for a specific post.
 * @summary Create a new discussion
 */
export const discussionControllerCreateDiscussion = (
  createDiscussionDto: CreateDiscussionDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<Discussion>(
    {
      url: `/discussions`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createDiscussionDto,
      signal,
    },
    options,
  );
};

export const getDiscussionControllerCreateDiscussionMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>,
    TError,
    { data: CreateDiscussionDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>,
  TError,
  { data: CreateDiscussionDto },
  TContext
> => {
  const mutationKey = ['discussionControllerCreateDiscussion'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>,
    { data: CreateDiscussionDto }
  > = (props) => {
    const { data } = props ?? {};

    return discussionControllerCreateDiscussion(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DiscussionControllerCreateDiscussionMutationResult = NonNullable<
  Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>
>;
export type DiscussionControllerCreateDiscussionMutationBody =
  CreateDiscussionDto;
export type DiscussionControllerCreateDiscussionMutationError = void;

/**
 * @summary Create a new discussion
 */
export const useDiscussionControllerCreateDiscussion = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>,
    TError,
    { data: CreateDiscussionDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof discussionControllerCreateDiscussion>>,
  TError,
  { data: CreateDiscussionDto },
  TContext
> => {
  const mutationOptions =
    getDiscussionControllerCreateDiscussionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Joins a discussion for a specific post.
 * @summary Join a discussion
 */
export const discussionControllerJoinDiscussion = (
  discussionId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserDiscussion>(
    {
      url: `/discussions/${encodeURIComponent(String(discussionId))}/join`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerJoinDiscussionMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>,
    TError,
    { discussionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>,
  TError,
  { discussionId: string },
  TContext
> => {
  const mutationKey = ['discussionControllerJoinDiscussion'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>,
    { discussionId: string }
  > = (props) => {
    const { discussionId } = props ?? {};

    return discussionControllerJoinDiscussion(discussionId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DiscussionControllerJoinDiscussionMutationResult = NonNullable<
  Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>
>;

export type DiscussionControllerJoinDiscussionMutationError = void;

/**
 * @summary Join a discussion
 */
export const useDiscussionControllerJoinDiscussion = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>,
    TError,
    { discussionId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof discussionControllerJoinDiscussion>>,
  TError,
  { discussionId: string },
  TContext
> => {
  const mutationOptions =
    getDiscussionControllerJoinDiscussionMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves discussions of a post space.
 * @summary Get discussions of a post space
 */
export const discussionControllerGetAllDiscussionsInPostSpace = (
  postSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<DiscussionDetails[]>(
    {
      url: `/discussions/post-spaces/${encodeURIComponent(String(postSpaceId))}/all`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerGetAllDiscussionsInPostSpaceQueryKey = (
  postSpaceId: string,
) => {
  return [`/discussions/post-spaces/${postSpaceId}/all`] as const;
};

export const getDiscussionControllerGetAllDiscussionsInPostSpaceQueryOptions = <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDiscussionControllerGetAllDiscussionsInPostSpaceQueryKey(postSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>>
  > = ({ signal }) =>
    discussionControllerGetAllDiscussionsInPostSpace(
      postSpaceId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!postSpaceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DiscussionControllerGetAllDiscussionsInPostSpaceQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>>
  >;
export type DiscussionControllerGetAllDiscussionsInPostSpaceQueryError = void;

export function useDiscussionControllerGetAllDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetAllDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDiscussionControllerGetAllDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get discussions of a post space
 */

export function useDiscussionControllerGetAllDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getDiscussionControllerGetAllDiscussionsInPostSpaceQueryOptions(
      postSpaceId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get discussions of a post space
 */
export const prefetchDiscussionControllerGetAllDiscussionsInPostSpace = async <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getDiscussionControllerGetAllDiscussionsInPostSpaceQueryOptions(
      postSpaceId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getDiscussionControllerGetAllDiscussionsInPostSpaceSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
    >,
    TError = void,
  >(
    postSpaceId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetAllDiscussionsInPostSpaceQueryKey(postSpaceId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
      >
    > = ({ signal }) =>
      discussionControllerGetAllDiscussionsInPostSpace(
        postSpaceId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetAllDiscussionsInPostSpaceSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>>
  >;
export type DiscussionControllerGetAllDiscussionsInPostSpaceSuspenseQueryError =
  void;

export function useDiscussionControllerGetAllDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetAllDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetAllDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get discussions of a post space
 */

export function useDiscussionControllerGetAllDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetAllDiscussionsInPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getDiscussionControllerGetAllDiscussionsInPostSpaceSuspenseQueryOptions(
      postSpaceId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves joined discussions of a post space.
 * @summary Get joined discussions of a post space
 */
export const discussionControllerGetUserJoinedDiscussionsInPostSpace = (
  postSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<DiscussionDetails[]>(
    {
      url: `/discussions/post-spaces/${encodeURIComponent(String(postSpaceId))}/joined`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryKey =
  (postSpaceId: string) => {
    return [`/discussions/post-spaces/${postSpaceId}/joined`] as const;
  };

export const getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
    >,
    TError = void,
  >(
    postSpaceId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryKey(
        postSpaceId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
        >
      >
    > = ({ signal }) =>
      discussionControllerGetUserJoinedDiscussionsInPostSpace(
        postSpaceId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!postSpaceId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
    >
  >;
export type DiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryError =
  void;

export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get joined discussions of a post space
 */

export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryOptions(
      postSpaceId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get joined discussions of a post space
 */
export const prefetchDiscussionControllerGetUserJoinedDiscussionsInPostSpace =
  async <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    postSpaceId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryOptions(
        postSpaceId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
    >,
    TError = void,
  >(
    postSpaceId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceQueryKey(
        postSpaceId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
        >
      >
    > = ({ signal }) =>
      discussionControllerGetUserJoinedDiscussionsInPostSpace(
        postSpaceId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
    >
  >;
export type DiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspenseQueryError =
  void;

export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get joined discussions of a post space
 */

export function useDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInPostSpace
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getDiscussionControllerGetUserJoinedDiscussionsInPostSpaceSuspenseQueryOptions(
      postSpaceId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves joined discussions of a space group.
 * @summary Get joined discussions of a space group
 */
export const discussionControllerGetUserJoinedDiscussionsInSpaceGroup = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<DiscussionDetails[]>(
    {
      url: `/discussions/space-groups/${encodeURIComponent(String(spaceGroupId))}/joined`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryKey =
  (spaceGroupId: string) => {
    return [`/discussions/space-groups/${spaceGroupId}/joined`] as const;
  };

export const getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
      >
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
        >
      >
    > = ({ signal }) =>
      discussionControllerGetUserJoinedDiscussionsInSpaceGroup(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return {
      queryKey,
      queryFn,
      enabled: !!spaceGroupId,
      ...queryOptions,
    } as UseQueryOptions<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
      >
    >
  >;
export type DiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryError =
  void;

export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >,
          TError,
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get joined discussions of a space group
 */

export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get joined discussions of a space group
 */
export const prefetchDiscussionControllerGetUserJoinedDiscussionsInSpaceGroup =
  async <
    TData = Awaited<
      ReturnType<
        typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
      >
    >,
    TError = void,
  >(
    queryClient: QueryClient,
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ): Promise<QueryClient> => {
    const queryOptions =
      getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryOptions(
        spaceGroupId,
        options,
      );

    await queryClient.prefetchQuery(queryOptions);

    return queryClient;
  };

export const getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
      >
    >,
    TError = void,
  >(
    spaceGroupId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<
              typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
            >
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupQueryKey(
        spaceGroupId,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
        >
      >
    > = ({ signal }) =>
      discussionControllerGetUserJoinedDiscussionsInSpaceGroup(
        spaceGroupId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<
          typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
        >
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspenseQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
      >
    >
  >;
export type DiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspenseQueryError =
  void;

export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get joined discussions of a space group
 */

export function useDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup>
  >,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<
            typeof discussionControllerGetUserJoinedDiscussionsInSpaceGroup
          >
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getDiscussionControllerGetUserJoinedDiscussionsInSpaceGroupSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves detailed discussion by ID.
 * @summary Get detailed discussion by ID
 */
export const discussionControllerGetDetailedDiscussionById = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<DiscussionDetails>(
    {
      url: `/discussions/${encodeURIComponent(String(id))}/details`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerGetDetailedDiscussionByIdQueryKey = (
  id: string,
) => {
  return [`/discussions/${id}/details`] as const;
};

export const getDiscussionControllerGetDetailedDiscussionByIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDiscussionControllerGetDetailedDiscussionByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>
  > = ({ signal }) =>
    discussionControllerGetDetailedDiscussionById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DiscussionControllerGetDetailedDiscussionByIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>
  >;
export type DiscussionControllerGetDetailedDiscussionByIdQueryError = void;

export function useDiscussionControllerGetDetailedDiscussionById<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetDetailedDiscussionById>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetDetailedDiscussionById>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetDetailedDiscussionById<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetDetailedDiscussionById>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetDetailedDiscussionById>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDiscussionControllerGetDetailedDiscussionById<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get detailed discussion by ID
 */

export function useDiscussionControllerGetDetailedDiscussionById<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getDiscussionControllerGetDetailedDiscussionByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get detailed discussion by ID
 */
export const prefetchDiscussionControllerGetDetailedDiscussionById = async <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getDiscussionControllerGetDetailedDiscussionByIdQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getDiscussionControllerGetDetailedDiscussionByIdSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetDetailedDiscussionById>
    >,
    TError = void,
  >(
    id: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetDetailedDiscussionById>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetDetailedDiscussionByIdQueryKey(id);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>
    > = ({ signal }) =>
      discussionControllerGetDetailedDiscussionById(id, requestOptions, signal);

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetDetailedDiscussionByIdSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetDetailedDiscussionById>>
  >;
export type DiscussionControllerGetDetailedDiscussionByIdSuspenseQueryError =
  void;

export function useDiscussionControllerGetDetailedDiscussionByIdSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetDetailedDiscussionByIdSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetDetailedDiscussionByIdSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get detailed discussion by ID
 */

export function useDiscussionControllerGetDetailedDiscussionByIdSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetDetailedDiscussionById>
  >,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetDetailedDiscussionById>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getDiscussionControllerGetDetailedDiscussionByIdSuspenseQueryOptions(
      id,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves top discussions of a post space.
 * @summary Get top discussions of a post space
 */
export const discussionControllerGetTopDiscussionsOfPostSpace = (
  postSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<DiscussionDetails[]>(
    {
      url: `/discussions/post-spaces/${encodeURIComponent(String(postSpaceId))}/discussions/top`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryKey = (
  postSpaceId: string,
) => {
  return [`/discussions/post-spaces/${postSpaceId}/discussions/top`] as const;
};

export const getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryOptions = <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryKey(postSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>>
  > = ({ signal }) =>
    discussionControllerGetTopDiscussionsOfPostSpace(
      postSpaceId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!postSpaceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type DiscussionControllerGetTopDiscussionsOfPostSpaceQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>>
  >;
export type DiscussionControllerGetTopDiscussionsOfPostSpaceQueryError = void;

export function useDiscussionControllerGetTopDiscussionsOfPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetTopDiscussionsOfPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
          >,
          TError,
          Awaited<
            ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useDiscussionControllerGetTopDiscussionsOfPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get top discussions of a post space
 */

export function useDiscussionControllerGetTopDiscussionsOfPostSpace<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryOptions(
      postSpaceId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get top discussions of a post space
 */
export const prefetchDiscussionControllerGetTopDiscussionsOfPostSpace = async <
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryOptions(
      postSpaceId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getDiscussionControllerGetTopDiscussionsOfPostSpaceSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
    >,
    TError = void,
  >(
    postSpaceId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getDiscussionControllerGetTopDiscussionsOfPostSpaceQueryKey(postSpaceId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
      >
    > = ({ signal }) =>
      discussionControllerGetTopDiscussionsOfPostSpace(
        postSpaceId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type DiscussionControllerGetTopDiscussionsOfPostSpaceSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>>
  >;
export type DiscussionControllerGetTopDiscussionsOfPostSpaceSuspenseQueryError =
  void;

export function useDiscussionControllerGetTopDiscussionsOfPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetTopDiscussionsOfPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useDiscussionControllerGetTopDiscussionsOfPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get top discussions of a post space
 */

export function useDiscussionControllerGetTopDiscussionsOfPostSpaceSuspense<
  TData = Awaited<
    ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
  >,
  TError = void,
>(
  postSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof discussionControllerGetTopDiscussionsOfPostSpace>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getDiscussionControllerGetTopDiscussionsOfPostSpaceSuspenseQueryOptions(
      postSpaceId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
