/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  BookFreeMeetingInputDto,
  BookFreeMeetingResponseDto,
  BookMeetingInputDto,
  BookMeetingResponseDto,
  BuyMessagesInputDto,
  BuyMessagesResponseDto,
  CanBookMeetingResponseDto,
  CanBuyMessagesResponseDto,
  GetMeetingAvailabilityResponseDto,
  GetMeetingsResponseDto,
  GetRescheduleAvailabilityResponseDto,
  MeetingDto,
  MessageAvailabilityResponseDto,
  MonetizationControllerGetGoogleAuthUrlParams,
  MonetizationControllerGoogleAuthRedirectParams,
  SelectRescheduleOptionInputDto,
  SuccessMessageResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get message availability
 */
export const monetizationControllerGetMessageAvailability = (
  creatorId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<MessageAvailabilityResponseDto>(
    {
      url: `/monetization/messages/availability/${encodeURIComponent(String(creatorId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerGetMessageAvailabilityQueryKey = (
  creatorId: string,
) => {
  return [`/monetization/messages/availability/${creatorId}`] as const;
};

export const getMonetizationControllerGetMessageAvailabilityQueryOptions = <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetMessageAvailabilityQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>
  > = ({ signal }) =>
    monetizationControllerGetMessageAvailability(
      creatorId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!creatorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMessageAvailabilityQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>
  >;
export type MonetizationControllerGetMessageAvailabilityQueryError = void;

export function useMonetizationControllerGetMessageAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMessageAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetMessageAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMessageAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMessageAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetMessageAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetMessageAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get message availability
 */

export function useMonetizationControllerGetMessageAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getMonetizationControllerGetMessageAvailabilityQueryOptions(
      creatorId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get message availability
 */
export const prefetchMonetizationControllerGetMessageAvailability = async <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMonetizationControllerGetMessageAvailabilityQueryOptions(
      creatorId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetMessageAvailabilitySuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof monetizationControllerGetMessageAvailability>
    >,
    TError = void,
  >(
    creatorId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMessageAvailability>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getMonetizationControllerGetMessageAvailabilityQueryKey(creatorId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>
    > = ({ signal }) =>
      monetizationControllerGetMessageAvailability(
        creatorId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type MonetizationControllerGetMessageAvailabilitySuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetMessageAvailability>>
  >;
export type MonetizationControllerGetMessageAvailabilitySuspenseQueryError =
  void;

export function useMonetizationControllerGetMessageAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMessageAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMessageAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get message availability
 */

export function useMonetizationControllerGetMessageAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMessageAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMessageAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGetMessageAvailabilitySuspenseQueryOptions(
      creatorId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check if users can buy messages for a creator
 */
export const monetizationControllerCanBuyMessages = (
  creatorId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CanBuyMessagesResponseDto>(
    {
      url: `/monetization/messages/can-buy/${encodeURIComponent(String(creatorId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerCanBuyMessagesQueryKey = (
  creatorId: string,
) => {
  return [`/monetization/messages/can-buy/${creatorId}`] as const;
};

export const getMonetizationControllerCanBuyMessagesQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerCanBuyMessagesQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>
  > = ({ signal }) =>
    monetizationControllerCanBuyMessages(creatorId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!creatorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerCanBuyMessagesQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>
>;
export type MonetizationControllerCanBuyMessagesQueryError = void;

export function useMonetizationControllerCanBuyMessages<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBuyMessages<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerCanBuyMessages<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Check if users can buy messages for a creator
 */

export function useMonetizationControllerCanBuyMessages<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getMonetizationControllerCanBuyMessagesQueryOptions(
    creatorId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check if users can buy messages for a creator
 */
export const prefetchMonetizationControllerCanBuyMessages = async <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  queryClient: QueryClient,
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getMonetizationControllerCanBuyMessagesQueryOptions(
    creatorId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerCanBuyMessagesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerCanBuyMessagesQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>
  > = ({ signal }) =>
    monetizationControllerCanBuyMessages(creatorId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerCanBuyMessagesSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>>;
export type MonetizationControllerCanBuyMessagesSuspenseQueryError = void;

export function useMonetizationControllerCanBuyMessagesSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBuyMessagesSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBuyMessagesSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Check if users can buy messages for a creator
 */

export function useMonetizationControllerCanBuyMessagesSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBuyMessages>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerCanBuyMessagesSuspenseQueryOptions(
      creatorId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const monetizationControllerGetGoogleAuthUrl = (
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/monetization/url`, method: 'GET', params, signal },
    options,
  );
};

export const getMonetizationControllerGetGoogleAuthUrlQueryKey = (
  params: MonetizationControllerGetGoogleAuthUrlParams,
) => {
  return [`/monetization/url`, ...(params ? [params] : [])] as const;
};

export const getMonetizationControllerGetGoogleAuthUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetGoogleAuthUrlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
  > = ({ signal }) =>
    monetizationControllerGetGoogleAuthUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetGoogleAuthUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
>;
export type MonetizationControllerGetGoogleAuthUrlQueryError = unknown;

export function useMonetizationControllerGetGoogleAuthUrl<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetGoogleAuthUrl<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetGoogleAuthUrl<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useMonetizationControllerGetGoogleAuthUrl<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getMonetizationControllerGetGoogleAuthUrlQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchMonetizationControllerGetGoogleAuthUrl = async <
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getMonetizationControllerGetGoogleAuthUrlQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetGoogleAuthUrlSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetGoogleAuthUrlQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
  > = ({ signal }) =>
    monetizationControllerGetGoogleAuthUrl(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetGoogleAuthUrlSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>
  >;
export type MonetizationControllerGetGoogleAuthUrlSuspenseQueryError = unknown;

export function useMonetizationControllerGetGoogleAuthUrlSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetGoogleAuthUrlSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetGoogleAuthUrlSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};

export function useMonetizationControllerGetGoogleAuthUrlSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
  TError = unknown,
>(
  params: MonetizationControllerGetGoogleAuthUrlParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetGoogleAuthUrl>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGetGoogleAuthUrlSuspenseQueryOptions(
      params,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const monetizationControllerGoogleAuthRedirect = (
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/monetization/callback`, method: 'GET', params, signal },
    options,
  );
};

export const getMonetizationControllerGoogleAuthRedirectQueryKey = (
  params: MonetizationControllerGoogleAuthRedirectParams,
) => {
  return [`/monetization/callback`, ...(params ? [params] : [])] as const;
};

export const getMonetizationControllerGoogleAuthRedirectQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGoogleAuthRedirectQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
  > = ({ signal }) =>
    monetizationControllerGoogleAuthRedirect(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGoogleAuthRedirectQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
>;
export type MonetizationControllerGoogleAuthRedirectQueryError = unknown;

export function useMonetizationControllerGoogleAuthRedirect<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGoogleAuthRedirect<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGoogleAuthRedirect<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useMonetizationControllerGoogleAuthRedirect<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getMonetizationControllerGoogleAuthRedirectQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchMonetizationControllerGoogleAuthRedirect = async <
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getMonetizationControllerGoogleAuthRedirectQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGoogleAuthRedirectSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGoogleAuthRedirectQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
  > = ({ signal }) =>
    monetizationControllerGoogleAuthRedirect(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGoogleAuthRedirectSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>
  >;
export type MonetizationControllerGoogleAuthRedirectSuspenseQueryError =
  unknown;

export function useMonetizationControllerGoogleAuthRedirectSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGoogleAuthRedirectSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGoogleAuthRedirectSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};

export function useMonetizationControllerGoogleAuthRedirectSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
  TError = unknown,
>(
  params: MonetizationControllerGoogleAuthRedirectParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGoogleAuthRedirect>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGoogleAuthRedirectSuspenseQueryOptions(
      params,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Buy messages for a creator
 */
export const monetizationControllerBuyMessages = (
  buyMessagesInputDto: BuyMessagesInputDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<BuyMessagesResponseDto>(
    {
      url: `/monetization/messages/buy`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: buyMessagesInputDto,
      signal,
    },
    options,
  );
};

export const getMonetizationControllerBuyMessagesMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBuyMessages>>,
    TError,
    { data: BuyMessagesInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerBuyMessages>>,
  TError,
  { data: BuyMessagesInputDto },
  TContext
> => {
  const mutationKey = ['monetizationControllerBuyMessages'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerBuyMessages>>,
    { data: BuyMessagesInputDto }
  > = (props) => {
    const { data } = props ?? {};

    return monetizationControllerBuyMessages(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerBuyMessagesMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerBuyMessages>>
>;
export type MonetizationControllerBuyMessagesMutationBody = BuyMessagesInputDto;
export type MonetizationControllerBuyMessagesMutationError = void;

/**
 * @summary Buy messages for a creator
 */
export const useMonetizationControllerBuyMessages = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBuyMessages>>,
    TError,
    { data: BuyMessagesInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerBuyMessages>>,
  TError,
  { data: BuyMessagesInputDto },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerBuyMessagesMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Check if user can book a meeting with creator
 */
export const monetizationControllerCanBookMeeting = (
  creatorId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CanBookMeetingResponseDto>(
    {
      url: `/monetization/meetings/can-book/${encodeURIComponent(String(creatorId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerCanBookMeetingQueryKey = (
  creatorId: string,
) => {
  return [`/monetization/meetings/can-book/${creatorId}`] as const;
};

export const getMonetizationControllerCanBookMeetingQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerCanBookMeetingQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>
  > = ({ signal }) =>
    monetizationControllerCanBookMeeting(creatorId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!creatorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerCanBookMeetingQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>
>;
export type MonetizationControllerCanBookMeetingQueryError = void;

export function useMonetizationControllerCanBookMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBookMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerCanBookMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Check if user can book a meeting with creator
 */

export function useMonetizationControllerCanBookMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getMonetizationControllerCanBookMeetingQueryOptions(
    creatorId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Check if user can book a meeting with creator
 */
export const prefetchMonetizationControllerCanBookMeeting = async <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  queryClient: QueryClient,
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getMonetizationControllerCanBookMeetingQueryOptions(
    creatorId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerCanBookMeetingSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerCanBookMeetingQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>
  > = ({ signal }) =>
    monetizationControllerCanBookMeeting(creatorId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerCanBookMeetingSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>>;
export type MonetizationControllerCanBookMeetingSuspenseQueryError = void;

export function useMonetizationControllerCanBookMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBookMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerCanBookMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Check if user can book a meeting with creator
 */

export function useMonetizationControllerCanBookMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerCanBookMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerCanBookMeetingSuspenseQueryOptions(
      creatorId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get available meeting slots for a creator
 */
export const monetizationControllerGetMeetingAvailability = (
  creatorId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GetMeetingAvailabilityResponseDto>(
    {
      url: `/monetization/meetings/availability/${encodeURIComponent(String(creatorId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerGetMeetingAvailabilityQueryKey = (
  creatorId: string,
) => {
  return [`/monetization/meetings/availability/${creatorId}`] as const;
};

export const getMonetizationControllerGetMeetingAvailabilityQueryOptions = <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetMeetingAvailabilityQueryKey(creatorId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>
  > = ({ signal }) =>
    monetizationControllerGetMeetingAvailability(
      creatorId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!creatorId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMeetingAvailabilityQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>
  >;
export type MonetizationControllerGetMeetingAvailabilityQueryError = void;

export function useMonetizationControllerGetMeetingAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMeetingAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetMeetingAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMeetingAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetMeetingAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetMeetingAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get available meeting slots for a creator
 */

export function useMonetizationControllerGetMeetingAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getMonetizationControllerGetMeetingAvailabilityQueryOptions(
      creatorId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get available meeting slots for a creator
 */
export const prefetchMonetizationControllerGetMeetingAvailability = async <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  creatorId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMonetizationControllerGetMeetingAvailabilityQueryOptions(
      creatorId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetMeetingAvailabilitySuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof monetizationControllerGetMeetingAvailability>
    >,
    TError = void,
  >(
    creatorId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetMeetingAvailability>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getMonetizationControllerGetMeetingAvailabilityQueryKey(creatorId);

    const queryFn: QueryFunction<
      Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>
    > = ({ signal }) =>
      monetizationControllerGetMeetingAvailability(
        creatorId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type MonetizationControllerGetMeetingAvailabilitySuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetMeetingAvailability>>
  >;
export type MonetizationControllerGetMeetingAvailabilitySuspenseQueryError =
  void;

export function useMonetizationControllerGetMeetingAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get available meeting slots for a creator
 */

export function useMonetizationControllerGetMeetingAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetMeetingAvailability>
  >,
  TError = void,
>(
  creatorId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetMeetingAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGetMeetingAvailabilitySuspenseQueryOptions(
      creatorId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Book a free meeting with a creator
 */
export const monetizationControllerBookFreeMeeting = (
  bookFreeMeetingInputDto: BookFreeMeetingInputDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<BookFreeMeetingResponseDto>(
    {
      url: `/monetization/meetings/book-free`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bookFreeMeetingInputDto,
      signal,
    },
    options,
  );
};

export const getMonetizationControllerBookFreeMeetingMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>,
    TError,
    { data: BookFreeMeetingInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>,
  TError,
  { data: BookFreeMeetingInputDto },
  TContext
> => {
  const mutationKey = ['monetizationControllerBookFreeMeeting'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>,
    { data: BookFreeMeetingInputDto }
  > = (props) => {
    const { data } = props ?? {};

    return monetizationControllerBookFreeMeeting(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerBookFreeMeetingMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>
>;
export type MonetizationControllerBookFreeMeetingMutationBody =
  BookFreeMeetingInputDto;
export type MonetizationControllerBookFreeMeetingMutationError = void;

/**
 * @summary Book a free meeting with a creator
 */
export const useMonetizationControllerBookFreeMeeting = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>,
    TError,
    { data: BookFreeMeetingInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerBookFreeMeeting>>,
  TError,
  { data: BookFreeMeetingInputDto },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerBookFreeMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Book a meeting with a creator
 */
export const monetizationControllerBookMeeting = (
  bookMeetingInputDto: BookMeetingInputDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<BookMeetingResponseDto>(
    {
      url: `/monetization/meetings/book`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: bookMeetingInputDto,
      signal,
    },
    options,
  );
};

export const getMonetizationControllerBookMeetingMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBookMeeting>>,
    TError,
    { data: BookMeetingInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerBookMeeting>>,
  TError,
  { data: BookMeetingInputDto },
  TContext
> => {
  const mutationKey = ['monetizationControllerBookMeeting'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerBookMeeting>>,
    { data: BookMeetingInputDto }
  > = (props) => {
    const { data } = props ?? {};

    return monetizationControllerBookMeeting(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerBookMeetingMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerBookMeeting>>
>;
export type MonetizationControllerBookMeetingMutationBody = BookMeetingInputDto;
export type MonetizationControllerBookMeetingMutationError = void;

/**
 * @summary Book a meeting with a creator
 */
export const useMonetizationControllerBookMeeting = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerBookMeeting>>,
    TError,
    { data: BookMeetingInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerBookMeeting>>,
  TError,
  { data: BookMeetingInputDto },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerBookMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get user's meetings (both as creator and fan)
 */
export const monetizationControllerGetMeetings = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GetMeetingsResponseDto>(
    { url: `/monetization/meetings`, method: 'GET', signal },
    options,
  );
};

export const getMonetizationControllerGetMeetingsQueryKey = () => {
  return [`/monetization/meetings`] as const;
};

export const getMonetizationControllerGetMeetingsQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMonetizationControllerGetMeetingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
  > = ({ signal }) => monetizationControllerGetMeetings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMeetingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
>;
export type MonetizationControllerGetMeetingsQueryError = void;

export function useMonetizationControllerGetMeetings<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
        TError,
        Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetings<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
        TError,
        Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetMeetings<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get user's meetings (both as creator and fan)
 */

export function useMonetizationControllerGetMeetings<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getMonetizationControllerGetMeetingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user's meetings (both as creator and fan)
 */
export const prefetchMonetizationControllerGetMeetings = async <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMonetizationControllerGetMeetingsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetMeetingsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getMonetizationControllerGetMeetingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
  > = ({ signal }) => monetizationControllerGetMeetings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMeetingsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGetMeetings>>
>;
export type MonetizationControllerGetMeetingsSuspenseQueryError = void;

export function useMonetizationControllerGetMeetingsSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingsSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingsSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get user's meetings (both as creator and fan)
 */

export function useMonetizationControllerGetMeetingsSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof monetizationControllerGetMeetings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGetMeetingsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a meeting
 */
export const monetizationControllerGetMeeting = (
  meetingId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<MeetingDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerGetMeetingQueryKey = (
  meetingId: string,
) => {
  return [`/monetization/meetings/${meetingId}`] as const;
};

export const getMonetizationControllerGetMeetingQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetMeetingQueryKey(meetingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
  > = ({ signal }) =>
    monetizationControllerGetMeeting(meetingId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!meetingId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMeetingQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
>;
export type MonetizationControllerGetMeetingQueryError = void;

export function useMonetizationControllerGetMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
          TError,
          Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a meeting
 */

export function useMonetizationControllerGetMeeting<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getMonetizationControllerGetMeetingQueryOptions(
    meetingId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a meeting
 */
export const prefetchMonetizationControllerGetMeeting = async <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  queryClient: QueryClient,
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getMonetizationControllerGetMeetingQueryOptions(
    meetingId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetMeetingSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetMeetingQueryKey(meetingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
  > = ({ signal }) =>
    monetizationControllerGetMeeting(meetingId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetMeetingSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerGetMeeting>>
>;
export type MonetizationControllerGetMeetingSuspenseQueryError = void;

export function useMonetizationControllerGetMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get a meeting
 */

export function useMonetizationControllerGetMeetingSuspense<
  TData = Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof monetizationControllerGetMeeting>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getMonetizationControllerGetMeetingSuspenseQueryOptions(
    meetingId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Accept a meeting request
 */
export const monetizationControllerAcceptMeeting = (
  meetingId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}/accept`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerAcceptMeetingMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationKey = ['monetizationControllerAcceptMeeting'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>,
    { meetingId: string }
  > = (props) => {
    const { meetingId } = props ?? {};

    return monetizationControllerAcceptMeeting(meetingId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerAcceptMeetingMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>
>;

export type MonetizationControllerAcceptMeetingMutationError = void;

/**
 * @summary Accept a meeting request
 */
export const useMonetizationControllerAcceptMeeting = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerAcceptMeeting>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerAcceptMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Decline a meeting request
 */
export const monetizationControllerDeclineMeeting = (
  meetingId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}/decline`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerDeclineMeetingMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationKey = ['monetizationControllerDeclineMeeting'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>,
    { meetingId: string }
  > = (props) => {
    const { meetingId } = props ?? {};

    return monetizationControllerDeclineMeeting(meetingId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerDeclineMeetingMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>
>;

export type MonetizationControllerDeclineMeetingMutationError = void;

/**
 * @summary Decline a meeting request
 */
export const useMonetizationControllerDeclineMeeting = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerDeclineMeeting>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerDeclineMeetingMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get available time slots for rescheduling
 */
export const monetizationControllerGetRescheduleAvailability = (
  meetingId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GetRescheduleAvailabilityResponseDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}/reschedule-availability`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerGetRescheduleAvailabilityQueryKey = (
  meetingId: string,
) => {
  return [
    `/monetization/meetings/${meetingId}/reschedule-availability`,
  ] as const;
};

export const getMonetizationControllerGetRescheduleAvailabilityQueryOptions = <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getMonetizationControllerGetRescheduleAvailabilityQueryKey(meetingId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof monetizationControllerGetRescheduleAvailability>>
  > = ({ signal }) =>
    monetizationControllerGetRescheduleAvailability(
      meetingId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!meetingId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof monetizationControllerGetRescheduleAvailability>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type MonetizationControllerGetRescheduleAvailabilityQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetRescheduleAvailability>>
  >;
export type MonetizationControllerGetRescheduleAvailabilityQueryError = void;

export function useMonetizationControllerGetRescheduleAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetRescheduleAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetRescheduleAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetRescheduleAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetRescheduleAvailability>
          >,
          TError,
          Awaited<
            ReturnType<typeof monetizationControllerGetRescheduleAvailability>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useMonetizationControllerGetRescheduleAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get available time slots for rescheduling
 */

export function useMonetizationControllerGetRescheduleAvailability<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getMonetizationControllerGetRescheduleAvailabilityQueryOptions(
      meetingId,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get available time slots for rescheduling
 */
export const prefetchMonetizationControllerGetRescheduleAvailability = async <
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  queryClient: QueryClient,
  meetingId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getMonetizationControllerGetRescheduleAvailabilityQueryOptions(
      meetingId,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getMonetizationControllerGetRescheduleAvailabilitySuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof monetizationControllerGetRescheduleAvailability>
    >,
    TError = void,
  >(
    meetingId: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof monetizationControllerGetRescheduleAvailability>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getMonetizationControllerGetRescheduleAvailabilityQueryKey(meetingId);

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof monetizationControllerGetRescheduleAvailability>
      >
    > = ({ signal }) =>
      monetizationControllerGetRescheduleAvailability(
        meetingId,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof monetizationControllerGetRescheduleAvailability>
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type MonetizationControllerGetRescheduleAvailabilitySuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerGetRescheduleAvailability>>
  >;
export type MonetizationControllerGetRescheduleAvailabilitySuspenseQueryError =
  void;

export function useMonetizationControllerGetRescheduleAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetRescheduleAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useMonetizationControllerGetRescheduleAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get available time slots for rescheduling
 */

export function useMonetizationControllerGetRescheduleAvailabilitySuspense<
  TData = Awaited<
    ReturnType<typeof monetizationControllerGetRescheduleAvailability>
  >,
  TError = void,
>(
  meetingId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof monetizationControllerGetRescheduleAvailability>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getMonetizationControllerGetRescheduleAvailabilitySuspenseQueryOptions(
      meetingId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Request to reschedule a meeting
 */
export const monetizationControllerRequestReschedule = (
  meetingId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}/reschedule`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getMonetizationControllerRequestRescheduleMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationKey = ['monetizationControllerRequestReschedule'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>,
    { meetingId: string }
  > = (props) => {
    const { meetingId } = props ?? {};

    return monetizationControllerRequestReschedule(meetingId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerRequestRescheduleMutationResult = NonNullable<
  Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>
>;

export type MonetizationControllerRequestRescheduleMutationError = void;

/**
 * @summary Request to reschedule a meeting
 */
export const useMonetizationControllerRequestReschedule = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>,
    TError,
    { meetingId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerRequestReschedule>>,
  TError,
  { meetingId: string },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerRequestRescheduleMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Select a reschedule option
 */
export const monetizationControllerSelectRescheduleOption = (
  meetingId: string,
  selectRescheduleOptionInputDto: SelectRescheduleOptionInputDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/monetization/meetings/${encodeURIComponent(String(meetingId))}/select-reschedule`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: selectRescheduleOptionInputDto,
      signal,
    },
    options,
  );
};

export const getMonetizationControllerSelectRescheduleOptionMutationOptions = <
  TError = void | SuccessMessageResponseDto,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>,
    TError,
    { meetingId: string; data: SelectRescheduleOptionInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>,
  TError,
  { meetingId: string; data: SelectRescheduleOptionInputDto },
  TContext
> => {
  const mutationKey = ['monetizationControllerSelectRescheduleOption'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>,
    { meetingId: string; data: SelectRescheduleOptionInputDto }
  > = (props) => {
    const { meetingId, data } = props ?? {};

    return monetizationControllerSelectRescheduleOption(
      meetingId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type MonetizationControllerSelectRescheduleOptionMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>
  >;
export type MonetizationControllerSelectRescheduleOptionMutationBody =
  SelectRescheduleOptionInputDto;
export type MonetizationControllerSelectRescheduleOptionMutationError =
  void | SuccessMessageResponseDto;

/**
 * @summary Select a reschedule option
 */
export const useMonetizationControllerSelectRescheduleOption = <
  TError = void | SuccessMessageResponseDto,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>,
    TError,
    { meetingId: string; data: SelectRescheduleOptionInputDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof monetizationControllerSelectRescheduleOption>>,
  TError,
  { meetingId: string; data: SelectRescheduleOptionInputDto },
  TContext
> => {
  const mutationOptions =
    getMonetizationControllerSelectRescheduleOptionMutationOptions(options);

  return useMutation(mutationOptions);
};
