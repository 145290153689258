import { useQueries } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import {
  CourseLesson,
  CourseWithChaptersAndLessons,
  coursesSpacesControllerGetLessonProgress,
  getCoursesSpacesControllerGetLessonProgressQueryKey,
} from '@/api';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../accordion';

type Props = {
  currentLesson: CourseLesson;
  course: CourseWithChaptersAndLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
  courseId: string;
};

function isCourseStillLocked(course: CourseWithChaptersAndLessons) {
  // Si el usuario ya tiene acceso, no está bloqueado
  if (course.hasAccess) return false;
  // Si no es Time Unlock o no hay fecha, no aplicamos bloqueo
  if (course.accessType !== 'TIME_UNLOCK' || !course.unlockDate) return false;

  const now = new Date();
  const unlockDate = new Date(course.unlockDate);

  // true => todavía no se ha alcanzado la fecha, por lo tanto bloqueado
  return now < unlockDate;
}

const CourseLessonsProgress = ({
  course,
  currentLesson,
  brandId,
  spaceGroupId,
  coursesSpaceId,
  courseId,
}: Props) => {
  const navigate = useNavigate();

  // Obtener el progreso de cada lección
  const lessonProgressQuery = useQueries({
    queries: course.lessons!.map((lesson) => ({
      queryKey: getCoursesSpacesControllerGetLessonProgressQueryKey(lesson.id),
      queryFn: () => coursesSpacesControllerGetLessonProgress(lesson.id),
    })),
  });

  const lessonProgress = lessonProgressQuery.map((query, index) => {
    return {
      completed: query.data?.completed ?? false,
      lessonId: course.lessons?.[index].id,
    };
  });

  // Cantidad de lecciones finalizadas
  const getDoneLessons = () => {
    return lessonProgress.filter((l) => l.completed).length;
  };

  // Porcentaje de completitud
  const getDonePercentage = () => {
    if (!course.lessons?.length) return 0;
    return (getDoneLessons() / course.lessons.length) * 100;
  };

  // Verificar si el curso está bloqueado por TIME_UNLOCK
  const courseLocked = isCourseStillLocked(course);

  const onLessonClick = (lessonId: string) => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/chapter/${currentLesson.chapterId}/lessons/${lessonId}`,
    );
  };

  return (
    <div className="flex h-fit flex-col gap-7.5 pb-10 pt-7.5 md:min-w-100">
      {/* Barra de progreso */}
      <div className="flex w-full flex-col gap-2.5">
        <div className="flex w-full items-center gap-6">
          <progress
            value={getDoneLessons()}
            max={course.lessons?.length}
            className="h-2.5 w-full [&::-webkit-progress-bar]:rounded-full [&::-webkit-progress-bar]:bg-dark-bgGrey [&::-webkit-progress-value]:rounded-full [&::-webkit-progress-value]:bg-brand"
          />
          <div className="text-nowrap rounded-lg border border-green-accent/10 bg-green-accent/10 px-2.5 py-[5px] text-sm font-medium">
            {getDonePercentage().toFixed(0)}% complete
          </div>
        </div>
      </div>
      <Accordion
        type="multiple"
        className="flex flex-col gap-2.5"
        defaultValue={[currentLesson.chapterId ?? '']}
      >
        {course.chapters?.map((chapter) => {
          return (
            <AccordionItem
              key={chapter.id}
              value={chapter.id}
              className="border-none"
            >
              <AccordionTrigger className="flex flex-row overflow-clip rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
                <h3 className="text-base font-medium">{chapter.name}</h3>
              </AccordionTrigger>
              <AccordionContent className="flex flex-col gap-2.5 p-2">
                {chapter.lessons?.map((lesson, index) => {
                  const thisLessonCompleted = lessonProgress[index].completed;
                  // Si el curso está bloqueado, desactivamos el botón de la lección.
                  // También, podemos mostrar un candado o un label distinto.

                  const isLessonSelected =
                    currentLesson.id === lesson.id &&
                    currentLesson.chapterId === chapter.id;

                  return (
                    <Button
                      key={lesson.id}
                      variant="none"
                      className={cn(
                        'flex h-fit items-center justify-start gap-2.5 p-4 text-start',
                        isLessonSelected && 'bg-light-3 dark:bg-dark-3',
                      )}
                      //disabled={courseLocked}
                      onClick={() => onLessonClick(lesson.id)}
                    >
                      {thisLessonCompleted ? (
                        // Lección completada
                        <div className="h-3 min-w-3 rounded-full bg-green-accent/50" />
                      ) : courseLocked ? (
                        // Curso bloqueado => mostramos candado u otro icono
                        <div className="text-red-500">🔒</div>
                      ) : (
                        // Número de lección
                        index + 1
                      )}
                      <h3 className="text-base font-medium">{lesson.name}</h3>
                    </Button>
                  );
                })}
              </AccordionContent>
            </AccordionItem>
          );
        })}
      </Accordion>
    </div>
  );
};

export default CourseLessonsProgress;
