import { CometChat } from '@cometchat/chat-sdk-javascript';
import { useQueryClient } from '@tanstack/react-query';
import {
  compareAsc,
  compareDesc,
  format,
  formatDistanceToNow,
  isBefore,
} from 'date-fns';
import { ClockIcon, XIcon } from 'lucide-react';
import { useMemo, useState } from 'react';

import {
  MeetingDto,
  MeetingDtoStatus,
  getMonetizationControllerGetMeetingsQueryKey,
  useMonetizationControllerAcceptMeeting,
  useMonetizationControllerDeclineMeeting,
  useMonetizationControllerGetMeetings,
  useMonetizationControllerRequestReschedule,
} from '@/api';
import { CalendarIcon } from '@/assets/icon/calendar';
import { CheckIcon } from '@/assets/icon/check-alt';
import { PhoneIcon } from '@/assets/icon/phone';
import { Loading } from '@/components/Loading';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuth } from '@/hooks/useContext';

import { RescheduleMeetingModal } from './RescheduleMeetingModal';

import { Avatar, AvatarFallback, AvatarImage } from '../avatar';

const filterMeetings = (
  meetings: MeetingDto[],
): {
  pending: MeetingDto[];
  approved: MeetingDto[];
  completed: MeetingDto[];
  declined: MeetingDto[];
} => {
  const statuses = {
    pending: [MeetingDtoStatus.PENDING, MeetingDtoStatus.RESCHEDULING],
    approved: [MeetingDtoStatus.ACCEPTED, MeetingDtoStatus.RESCHEDULED],
    completed: [MeetingDtoStatus.COMPLETED],
    declined: [MeetingDtoStatus.DECLINED, MeetingDtoStatus.CANCELLED],
  } as {
    pending: string[];
    approved: string[];
    completed: string[];
    declined: string[];
  };

  return {
    pending: meetings
      .filter((meeting) => statuses.pending.includes(meeting.status))
      .toSorted((a, b) => compareDesc(a.createdAt, b.createdAt)),
    approved: meetings
      .filter((meeting) => statuses.approved.includes(meeting.status))
      .toSorted((a, b) => compareAsc(a.startTime, b.startTime)),
    completed: meetings
      .filter((meeting) => statuses.completed.includes(meeting.status))
      .toSorted((a, b) => compareAsc(a.startTime, b.startTime)),
    declined: meetings
      .filter((meeting) => statuses.declined.includes(meeting.status))
      .toSorted((a, b) => compareAsc(a.startTime, b.startTime)),
  };
};

const MeetingRequests = () => {
  const meetingsQuery = useMonetizationControllerGetMeetings();

  if (meetingsQuery.isLoading) {
    return <Loading />;
  }

  const meetings = meetingsQuery.data?.meetings ?? [];

  return <MeetingRequestsInner meetings={meetings} />;
};

const MeetingRequestsInner = ({ meetings }: { meetings: MeetingDto[] }) => {
  const { pending, approved, completed, declined } = useMemo(
    () => filterMeetings(meetings),
    [meetings],
  );

  return (
    <Tabs defaultValue="pending" className="h-full w-full overflow-auto">
      <TabsList className="mb-6 flex justify-start gap-8 rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
        <TabsTrigger
          value="pending"
          className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
        >
          Pending ({pending.length})
        </TabsTrigger>
        <TabsTrigger
          value="approved"
          className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
        >
          Approved ({approved.length})
        </TabsTrigger>
        <TabsTrigger
          value="completed"
          className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
        >
          Completed
        </TabsTrigger>
        <TabsTrigger
          value="declined"
          className="rounded-none pb-2.5 data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
        >
          Declined
        </TabsTrigger>
      </TabsList>
      <TabsContent value="pending" className="mt-0 flex flex-col gap-4">
        {pending.length > 0 ? (
          pending.map(
            (request) =>
              !(!request.isFreeCall && request.paymentStatus === 'PENDING') && (
                <MeetingRequestCard key={request.id} request={request} />
              ),
          )
        ) : (
          <div className="text-center text-gray-500">No pending meetings</div>
        )}
      </TabsContent>
      <TabsContent value="approved" className="mt-0 flex flex-col gap-4">
        {approved.length > 0 ? (
          approved.map((request) => (
            <MeetingRequestCard key={request.id} request={request} />
          ))
        ) : (
          <div className="text-center text-gray-500">No approved meetings</div>
        )}
      </TabsContent>
      <TabsContent value="completed" className="mt-0 flex flex-col gap-4">
        {completed.length > 0 ? (
          completed.map((request) => (
            <MeetingRequestCard key={request.id} request={request} />
          ))
        ) : (
          <div className="text-center text-gray-500">No completed meetings</div>
        )}
      </TabsContent>
      <TabsContent value="declined" className="mt-0 flex flex-col gap-4">
        {declined.length > 0 ? (
          declined.map((request) => (
            <MeetingRequestCard key={request.id} request={request} />
          ))
        ) : (
          <div className="text-center text-gray-500">No declined meetings</div>
        )}
      </TabsContent>
    </Tabs>
  );
};

const MeetingRequestCard = ({ request }: { request: MeetingDto }) => {
  const { user } = useAuth();
  const [meetLink, setMeetLink] = useState<string | null>(null);

  const queryClient = useQueryClient();

  const invalidateMeetings = () => {
    queryClient.invalidateQueries({
      queryKey: getMonetizationControllerGetMeetingsQueryKey(),
    });
  };

  const acceptMeeting = useMonetizationControllerAcceptMeeting({
    mutation: {
      onSuccess: (data: any) => {
        console.log('Meeting accepted:', data);
        setMeetLink(data.googleMeetUrl ?? null);
        //invalidateMeetings();
      },
    },
  });
  const declineMeeting = useMonetizationControllerDeclineMeeting({
    mutation: {
      onSuccess: invalidateMeetings,
    },
  });
  const requestReschedule = useMonetizationControllerRequestReschedule({
    mutation: {
      onSuccess: invalidateMeetings,
    },
  });

  const renderStatusBadge = () => {
    const badges = {
      [MeetingDtoStatus.COMPLETED]: (
        <div className="flex w-fit items-center gap-1 rounded-sm bg-green-base p-1 text-xxs font-medium text-white">
          <CheckIcon className="h-2.5 w-2.5 flex-shrink-0 stroke-white" />
          Completed
        </div>
      ),
      [MeetingDtoStatus.DECLINED]: (
        <div className="flex w-fit items-center gap-1 rounded-sm bg-error p-1 text-xxs font-medium text-white">
          <XIcon className="h-2.5 w-2.5 flex-shrink-0 stroke-white" />
          Declined
        </div>
      ),
      [MeetingDtoStatus.CANCELLED]: (
        <div className="flex w-fit items-center gap-1 rounded-sm bg-error p-1 text-xxs font-medium text-white">
          <XIcon className="h-2.5 w-2.5 flex-shrink-0 stroke-white" />
          Cancelled
        </div>
      ),
    };

    const now = new Date();
    const timeUntilStart = formatDistanceToNow(request.startTime);
    const showCountdown =
      isBefore(now, request.startTime) &&
      [MeetingDtoStatus.ACCEPTED, MeetingDtoStatus.RESCHEDULED].includes(
        // @ts-expect-error
        request.status,
      );

    return (
      <div className="flex gap-1">
        <div className="flex w-fit items-center gap-1 rounded-sm bg-[#8A69E9] p-1 text-xxs font-medium text-white">
          <PhoneIcon className="h-2.5 w-2.5 flex-shrink-0 stroke-white" />
          {request.isFreeCall || request.price === 0
            ? 'Free'
            : `$${request.price}`}
        </div>
        {showCountdown && (
          <div className="flex w-fit items-center gap-1 rounded-sm bg-[#D55F2A] p-1 text-xxs font-medium text-white">
            <ClockIcon className="h-2.5 w-2.5 flex-shrink-0 stroke-white" />
            Starts in {timeUntilStart}
          </div>
        )}
        {/* @ts-expect-error */}
        {badges[request.status]}
      </div>
    );
  };

  const renderActions = () => {
    if (
      (request.status === MeetingDtoStatus.ACCEPTED ||
        request.status === MeetingDtoStatus.RESCHEDULED) &&
      request.creatorId === user?.id
    ) {
      const timeUntilStart =
        new Date(request.startTime).getTime() - new Date().getTime();
      if (timeUntilStart > 0) {
        return (
          <Button
            // disabled={timeUntilStart > 15 * 60 * 1000}
            className="w-fit"
            onClick={() => {
              const receiverID = request.fan.id;
              const callType = CometChat.CALL_TYPE.VIDEO;
              const receiverType = CometChat.RECEIVER_TYPE.USER;

              const call = new CometChat.Call(
                receiverID,
                callType,
                receiverType,
              );

              CometChat.initiateCall(call).then(
                (outGoingCall) => {
                  console.log('Call initiated successfully:', outGoingCall);
                },
                (error) => {
                  console.log(
                    'Call initialization failed with exception:',
                    error,
                  );
                },
              );
            }}
          >
            Start call
          </Button>
        );
      }
    }

    if (
      [MeetingDtoStatus.PENDING].includes(
        // @ts-expect-error
        request.status,
      ) &&
      request.creatorId === user?.id
    ) {
      return (
        <div className="flex gap-2.5">
          <Button
            onClick={() => acceptMeeting.mutate({ meetingId: request.id })}
            className="flex items-center gap-2"
            disabled={
              acceptMeeting.isPending ||
              declineMeeting.isPending ||
              requestReschedule.isPending
            }
          >
            Accept <CheckIcon className="h-5 w-5 flex-shrink-0 stroke-white" />
          </Button>
          <Button
            onClick={() => declineMeeting.mutate({ meetingId: request.id })}
            className="flex items-center gap-2 bg-dark-4 hover:bg-dark-3"
            disabled={
              acceptMeeting.isPending ||
              declineMeeting.isPending ||
              requestReschedule.isPending
            }
          >
            Decline <XIcon className="h-5 w-5 flex-shrink-0 stroke-white" />
          </Button>
          <Button
            onClick={() => requestReschedule.mutate({ meetingId: request.id })}
            className="flex items-center gap-2 bg-dark-4 hover:bg-dark-3"
            disabled={
              acceptMeeting.isPending ||
              declineMeeting.isPending ||
              requestReschedule.isPending
            }
          >
            Reschedule
          </Button>
        </div>
      );
    }

    if (
      [MeetingDtoStatus.RESCHEDULING].includes(
        // @ts-expect-error
        request.status,
      ) &&
      request.creatorId === user?.id
    ) {
      return (
        <div className="text-sm text-textParagraph dark:text-dark-textParagraph">
          <span className="font-semibold">You requested a reschedule</span>
          <br />
          Waiting for {request.fan.name} to select a new time
        </div>
      );
    }

    if (
      [MeetingDtoStatus.RESCHEDULING].includes(
        // @ts-expect-error
        request.status,
      ) &&
      request.fanId === user?.id
    ) {
      return (
        <div className="flex flex-col items-start gap-2.5">
          <span className="text-sm text-textParagraph dark:text-dark-textParagraph">
            {request.creator.name} wants to reschedule
          </span>
          <RescheduleMeetingModal
            meeting={request}
            trigger={
              <Button className="flex items-center gap-2">
                Select new time{' '}
                <CheckIcon className="h-5 w-5 flex-shrink-0 stroke-white" />
              </Button>
            }
          />
        </div>
      );
    }

    return null;
  };

  // if i am the creator, the text should be "Call with fan name"
  // if i am the fan, the text should be "Call with creator name"
  const text =
    user?.id === request.creatorId
      ? `Call with ${request.fan.name}`
      : `Call with ${request.creator.name}`;

  const avatarUrl =
    user?.id === request.creatorId
      ? request.fan.avatarUrl
      : request.creator.avatarUrl;

  const fallbackName: string | null =
    user?.id === request.creatorId ? request.fan.name : request.creator.name;

  return (
    <div className="flex w-full gap-2.5 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
      <div className="h-10 w-10 flex-shrink-0 rounded-md bg-light-3 dark:bg-dark-3">
        <Avatar>
          <AvatarImage src={avatarUrl!} />
          <AvatarFallback>
            {fallbackName?.slice(0, 2).toUpperCase()}
          </AvatarFallback>
        </Avatar>
      </div>
      <div className="flex flex-col gap-4">
        {renderStatusBadge()}
        <div className="flex flex-col gap-2.5">
          <div className="text-base font-semibold">{text}</div>
          <div className="flex items-center gap-2.5 text-sm text-textParagraph dark:text-dark-textParagraph">
            <CalendarIcon className="h-4 w-4 flex-shrink-0 fill-black dark:fill-white" />
            {format(request.startTime, 'MMM d, yyyy')}
          </div>
          <div className="flex items-center gap-2.5 text-sm text-textParagraph dark:text-dark-textParagraph">
            <ClockIcon className="h-4 w-4 flex-shrink-0 stroke-black dark:stroke-white" />
            {format(request.startTime, 'hh:mm a')}
          </div>
        </div>
        {renderActions()}
        {/* 1. Mostrar el link guardado en estado local */}
        {meetLink && (
          <div style={{ marginTop: '1rem' }}>
            <strong>Google Meet link (local):</strong>{' '}
            <a href={meetLink} target="_blank" rel="noreferrer">
              {meetLink}
            </a>
          </div>
        )}

        {/* 2. Mostrar el link que llega de la BD a través del refetch */}
        {request.googleMeetUrl && (
          <div style={{ marginTop: '1rem' }}>
            <strong>Google Meet link (BD):</strong>{' '}
            <a
              href={String(request.googleMeetUrl)}
              target="_blank"
              rel="noreferrer"
            >
              {String(request.googleMeetUrl)}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default MeetingRequests;
