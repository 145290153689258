import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort';
import { Link, Navigate, useParams } from 'react-router-dom';

import {
  CourseWithChaptersAndLessons,
  CoursesSpace,
  getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey,
  useBrandsControllerGetSpaceGroupsOfBrand,
  useCoursesSpacesControllerGetCoursesBySpace,
  useCoursesSpacesControllerSaveOrganizedCourses,
  useNotificationsControllerResetCourseCounter,
} from '@/api';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import { buttonVariants } from '@/components/ui/button';
import CourseCard from '@/components/ui/courses-components/CourseCard';
import { useBrandPermits } from '@/hooks/useBrandPermits';

export const CoursePage = () => {
  const { brandId, spaceGroupId, coursesSpaceId } = useParams();
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(
    brandId as string,
  );

  if (spaceGroupsQuery.isLoading) {
    return <Loading />;
  }

  if (spaceGroupsQuery.isError) {
    throw new Error(
      `Error fetching space groups for brand ${brandId}, ${spaceGroupsQuery.error}`,
    );
  }

  const spaceGroups = spaceGroupsQuery.data ?? [];

  const spaceGroup = spaceGroups.find(
    (spaceGroup) => spaceGroup.id === spaceGroupId,
  );

  if (!spaceGroup) {
    return <Navigate to="/404" />;
  }

  const coursesSpace = spaceGroup.coursesSpaces.find(
    (space) => space.id === coursesSpaceId,
  );

  if (!coursesSpace) {
    return <Navigate to="/404" />;
  }

  return (
    <CoursesInner
      coursesSpace={coursesSpace}
      brandId={brandId as string}
      spaceGroupId={spaceGroupId as string}
    />
  );
};

const getAction = (
  hasPermits: boolean,
  brandId: string,
  spaceGroupId: string,
  coursesSpaceId: string,
) => {
  if (hasPermits) {
    return (
      <Link
        to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/create`}
        className={buttonVariants({ variant: 'default' })}
      >
        Add Course
      </Link>
    );
  }
  return null;
};

const CoursesInner = ({
  coursesSpace,
  brandId,
  spaceGroupId,
}: {
  coursesSpace: CoursesSpace;
  brandId: string;
  spaceGroupId: string;
}) => {
  const hasPermits = useBrandPermits();

  const [courses, setCourses] = useState<CourseWithChaptersAndLessons[]>([]);
  // const courses = coursesQuery.data ?? [];
  const coursesQuery = useCoursesSpacesControllerGetCoursesBySpace(
    coursesSpace.id,
  );

  const resetCourseCounterMutation =
    useNotificationsControllerResetCourseCounter();

  useEffect(() => {
    resetCourseCounterMutation.mutate({
      coursesSpaceId: coursesSpace.id,
    });
  }, [coursesSpace.id, coursesQuery.data]);

  useEffect(() => {
    if (coursesQuery.data) {
      setCourses(coursesQuery.data);
    }
  }, [coursesQuery.data]);

  const config = {
    showNotifications: true,
    label: coursesSpace.name,
    action: getAction(hasPermits, brandId, spaceGroupId, coursesSpace.id),
  };
  useUpdateConfigHeader(config, [
    coursesSpace.name,
    brandId,
    spaceGroupId,
    coursesSpace.id,
    hasPermits,
  ]);

  const queryClient = useQueryClient();
  const saveOrganizedCourses = useCoursesSpacesControllerSaveOrganizedCourses({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey:
            getCoursesSpacesControllerGetCourseWithLessonsByCourseIdQueryKey(
              coursesSpace.id,
            ),
        });
      },
    },
  });

  if (coursesQuery.isLoading) {
    return <Loading />;
  }

  const coursesFiltered = hasPermits
    ? courses
    : courses.filter((course) => course.isPublished);

  if (coursesFiltered.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-4">
        <p className="text-2xl text-gray-500">There are no courses here</p>
        {config.action}
      </div>
    );
  }

  const saveOrderOfCourses = (courses: CourseWithChaptersAndLessons[]) => {
    saveOrganizedCourses.mutate({
      coursesSpaceId: coursesSpace.id,
      data: {
        courseIds: courses.map((course) => course.id),
      },
    });
  };

  const onSortEnd = (oldIndex: number, newIndex: number) => {
    const newCourses = [...courses];
    const [removed] = newCourses.splice(oldIndex, 1);
    newCourses.splice(newIndex, 0, removed);
    setCourses(newCourses);
    saveOrderOfCourses(newCourses);
  };

  if (hasPermits) {
    return (
      <SortableList
        onSortEnd={onSortEnd}
        className="grid grid-cols-1 gap-5 overflow-auto p-6 py-16 md:grid-cols-2 md:px-25 lg:grid-cols-3"
        draggedItemClassName="dragged"
      >
        {courses.map((course) => (
          <SortableItem key={course.id}>
            <div>
              <CourseCard
                key={course.id}
                course={course}
                brandId={brandId}
                spaceGroupId={spaceGroupId}
                coursesSpaceId={coursesSpace.id}
              />
            </div>
          </SortableItem>
        ))}
      </SortableList>
    );
  }

  return (
    <div className="grid grid-cols-1 gap-5 overflow-auto p-6 py-16 md:grid-cols-2 md:px-25 lg:grid-cols-3">
      {coursesFiltered.map((course) => (
        <CourseCard
          key={course.id}
          course={course}
          brandId={brandId}
          spaceGroupId={spaceGroupId}
          coursesSpaceId={coursesSpace.id}
        />
      ))}
    </div>
  );
};
