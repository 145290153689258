export const CopyIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 10.5C6 8.6144 6 7.6716 6.58579 7.08579C7.1716 6.5 8.1144 6.5 10 6.5H10.6667C12.5523 6.5 13.4951 6.5 14.0809 7.08579C14.6667 7.6716 14.6667 8.6144 14.6667 10.5V11.1667C14.6667 13.0523 14.6667 13.9951 14.0809 14.5809C13.4951 15.1667 12.5523 15.1667 10.6667 15.1667H10C8.1144 15.1667 7.1716 15.1667 6.58579 14.5809C6 13.9951 6 13.0523 6 11.1667V10.5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.332 6.4987C11.3304 4.5273 11.3006 3.50617 10.7267 2.80698C10.6159 2.67196 10.4921 2.54815 10.3571 2.43734C9.6195 1.83203 8.5237 1.83203 6.33203 1.83203C4.14038 1.83203 3.04455 1.83203 2.30698 2.43734C2.17196 2.54814 2.04815 2.67196 1.93734 2.80698C1.33203 3.54455 1.33203 4.64038 1.33203 6.83203C1.33203 9.0237 1.33203 10.1195 1.93734 10.8571C2.04814 10.9921 2.17196 11.1159 2.30698 11.2267C3.00617 11.8006 4.0273 11.8304 5.9987 11.832"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
