import { z } from 'zod';

export const billingFrequencies = ['ONE TIME', 'RECURRING'] as const;
export const paymentFrequencies = ['WEEK', 'MONTH'] as const;

export const subscriptionPeriods = [
  'DAY_7',
  'MONTH_1',
  'MONTH_3',
  'MONTH_6',
  'YEAR_1',
] as const;

export const revoqueAccessAfterOptions = [
  'NEVER',
  'AFTER SEVEN DAYS',
  'AFTER ONE MONTH',
  'AFTER THREE MONTHS',
  'AFTER SIX MONTHS',
  'AFTER ONE YEAR',
  'AFTER OTHER # OF DAYS',
] as const;

export const formSchema = z.object({
  variantName: z.string().min(1, {
    message: 'Name is required',
  }),
  billingFrequency: z.enum(billingFrequencies).optional(),
  oneTimePrice: z.string().optional(),
  revoqueAccessAfter: z
    .enum(revoqueAccessAfterOptions)
    .optional()
    .default(revoqueAccessAfterOptions[0]),
  recurringPrice: z.string().optional(),
  subscriptionPeriod: z.enum(subscriptionPeriods).optional(),
  splitPayments: z.number().optional(),
  enableSplitPayments: z.boolean().optional(),
  revoqueAccessAfterDays: z.number().optional(),
  paymentFrequency: z.enum(paymentFrequencies).optional(),
});

export type FormSchema = z.infer<typeof formSchema>;
