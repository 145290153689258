import { AddSpacesToSpaceGroupDto } from '@/api';

import { CreateNewSpaceGroupState, SpaceType, Steps } from './CreateNew.store';

export const handleBack = (spaceType: SpaceType, step: Steps): Steps => {
  if (spaceType === 'Space') {
    return 'choose-space-or-space-group';
  }
  switch (step) {
    case 'create-new-space-group':
      return 'choose-space-or-space-group';
    case 'add-spaces':
      return 'create-new-space-group';
    case 'monetization':
      return 'add-spaces';
    case 'create-pricing-variant':
      return 'create-new-space-group';
    default:
      return 'none';
  }
};

export const onAddClick = (
  e: React.MouseEvent<HTMLButtonElement>,
): Partial<CreateNewSpaceGroupState> => {
  e.stopPropagation();
  return { step: 'choose-space-or-space-group', isDialogOpen: true };
};

export const chooseSpaceOrSpaceGroup = (
  type: SpaceType,
): Partial<CreateNewSpaceGroupState> => {
  const newStep = type === 'Space' ? 'add-spaces' : 'create-new-space-group';
  return {
    step: newStep,
    spaceType: type,
  };
};

export const handleChooseSpaces = (
  spaceType: SpaceType,
  spacesList: AddSpacesToSpaceGroupDto,
  set: (state: Partial<CreateNewSpaceGroupState>) => void,
  handleFinish: (spaces: AddSpacesToSpaceGroupDto) => void,
) => {
  if (spaceType === 'Space group') {
    set({ step: 'monetization', spacesList });
  } else {
    set({ spacesList });
    handleFinish(spacesList);
  }
};
