/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreateEventDto,
  CreateEventSpaceDto,
  Event,
  EventSpace,
  EventWithRsvps,
  GetEventRsvpResponseDto,
  SuccessMessageResponseDto,
  UpdateEventDto,
  UpdateEventSpaceDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new event space within a specific space group.
 * @summary Create a new event space
 */
export const eventSpacesControllerCreateEventSpace = (
  createEventSpaceDto: CreateEventSpaceDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<EventSpace>(
    {
      url: `/events-spaces`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createEventSpaceDto,
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerCreateEventSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>,
    TError,
    { data: CreateEventSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>,
  TError,
  { data: CreateEventSpaceDto },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerCreateEventSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>,
    { data: CreateEventSpaceDto }
  > = (props) => {
    const { data } = props ?? {};

    return eventSpacesControllerCreateEventSpace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerCreateEventSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>
>;
export type EventSpacesControllerCreateEventSpaceMutationBody =
  CreateEventSpaceDto;
export type EventSpacesControllerCreateEventSpaceMutationError = void;

/**
 * @summary Create a new event space
 */
export const useEventSpacesControllerCreateEventSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>,
    TError,
    { data: CreateEventSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEventSpace>>,
  TError,
  { data: CreateEventSpaceDto },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerCreateEventSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates a new event within a specific event space.
 * @summary Create a new event
 */
export const eventSpacesControllerCreateEvent = (
  eventSpaceId: string,
  createEventDto: CreateEventDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<Event>(
    {
      url: `/events-spaces/${encodeURIComponent(String(eventSpaceId))}/events`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createEventDto,
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerCreateEventMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>,
    TError,
    { eventSpaceId: string; data: CreateEventDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>,
  TError,
  { eventSpaceId: string; data: CreateEventDto },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerCreateEvent'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>,
    { eventSpaceId: string; data: CreateEventDto }
  > = (props) => {
    const { eventSpaceId, data } = props ?? {};

    return eventSpacesControllerCreateEvent(eventSpaceId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerCreateEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>
>;
export type EventSpacesControllerCreateEventMutationBody = CreateEventDto;
export type EventSpacesControllerCreateEventMutationError = void;

/**
 * @summary Create a new event
 */
export const useEventSpacesControllerCreateEvent = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>,
    TError,
    { eventSpaceId: string; data: CreateEventDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerCreateEvent>>,
  TError,
  { eventSpaceId: string; data: CreateEventDto },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerCreateEventMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all events associated with a specific event space.
 * @summary Get all events for an event space
 */
export const eventSpacesControllerGetEvents = (
  eventSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<EventWithRsvps[]>(
    {
      url: `/events-spaces/${encodeURIComponent(String(eventSpaceId))}/events`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerGetEventsQueryKey = (
  eventSpaceId: string,
) => {
  return [`/events-spaces/${eventSpaceId}/events`] as const;
};

export const getEventSpacesControllerGetEventsQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEventSpacesControllerGetEventsQueryKey(eventSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
  > = ({ signal }) =>
    eventSpacesControllerGetEvents(eventSpaceId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!eventSpaceId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetEventsQueryResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
>;
export type EventSpacesControllerGetEventsQueryError = void;

export function useEventSpacesControllerGetEvents<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEvents<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEventSpacesControllerGetEvents<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all events for an event space
 */

export function useEventSpacesControllerGetEvents<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEventSpacesControllerGetEventsQueryOptions(
    eventSpaceId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all events for an event space
 */
export const prefetchEventSpacesControllerGetEvents = async <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  queryClient: QueryClient,
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getEventSpacesControllerGetEventsQueryOptions(
    eventSpaceId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getEventSpacesControllerGetEventsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEventSpacesControllerGetEventsQueryKey(eventSpaceId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
  > = ({ signal }) =>
    eventSpacesControllerGetEvents(eventSpaceId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetEventsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>
>;
export type EventSpacesControllerGetEventsSuspenseQueryError = void;

export function useEventSpacesControllerGetEventsSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEventsSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEventsSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all events for an event space
 */

export function useEventSpacesControllerGetEventsSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
  TError = void,
>(
  eventSpaceId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEvents>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getEventSpacesControllerGetEventsSuspenseQueryOptions(
    eventSpaceId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves all event spaces associated with a specific space group.
 * @summary Get all event spaces for a space group
 */
export const eventSpacesControllerGetEventSpaces = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<EventSpace[]>(
    {
      url: `/events-spaces/${encodeURIComponent(String(spaceGroupId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerGetEventSpacesQueryKey = (
  spaceGroupId: string,
) => {
  return [`/events-spaces/${spaceGroupId}`] as const;
};

export const getEventSpacesControllerGetEventSpacesQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEventSpacesControllerGetEventSpacesQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>
  > = ({ signal }) =>
    eventSpacesControllerGetEventSpaces(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetEventSpacesQueryResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>
>;
export type EventSpacesControllerGetEventSpacesQueryError = void;

export function useEventSpacesControllerGetEventSpaces<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEventSpaces<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEventSpacesControllerGetEventSpaces<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all event spaces for a space group
 */

export function useEventSpacesControllerGetEventSpaces<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEventSpacesControllerGetEventSpacesQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all event spaces for a space group
 */
export const prefetchEventSpacesControllerGetEventSpaces = async <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getEventSpacesControllerGetEventSpacesQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getEventSpacesControllerGetEventSpacesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getEventSpacesControllerGetEventSpacesQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>
  > = ({ signal }) =>
    eventSpacesControllerGetEventSpaces(spaceGroupId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetEventSpacesSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>>;
export type EventSpacesControllerGetEventSpacesSuspenseQueryError = void;

export function useEventSpacesControllerGetEventSpacesSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEventSpacesSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetEventSpacesSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all event spaces for a space group
 */

export function useEventSpacesControllerGetEventSpacesSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
  TError = void,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetEventSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getEventSpacesControllerGetEventSpacesSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an event for the authenticated user.
 * @summary Update an event
 */
export const eventSpacesControllerUpdateEvent = (
  eventId: string,
  updateEventDto: UpdateEventDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/events-spaces/events/${encodeURIComponent(String(eventId))}/edit`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateEventDto,
    },
    options,
  );
};

export const getEventSpacesControllerUpdateEventMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>,
    TError,
    { eventId: string; data: UpdateEventDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>,
  TError,
  { eventId: string; data: UpdateEventDto },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerUpdateEvent'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>,
    { eventId: string; data: UpdateEventDto }
  > = (props) => {
    const { eventId, data } = props ?? {};

    return eventSpacesControllerUpdateEvent(eventId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerUpdateEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>
>;
export type EventSpacesControllerUpdateEventMutationBody = UpdateEventDto;
export type EventSpacesControllerUpdateEventMutationError = void;

/**
 * @summary Update an event
 */
export const useEventSpacesControllerUpdateEvent = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>,
    TError,
    { eventId: string; data: UpdateEventDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEvent>>,
  TError,
  { eventId: string; data: UpdateEventDto },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerUpdateEventMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates an event space for the authenticated user.
 * @summary Update an event space
 */
export const eventSpacesControllerUpdateEventSpace = (
  eventSpaceId: string,
  updateEventSpaceDto: UpdateEventSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<EventSpace>(
    {
      url: `/events-spaces/${encodeURIComponent(String(eventSpaceId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateEventSpaceDto,
    },
    options,
  );
};

export const getEventSpacesControllerUpdateEventSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>,
    TError,
    { eventSpaceId: string; data: UpdateEventSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>,
  TError,
  { eventSpaceId: string; data: UpdateEventSpaceDto },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerUpdateEventSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>,
    { eventSpaceId: string; data: UpdateEventSpaceDto }
  > = (props) => {
    const { eventSpaceId, data } = props ?? {};

    return eventSpacesControllerUpdateEventSpace(
      eventSpaceId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerUpdateEventSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>
>;
export type EventSpacesControllerUpdateEventSpaceMutationBody =
  UpdateEventSpaceDto;
export type EventSpacesControllerUpdateEventSpaceMutationError = void;

/**
 * @summary Update an event space
 */
export const useEventSpacesControllerUpdateEventSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>,
    TError,
    { eventSpaceId: string; data: UpdateEventSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerUpdateEventSpace>>,
  TError,
  { eventSpaceId: string; data: UpdateEventSpaceDto },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerUpdateEventSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes an event space for the authenticated user.
 * @summary Delete an event space
 */
export const eventSpacesControllerDeleteEventSpace = (
  eventSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/events-spaces/${encodeURIComponent(String(eventSpaceId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getEventSpacesControllerDeleteEventSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>,
    TError,
    { eventSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>,
  TError,
  { eventSpaceId: string },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerDeleteEventSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>,
    { eventSpaceId: string }
  > = (props) => {
    const { eventSpaceId } = props ?? {};

    return eventSpacesControllerDeleteEventSpace(eventSpaceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerDeleteEventSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>
>;

export type EventSpacesControllerDeleteEventSpaceMutationError = void;

/**
 * @summary Delete an event space
 */
export const useEventSpacesControllerDeleteEventSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>,
    TError,
    { eventSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEventSpace>>,
  TError,
  { eventSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerDeleteEventSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes an event for the authenticated user.
 * @summary Delete an event
 */
export const eventSpacesControllerDeleteEvent = (
  eventSpaceId: string,
  eventId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/events-spaces/${encodeURIComponent(String(eventSpaceId))}/events/${encodeURIComponent(String(eventId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getEventSpacesControllerDeleteEventMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>,
    TError,
    { eventSpaceId: string; eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>,
  TError,
  { eventSpaceId: string; eventId: string },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerDeleteEvent'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>,
    { eventSpaceId: string; eventId: string }
  > = (props) => {
    const { eventSpaceId, eventId } = props ?? {};

    return eventSpacesControllerDeleteEvent(
      eventSpaceId,
      eventId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerDeleteEventMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>
>;

export type EventSpacesControllerDeleteEventMutationError = void;

/**
 * @summary Delete an event
 */
export const useEventSpacesControllerDeleteEvent = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>,
    TError,
    { eventSpaceId: string; eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerDeleteEvent>>,
  TError,
  { eventSpaceId: string; eventId: string },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerDeleteEventMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Get RSVP for an event for the authenticated user.
 * @summary Get RSVP for an event
 */
export const eventSpacesControllerGetRsvp = (
  eventId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GetEventRsvpResponseDto>(
    {
      url: `/events-spaces/rsvp/${encodeURIComponent(String(eventId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerGetRsvpQueryKey = (eventId: string) => {
  return [`/events-spaces/rsvp/${eventId}`] as const;
};

export const getEventSpacesControllerGetRsvpQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEventSpacesControllerGetRsvpQueryKey(eventId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
  > = ({ signal }) =>
    eventSpacesControllerGetRsvp(eventId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!eventId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetRsvpQueryResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
>;
export type EventSpacesControllerGetRsvpQueryError = void;

export function useEventSpacesControllerGetRsvp<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetRsvp<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
          TError,
          Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useEventSpacesControllerGetRsvp<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get RSVP for an event
 */

export function useEventSpacesControllerGetRsvp<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getEventSpacesControllerGetRsvpQueryOptions(
    eventId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get RSVP for an event
 */
export const prefetchEventSpacesControllerGetRsvp = async <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  queryClient: QueryClient,
  eventId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getEventSpacesControllerGetRsvpQueryOptions(
    eventId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getEventSpacesControllerGetRsvpSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getEventSpacesControllerGetRsvpQueryKey(eventId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
  > = ({ signal }) =>
    eventSpacesControllerGetRsvp(eventId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type EventSpacesControllerGetRsvpSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>
>;
export type EventSpacesControllerGetRsvpSuspenseQueryError = void;

export function useEventSpacesControllerGetRsvpSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetRsvpSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useEventSpacesControllerGetRsvpSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get RSVP for an event
 */

export function useEventSpacesControllerGetRsvpSuspense<
  TData = Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
  TError = void,
>(
  eventId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof eventSpacesControllerGetRsvp>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getEventSpacesControllerGetRsvpSuspenseQueryOptions(
    eventId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Toggle RSVP to an event for the authenticated user.
 * @summary Toggle RSVP to an event
 */
export const eventSpacesControllerToggleRsvp = (
  eventId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/events-spaces/rsvp/${encodeURIComponent(String(eventId))}/toggle`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getEventSpacesControllerToggleRsvpMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>,
    TError,
    { eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>,
  TError,
  { eventId: string },
  TContext
> => {
  const mutationKey = ['eventSpacesControllerToggleRsvp'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>,
    { eventId: string }
  > = (props) => {
    const { eventId } = props ?? {};

    return eventSpacesControllerToggleRsvp(eventId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type EventSpacesControllerToggleRsvpMutationResult = NonNullable<
  Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>
>;

export type EventSpacesControllerToggleRsvpMutationError = void;

/**
 * @summary Toggle RSVP to an event
 */
export const useEventSpacesControllerToggleRsvp = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>,
    TError,
    { eventId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof eventSpacesControllerToggleRsvp>>,
  TError,
  { eventId: string },
  TContext
> => {
  const mutationOptions =
    getEventSpacesControllerToggleRsvpMutationOptions(options);

  return useMutation(mutationOptions);
};
