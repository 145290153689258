import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import {
  CreateAutomationActionDtoType,
  CreateAutomationDto,
  CreateAutomationDtoTrigger,
  useAutomationsControllerCreate,
  useAutomationsControllerFindOne,
  useAutomationsControllerUpdate,
  useBrandsControllerGetSpaceGroupsOfBrand,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { ChatBubbleEllipsisIcon } from '@/assets/icon/chat-bubble-ellipsis';
import { CheckIcon } from '@/assets/icon/check-alt';
import { MailIcon } from '@/assets/icon/mail';
import { PlusIcon } from '@/assets/icon/plusIcon';
import { TrashIcon } from '@/assets/icon/trash';
import { Loading } from '@/components/Loading';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Textarea } from '@/components/ui/textarea';

interface FindAutomationByIdResponse {
  id: string;
  name: string;
  trigger: CreateAutomationDtoTrigger;
  isActive: boolean;
  brandId: string;
  createdAt: string;
  updatedAt: string;
  totalRuns: number;
  actions: {
    id: string;
    automationId: string;
    type: CreateAutomationActionDtoType;
    subjectTemplate: string;
    messageTemplate: string;
    cometChatMessageText: string | null;
    customEmailContent: string | null;
  }[];
  spaceGroups: {
    automationId: string;
    spaceGroupId: string;
    spaceGroup: {
      id: string;
      brandId: string;
      name: string;
      pricingType: string;
      price: number;
      avatarUrl: string | null;
      isPrivate: boolean;
      creatorId: string;
      createdAt: string;
      updatedAt: string;
      setPermission: string;
      stripePriceId: string;
      stripeProductId: string;
      billingFrequency: string;
      paymentLinkUrl: string;
      creatorShare: number | null;
      teachlyShare: number | null;
      platformFee: number | null;
      allowDirectMessaging: boolean;
      messageFrequencyType: string;
      freeMessagesLimit: number;
      freeMessagesFrequency: string;
      allowCallMeetings: boolean;
      freeCallsLimit: number;
      freeCallsFrequency: string;
      freeCallDuration: number;
      freeCallDurationType: string;
    };
  }[];
}

const triggers = [
  {
    value: 'MEMBER_JOINED_SPACE_GROUP',
    label: 'Member joined space group',
  },
  // {
  //   value: 'MEMBER_RSVP_TO_EVENT',
  //   label: '',
  // }
];

const actionsMenu = [
  {
    category: 'Communication',
    options: [
      {
        title: 'Send an email',
        value: 'SEND_EMAIL',
        icon: <MailIcon className="h-6 w-6 stroke-black dark:stroke-white" />,
      },
      {
        title: 'Send a direct message',
        value: 'SEND_DIRECT_MESSAGE',
        icon: (
          <ChatBubbleEllipsisIcon className="h-6 w-6 stroke-black dark:stroke-white" />
        ),
      },
    ],
  },
];

const WorkflowCreationPage = () => {
  const navigate = useNavigate();
  const { brandId } = useParams();
  const [params] = useSearchParams();
  const spaceGroupsQuery = useBrandsControllerGetSpaceGroupsOfBrand(brandId!);
  const getAutomationById = useAutomationsControllerFindOne(params.get('id')!);
  const createAutomationMutation = useAutomationsControllerCreate();
  const updateAutomationMutation = useAutomationsControllerUpdate();

  const [automation, setAutomation] = useState<CreateAutomationDto>();
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    if (getAutomationById.data) {
      const parsedResponse = {
        ...(getAutomationById.data as FindAutomationByIdResponse),
        spaceGroupIds: (
          getAutomationById.data as FindAutomationByIdResponse
        ).spaceGroups.map((spaceGroup) => spaceGroup.spaceGroupId),
      };
      setAutomation(parsedResponse);
    }
  }, [getAutomationById.data]);

  if (spaceGroupsQuery.isPending || getAutomationById.isLoading) {
    return <Loading size={6} />;
  }

  if (spaceGroupsQuery.isError) {
    throw spaceGroupsQuery.error;
  }

  const spaceGroups = spaceGroupsQuery.data;

  const handleContinue = () => {
    const payload: CreateAutomationDto = {
      name: automation?.name!,
      trigger: automation?.trigger!,
      isActive: true,
      brandId: brandId!,
      spaceGroupIds: automation?.spaceGroupIds!,
      actions: automation?.actions!,
    };

    try {
      createAutomationMutation.mutateAsync(
        { data: payload },
        {
          onSuccess: () => {
            navigate(`/brands/${brandId}/workflow`);
          },
        },
      );
    } catch (error) {}
  };

  const handleEdit = async () => {
    try {
      updateAutomationMutation.mutateAsync(
        {
          data: automation!,
          id: (getAutomationById.data as FindAutomationByIdResponse).id,
        },
        {
          onSuccess: () => {
            navigate(`/brands/${brandId}/workflow`);
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex items-center justify-between border-b border-light px-10 py-4 dark:border-dark-light">
        <Button
          variant="ghost"
          className="flex w-fit gap-2 p-0 text-base font-semibold hover:bg-transparent"
          onClick={() => navigate(`/brands/${brandId}/workflow`)}
        >
          <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
          Back
        </Button>
        <Button
          className="rounded-lg px-6 py-2.5 text-base font-medium"
          disabled={
            automation?.actions?.some((action) => {
              if (action.type === 'SEND_EMAIL') {
                return !action.messageTemplate || !action.subjectTemplate;
              }
              if (action.type === 'SEND_DIRECT_MESSAGE') {
                return !action.messageTemplate;
              }
            }) ||
            !automation?.name ||
            createAutomationMutation.isPending ||
            !automation?.actions
          }
          onClick={() => {
            if (getAutomationById.data) {
              handleEdit();
            } else {
              handleContinue();
            }
          }}
        >
          {getAutomationById.data ? 'Update' : 'Publish'}
        </Button>
      </div>
      <div className="flex w-200 flex-col gap-10 self-center">
        <div className="rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
          <div className="flex flex-col gap-2.5">
            <InputField
              type="text"
              title="Automation name"
              placeholder="Enter a name"
              inputClassName="!border !border-light dark:!border-dark-light !bg-transparent "
              value={automation?.name}
              onChange={(e) =>
                setAutomation({
                  ...automation!,
                  name: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="flex flex-col gap-2.5 border-l border-light-2 dark:border-dark-2">
          <div className="relative flex pl-10">
            <div
              className={`absolute -left-4 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full ${step === 1 ? 'bg-green-100' : 'bg-light-2 dark:bg-dark-2'}`}
            >
              {step === 1 && (
                <CheckIcon className="h-4 w-4 flex-shrink-0 stroke-white" />
              )}
            </div>
            <div className="flex w-full flex-col gap-10 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
              <div className="flex flex-col gap-2.5">
                <div className="text-xs font-medium text-textParagraph dark:text-dark-textParagraph">
                  Trigger
                </div>
                <div className="text-base font-medium">
                  {triggers.find(
                    (trigger) => trigger.value === automation?.trigger,
                  )?.label ?? 'Select trigger'}
                </div>
              </div>
              {step === 0 && (
                <>
                  <div className="flex flex-col gap-2.5">
                    <div className="text-base font-medium">Select trigger</div>
                    <Select
                      onValueChange={(value: CreateAutomationDtoTrigger) =>
                        setAutomation({
                          ...automation!,
                          trigger: value,
                        })
                      }
                      value={automation?.trigger}
                    >
                      <SelectTrigger className="h-fit !border-light !bg-transparent p-4 text-base font-medium dark:!border-dark-light">
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent>
                        {triggers.map((trigger) => (
                          <SelectItem key={trigger.value} value={trigger.value}>
                            {trigger.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <div className="text-base font-medium">
                      Choose space group
                    </div>
                    <Select
                      onValueChange={(value: string) =>
                        setAutomation({
                          ...automation!,
                          spaceGroupIds: [value],
                        })
                      }
                      value={automation?.spaceGroupIds?.[0]}
                    >
                      <SelectTrigger className="h-fit !border-light !bg-transparent p-4 text-base font-medium dark:!border-dark-light">
                        <SelectValue placeholder="Select space" />
                      </SelectTrigger>
                      <SelectContent>
                        {spaceGroups.map((spaceGroup) => (
                          <SelectItem key={spaceGroup.id} value={spaceGroup.id}>
                            {spaceGroup.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <Button
                    className="w-fit self-end rounded-lg px-6 py-2.5 text-base font-medium"
                    onClick={() => {
                      setStep(1);
                      if (!getAutomationById.data)
                        setAutomation({
                          ...automation!,
                          actions: [
                            {
                              type: 'SEND_EMAIL',
                              messageTemplate: '',
                              subjectTemplate: '',
                            },
                          ],
                        });
                    }}
                    disabled={
                      !automation?.trigger || !automation?.spaceGroupIds
                    }
                  >
                    Continue
                  </Button>
                </>
              )}
            </div>
          </div>

          {step === 1 &&
            automation?.actions?.map((act, index) => (
              <>
                {step === 1 && (
                  <div className="flex flex-col items-center gap-[6px]">
                    <div className="h-10 w-0.5 border border-light-2 dark:border-dark-2" />
                    <div
                      className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-light-2 dark:bg-dark-2"
                      onClick={() => {
                        const newActions = [...automation.actions];
                        newActions.splice(index, 0, {
                          type: 'SEND_EMAIL',
                          messageTemplate: '',
                          subjectTemplate: '',
                        });
                        setAutomation({
                          ...automation,
                          actions: newActions,
                        });
                      }}
                    >
                      <PlusIcon className="h-5 w-5 fill-black dark:fill-white" />
                    </div>
                    <div className="h-10 w-0.5 border border-light-2 dark:border-dark-2" />
                  </div>
                )}
                <div
                  className="relative flex pl-10"
                  onClick={() => console.log(act)}
                >
                  <div className="flex w-full flex-col gap-16 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
                    <div className="flex justify-between">
                      <div className="flex flex-col gap-2.5">
                        <div className="text-xs font-medium text-textParagraph dark:text-dark-textParagraph">
                          Action
                        </div>
                        <div className="text-base font-medium">
                          {
                            actionsMenu
                              .find((action) =>
                                action.options.some(
                                  (option) => option.value === act.type,
                                ),
                              )
                              ?.options.find(
                                (option) => option.value === act.type,
                              )?.title
                          }
                        </div>
                      </div>
                      <div
                        className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center"
                        onClick={() => {
                          const newActions = [...automation.actions];
                          newActions.splice(index, 1);
                          setAutomation({
                            ...automation,
                            actions: newActions,
                          });
                        }}
                      >
                        <TrashIcon className="h-5 w-5 flex-shrink-0 stroke-black dark:stroke-white" />
                      </div>
                    </div>
                    <div className="flex flex-col gap-8">
                      {actionsMenu.map((action) => (
                        <div className="flex flex-col gap-4">
                          <div className="text-base font-medium">
                            {action.category}
                          </div>
                          <div className="grid grid-cols-5 gap-6">
                            {action.options.map((option) => (
                              <div
                                className="relative flex w-31 cursor-pointer flex-col gap-2.5 text-center text-xs font-medium"
                                onClick={() => {
                                  const newActions = [...automation.actions];
                                  newActions[index].type =
                                    option.value as CreateAutomationActionDtoType;
                                  setAutomation({
                                    ...automation,
                                    actions: newActions,
                                  });
                                }}
                              >
                                {act.type === option.value && (
                                  <div className="absolute -right-[7px] -top-[9px] flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full bg-brand">
                                    <CheckIcon className="h-3 w-3 flex-shrink-0 stroke-white" />
                                  </div>
                                )}
                                <div
                                  className={`flex h-[70px] flex-shrink-0 items-center justify-center rounded-lg border ${
                                    act.type === option.value
                                      ? 'border-brand'
                                      : 'border-light dark:border-dark-light'
                                  }`}
                                >
                                  {option.icon}
                                </div>
                                {option.title}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div className="flex flex-col gap-8">
                      <div className="text-base font-medium">
                        {
                          actionsMenu
                            .find((action) =>
                              action.options.some(
                                (option) => option.value === act.type,
                              ),
                            )
                            ?.options.find(
                              (option) => option.value === act.type,
                            )?.title
                        }
                      </div>
                      {act.type === 'SEND_DIRECT_MESSAGE' && (
                        <Textarea
                          onChange={(e) => {
                            const newActions = [...automation.actions];
                            newActions[index].messageTemplate = e.target.value;
                            setAutomation({
                              ...automation,
                              actions: newActions,
                            });
                          }}
                          className="!border !border-light !bg-transparent shadow-md dark:!border-dark-light"
                          value={act.messageTemplate}
                          rows={10}
                        />
                      )}
                      {act.type === 'SEND_EMAIL' && (
                        <>
                          <InputField
                            type="text"
                            title="Subject line"
                            placeholder="Write email title"
                            inputClassName="!border !border-light dark:!border-dark-light !bg-transparent"
                            value={act.subjectTemplate}
                            onChange={(e) => {
                              const newActions = [...automation.actions];
                              newActions[index].subjectTemplate =
                                e.target.value;
                              setAutomation({
                                ...automation,
                                actions: newActions,
                              });
                            }}
                          />
                          <Textarea
                            onChange={(e) => {
                              const newActions = [...automation.actions];
                              newActions[index].messageTemplate =
                                e.target.value;
                              setAutomation({
                                ...automation,
                                actions: newActions,
                              });
                            }}
                            className="!border !border-light !bg-transparent shadow-md dark:!border-dark-light"
                            value={act.messageTemplate}
                            rows={10}
                          />
                        </>
                      )}
                    </div>
                    {/* {index === automation.actions.length - 1 && (
                    <Button
                      className="self-end"
                      disabled={
                        automation.actions.some((action) => {
                          if (action.type === 'SEND_EMAIL') {
                            return (
                              !action.messageTemplate || !action.subjectTemplate
                            );
                          }
                          if (action.type === 'SEND_DIRECT_MESSAGE') {
                            return !action.messageTemplate;
                          }
                        }) ||
                        !automation.name ||
                        createAutomationMutation.isPending
                      }
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  )} */}
                  </div>
                </div>
              </>
            ))}

          {step === 1 && (
            <div className="flex flex-col items-center gap-[6px]">
              <div className="h-10 w-0.5 border border-light-2 dark:border-dark-2" />
              <div
                className="flex h-10 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-light-2 dark:bg-dark-2"
                onClick={() => {
                  const newActions = [...automation?.actions!];
                  newActions.push({
                    type: 'SEND_EMAIL',
                    messageTemplate: '',
                    subjectTemplate: '',
                  });
                  setAutomation({
                    ...automation!,
                    name: automation!.name || '',
                    actions: newActions,
                  });
                }}
              >
                <PlusIcon className="h-5 w-5 fill-black dark:fill-white" />
              </div>
              <div className="h-10 w-0.5 border border-light-2 dark:border-dark-2" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WorkflowCreationPage;
