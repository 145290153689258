// import { useEffect, useState } from 'react';
// import { BankIcon } from '@/assets/icon/bank';
// import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

import PayoutHistoryTab from './PayoutHistoryTab';
import TransactionHistoryTab from './TransationHistoryTab';

// Interfaces
// interface Account {
//   bank: string;
//   lastFour: string;
// }

// interface CardPayout {
//   title: string;
//   value: string;
// }

const PayoutPage = () => {
  // const [myAccount, setMyAccount] = useState<Account | undefined>();
  // const [cardsPayout, setCardsPayout] = useState<CardPayout[]>([]);
  // const [isLoading, setIsLoading] = useState<boolean>(true);
  // const [error, setError] = useState<string | null>(null);

  // Usamos el hook generado por Orval para las métricas de pagos
  //const { data, isLoading: isLoadingPayouts, error: payoutsError } = useStripeMetricsControllerGetPayoutsMetrics();

  // useEffect(() => {
  //   if (isLoadingPayouts) {
  //     setIsLoading(true);
  //     return;
  //   }

  //   if (payoutsError) {
  //     setError('Error al cargar los datos de pagos');
  //     setIsLoading(false);
  //     return;
  //   }

  //   if (data) {
  //     // Actualizamos el estado con los datos recibidos
  //     setCardsPayout(data.cardsPayout);

  //     // Si hay datos en el historial de pagos, podemos inferir la información de la cuenta
  //     if (data.payoutHistory && data.payoutHistory.length > 0) {
  //       setMyAccount(data.payoutHistory[0].account);
  //     }

  //     setIsLoading(false);
  //   }
  // }, [data, isLoadingPayouts, payoutsError]);

  // Mostramos un estado de carga si estamos esperando los datos
  // if (isLoading) {
  //   return (
  //     <div className="flex w-full items-center justify-center py-10">
  //       <p className="text-black dark:text-white">Cargando datos...</p>
  //     </div>
  //   );
  // }

  // Mostramos un mensaje de error si hubo un problema
  // if (error) {
  //   return (
  //     <div className="flex w-full items-center justify-center py-10">
  //       <p className="text-red-500">{error}</p>
  //     </div>
  //   );
  // }

  return (
    <div className="flex w-full flex-col gap-10 overflow-auto px-4 py-10 md:px-10">
      <div className="flex w-full flex-col gap-4 xl:flex-row">
        {/* {cardsPayout.map((card, index) => (
          <div
            className="flex w-full flex-col gap-6 rounded-2xl bg-light-2 px-4 py-6 dark:bg-dark-2"
            key={index}
          >
            <h2 className="text-lg font-bold leading-3 text-textParagraph dark:text-dark-textParagraph">
              {card.title}
            </h2>
            <div className="text-xl font-semibold leading-5 text-black dark:text-white">
              {card.value}
            </div>
          </div>
        ))} */}
      </div>
      {/* <div className="flex flex-col gap-8 rounded-2xl bg-light-2 px-4 py-6 dark:bg-dark-2">
        <h2 className="w-full text-base font-medium text-black dark:text-white">
          Payout Account
        </h2>
        <div className="flex gap-2.5">
          <div className="flex min-h-12 min-w-12 items-center justify-center rounded-lg bg-light-3 dark:bg-dark-3">
            <BankIcon className="max-h-6 min-h-6 min-w-6 max-w-6 stroke-light-gray dark:stroke-dark-light-gray" />
          </div>
          {myAccount ? (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-base font-medium text-black dark:text-white">
                {myAccount.bank}
              </h3>
              <p className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                ••••{myAccount.lastFour}
              </p>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-base font-medium text-black dark:text-white">
                Bank Account
              </h3>
              <p className="text-base font-medium text-textParagraph dark:text-dark-textParagraph">
                Bank account connected
              </p>
            </div>
          )}
          <Button
            className="h-fit w-32 flex-none p-4"
            hidden={!myAccount}
            disabled
          >
            Withdraw
          </Button>
        </div>
      </div> */}
      <Tabs defaultValue="payout" className="flex h-full w-full flex-col">
        <TabsList className="flex justify-start rounded-none border-b border-light !bg-transparent p-0 dark:border-dark-light">
          <TabsTrigger
            value="payout"
            className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
          >
            Payout history
          </TabsTrigger>
          <TabsTrigger
            value="transaction"
            className="rounded-none data-[state=active]:-mb-[1px] data-[state=active]:border-b-2 data-[state=active]:border-brand data-[state=active]:bg-transparent data-[state=active]:!text-brand"
          >
            Transaction history
          </TabsTrigger>
        </TabsList>
        <TabsContent value="payout" className="mt-4 overflow-auto">
          <PayoutHistoryTab />
        </TabsContent>
        <TabsContent value="transaction" className="mt-4 overflow-auto">
          <TransactionHistoryTab />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default PayoutPage;
