/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreatePriceVariantDto,
  PriceVariantResponseDto,
  UpdatePriceVariantDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get all price variants for a space group
 */
export const priceVariantControllerGetPriceVariants = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<PriceVariantResponseDto[]>(
    {
      url: `/price-variants/${encodeURIComponent(String(spaceGroupId))}/variants`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getPriceVariantControllerGetPriceVariantsQueryKey = (
  spaceGroupId: string,
) => {
  return [`/price-variants/${spaceGroupId}/variants`] as const;
};

export const getPriceVariantControllerGetPriceVariantsQueryOptions = <
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPriceVariantControllerGetPriceVariantsQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
  > = ({ signal }) =>
    priceVariantControllerGetPriceVariants(
      spaceGroupId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type PriceVariantControllerGetPriceVariantsQueryResult = NonNullable<
  Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
>;
export type PriceVariantControllerGetPriceVariantsQueryError = unknown;

export function usePriceVariantControllerGetPriceVariants<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
          TError,
          Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function usePriceVariantControllerGetPriceVariants<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
          TError,
          Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function usePriceVariantControllerGetPriceVariants<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all price variants for a space group
 */

export function usePriceVariantControllerGetPriceVariants<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getPriceVariantControllerGetPriceVariantsQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all price variants for a space group
 */
export const prefetchPriceVariantControllerGetPriceVariants = async <
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getPriceVariantControllerGetPriceVariantsQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getPriceVariantControllerGetPriceVariantsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getPriceVariantControllerGetPriceVariantsQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
  > = ({ signal }) =>
    priceVariantControllerGetPriceVariants(
      spaceGroupId,
      requestOptions,
      signal,
    );

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type PriceVariantControllerGetPriceVariantsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>
  >;
export type PriceVariantControllerGetPriceVariantsSuspenseQueryError = unknown;

export function usePriceVariantControllerGetPriceVariantsSuspense<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function usePriceVariantControllerGetPriceVariantsSuspense<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function usePriceVariantControllerGetPriceVariantsSuspense<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all price variants for a space group
 */

export function usePriceVariantControllerGetPriceVariantsSuspense<
  TData = Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof priceVariantControllerGetPriceVariants>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getPriceVariantControllerGetPriceVariantsSuspenseQueryOptions(
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Create a new price variant for a space group
 */
export const priceVariantControllerCreatePriceVariant = (
  spaceGroupId: string,
  createPriceVariantDto: CreatePriceVariantDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/price-variants/${encodeURIComponent(String(spaceGroupId))}/variants`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createPriceVariantDto,
      signal,
    },
    options,
  );
};

export const getPriceVariantControllerCreatePriceVariantMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>,
    TError,
    { spaceGroupId: string; data: CreatePriceVariantDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>,
  TError,
  { spaceGroupId: string; data: CreatePriceVariantDto },
  TContext
> => {
  const mutationKey = ['priceVariantControllerCreatePriceVariant'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>,
    { spaceGroupId: string; data: CreatePriceVariantDto }
  > = (props) => {
    const { spaceGroupId, data } = props ?? {};

    return priceVariantControllerCreatePriceVariant(
      spaceGroupId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PriceVariantControllerCreatePriceVariantMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>
  >;
export type PriceVariantControllerCreatePriceVariantMutationBody =
  CreatePriceVariantDto;
export type PriceVariantControllerCreatePriceVariantMutationError = unknown;

/**
 * @summary Create a new price variant for a space group
 */
export const usePriceVariantControllerCreatePriceVariant = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>,
    TError,
    { spaceGroupId: string; data: CreatePriceVariantDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof priceVariantControllerCreatePriceVariant>>,
  TError,
  { spaceGroupId: string; data: CreatePriceVariantDto },
  TContext
> => {
  const mutationOptions =
    getPriceVariantControllerCreatePriceVariantMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update a price variant
 */
export const priceVariantControllerUpdatePriceVariant = (
  spaceGroupId: string,
  variantId: string,
  updatePriceVariantDto: UpdatePriceVariantDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<void>(
    {
      url: `/price-variants/${encodeURIComponent(String(spaceGroupId))}/variants/${encodeURIComponent(String(variantId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updatePriceVariantDto,
    },
    options,
  );
};

export const getPriceVariantControllerUpdatePriceVariantMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>,
    TError,
    { spaceGroupId: string; variantId: string; data: UpdatePriceVariantDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>,
  TError,
  { spaceGroupId: string; variantId: string; data: UpdatePriceVariantDto },
  TContext
> => {
  const mutationKey = ['priceVariantControllerUpdatePriceVariant'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>,
    { spaceGroupId: string; variantId: string; data: UpdatePriceVariantDto }
  > = (props) => {
    const { spaceGroupId, variantId, data } = props ?? {};

    return priceVariantControllerUpdatePriceVariant(
      spaceGroupId,
      variantId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PriceVariantControllerUpdatePriceVariantMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>
  >;
export type PriceVariantControllerUpdatePriceVariantMutationBody =
  UpdatePriceVariantDto;
export type PriceVariantControllerUpdatePriceVariantMutationError = unknown;

/**
 * @summary Update a price variant
 */
export const usePriceVariantControllerUpdatePriceVariant = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>,
    TError,
    { spaceGroupId: string; variantId: string; data: UpdatePriceVariantDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof priceVariantControllerUpdatePriceVariant>>,
  TError,
  { spaceGroupId: string; variantId: string; data: UpdatePriceVariantDto },
  TContext
> => {
  const mutationOptions =
    getPriceVariantControllerUpdatePriceVariantMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Delete a price variant from a space group
 */
export const priceVariantControllerDeletePriceVariant = (
  spaceGroupId: string,
  variantId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<void>(
    {
      url: `/price-variants/${encodeURIComponent(String(spaceGroupId))}/variants/${encodeURIComponent(String(variantId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getPriceVariantControllerDeletePriceVariantMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>,
    TError,
    { spaceGroupId: string; variantId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>,
  TError,
  { spaceGroupId: string; variantId: string },
  TContext
> => {
  const mutationKey = ['priceVariantControllerDeletePriceVariant'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>,
    { spaceGroupId: string; variantId: string }
  > = (props) => {
    const { spaceGroupId, variantId } = props ?? {};

    return priceVariantControllerDeletePriceVariant(
      spaceGroupId,
      variantId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type PriceVariantControllerDeletePriceVariantMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>
  >;

export type PriceVariantControllerDeletePriceVariantMutationError = unknown;

/**
 * @summary Delete a price variant from a space group
 */
export const usePriceVariantControllerDeletePriceVariant = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>,
    TError,
    { spaceGroupId: string; variantId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof priceVariantControllerDeletePriceVariant>>,
  TError,
  { spaceGroupId: string; variantId: string },
  TContext
> => {
  const mutationOptions =
    getPriceVariantControllerDeletePriceVariantMutationOptions(options);

  return useMutation(mutationOptions);
};
