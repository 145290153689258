import {
  CreatePriceVariantDto,
  UpdatePriceVariantDto,
  UpdatePriceVariantDtoBillingType,
  UpdatePriceVariantDtoRecurringInterval,
} from '@/api';
import type {} from '@/api/generated/api/price-variants/price-variants';

import { FormSchema } from './CreatePricingVariant.types';

/**
 * Mantiene la función original para CREAR
 */
export const parseFormResponseToCreatePriceVariantDto = (
  spaceGroupId: string,
  formValues: FormSchema,
): { spaceGroupId: string; data: CreatePriceVariantDto } => {
  const {
    variantName,
    billingFrequency,
    oneTimePrice,
    recurringPrice,
    subscriptionPeriod,
    revoqueAccessAfter,
    enableSplitPayments,
    paymentFrequency,
    splitPayments,
  } = formValues;

  // Create a base DTO with the common fields
  const dto: CreatePriceVariantDto = {
    name: variantName,
    billingType:
      billingFrequency === 'ONE TIME' ? 'ONE_TIME_PAYMENT' : 'RECURRING',
    price: 0,
  };

  // Add fields specific to ONE_TIME_PAYMENT
  if (billingFrequency === 'ONE TIME') {
    dto.price = Number(oneTimePrice) || 0;
    dto.revokeAccessPeriod = parseRevokeAccessPeriod(revoqueAccessAfter);
    // Solo configura enableSplitPlay y recurringInterval si split payments está activado
    if (enableSplitPayments) {
      dto.numberOfSplitPayments = splitPayments;
      // Convertir monthly/weekly a MONTH/WEEK como espera el backend
      dto.splitPaymentPeriod = paymentFrequency;
    } else {
      dto.numberOfSplitPayments = 0;
    }
  }
  // Add fields specific to RECURRING
  else if (billingFrequency === 'RECURRING') {
    dto.price = Number(recurringPrice) || 0;

    if (subscriptionPeriod) {
      const { interval, count } =
        parseRecurringIntervalAndCount(subscriptionPeriod);
      dto.recurringInterval = interval;
      dto.intervalCount = count;
      dto.subscriptionPeriod = parseSubscriptionPeriod(subscriptionPeriod);
    }
  }

  return {
    spaceGroupId,
    data: dto,
  };
};

/**
 * NUEVA FUNCIÓN para EDITAR. Convierte los valores del formulario a `UpdatePriceVariantDto`.
 */
export const parseFormResponseToUpdatePriceVariantDto = (
  formValues: FormSchema,
): UpdatePriceVariantDto => {
  const {
    variantName,
    billingFrequency,
    oneTimePrice,
    recurringPrice,
    subscriptionPeriod,
    revoqueAccessAfter,
    splitPayments,
    paymentFrequency,
  } = formValues;

  // Create a base DTO with the common fields
  const dto: UpdatePriceVariantDto = {
    name: variantName,
    billingType:
      billingFrequency === 'ONE TIME'
        ? ('ONE_TIME_PAYMENT' as UpdatePriceVariantDtoBillingType)
        : ('RECURRING' as UpdatePriceVariantDtoBillingType),
  };

  // Add fields specific to ONE_TIME_PAYMENT
  if (billingFrequency === 'ONE TIME') {
    dto.price = Number(oneTimePrice) || 0;

    dto.revokeAccessPeriod = parseRevokeAccessPeriod(revoqueAccessAfter);
    if (splitPayments) {
      dto.numberOfSplitPayments = splitPayments;
      // Convertir monthly/weekly a MONTH/WEEK como espera el backend
      dto.splitPaymentPeriod = paymentFrequency;
    } else {
      dto.numberOfSplitPayments = 0;
    }
  }
  // Add fields specific to RECURRING
  else if (billingFrequency === 'RECURRING') {
    dto.price = Number(recurringPrice) || 0;

    if (subscriptionPeriod) {
      const { interval, count } =
        parseRecurringIntervalAndCount(subscriptionPeriod);
      dto.recurringInterval =
        interval as UpdatePriceVariantDtoRecurringInterval;
      dto.intervalCount = count;
      dto.subscriptionPeriod = subscriptionPeriod;
    }
  }

  return dto;
};

/**
 * Función de ayuda para determinar el precio correcto
 */
// function getPrice(
//   billingFrequency: FormSchema['billingFrequency'],
//   oneTimePrice: FormSchema['oneTimePrice'],
//   recurringPrice: FormSchema['recurringPrice'],
// ) {
//   if (billingFrequency === 'ONE TIME') {
//     return Number(oneTimePrice) || 0;
//   }
//   return Number(recurringPrice) || 0;
// }

/**
 * Determina el intervalo y la cuenta de intervalos
 */
function parseRecurringIntervalAndCount(
  uiSubscriptionPeriod: FormSchema['subscriptionPeriod'],
): { interval: 'WEEK' | 'MONTH' | 'YEAR'; count: number } {
  switch (uiSubscriptionPeriod) {
    case 'DAY_7':
      return { interval: 'WEEK', count: 1 };
    case 'MONTH_1':
      return { interval: 'MONTH', count: 1 };
    case 'MONTH_3':
      return { interval: 'MONTH', count: 3 };
    case 'MONTH_6':
      return { interval: 'MONTH', count: 6 };
    case 'YEAR_1':
      return { interval: 'YEAR', count: 1 };
    default:
      return { interval: 'MONTH', count: 1 };
  }
}

/**
 * Determina la suscripción (para create). Para Update no la usamos,
 * pero permanece sin cambios para no romper nada.
 */
function parseSubscriptionPeriod(
  uiSubscriptionPeriod: FormSchema['subscriptionPeriod'],
): CreatePriceVariantDto['subscriptionPeriod'] {
  switch (uiSubscriptionPeriod) {
    case 'DAY_7':
      return 'DAY_7';
    case 'MONTH_1':
      return 'MONTH_1';
    case 'MONTH_3':
      return 'MONTH_3';
    case 'MONTH_6':
      return 'MONTH_6';
    case 'YEAR_1':
      return 'YEAR_1';
    default:
      return undefined;
  }
}

/**
 * Determina el periodo de revocación del acceso
 */
function parseRevokeAccessPeriod(
  revoqueAccessAfter: FormSchema['revoqueAccessAfter'],
): CreatePriceVariantDto['revokeAccessPeriod'] {
  switch (revoqueAccessAfter) {
    case 'NEVER':
      return 'NEVER';
    case 'AFTER SEVEN DAYS':
      return 'DAY_7';
    case 'AFTER ONE MONTH':
      return 'MONTH_1';
    case 'AFTER THREE MONTHS':
      return 'MONTH_3';
    case 'AFTER SIX MONTHS':
      return 'MONTH_6';
    case 'AFTER ONE YEAR':
      return 'YEAR_1';
    case 'AFTER OTHER # OF DAYS':
      return 'CUSTOM';
    default:
      return undefined;
  }
}
