/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  BadRequestException,
  CreateCoursePaymentIntentDto,
  CreateCoursePaymentIntentResponseDto,
  CreateSubscriptionDto,
  CreateSubscriptionResponseDto,
  InternalServerErrorException,
  NotFoundException,
  ProcessPurchaseDto,
  ProcessPurchaseResponseDto,
  StripeConnectResponseDto,
  StripeControllerCancelSubscription200,
  StripeControllerCancelSubscriptionBody,
  StripeControllerProcessCheckout200,
  StripeControllerProcessCheckoutBody,
  StripeGetConnectedAccountResponseDto,
  UnauthorizedException,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Connect Stripe Account
 */
export const stripeControllerCreateStripeConnectAccount = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeConnectResponseDto>(
    { url: `/stripe/create-connect-account`, method: 'POST', signal },
    options,
  );
};

export const getStripeControllerCreateStripeConnectAccountMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['stripeControllerCreateStripeConnectAccount'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>,
    void
  > = () => {
    return stripeControllerCreateStripeConnectAccount(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerCreateStripeConnectAccountMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>
  >;

export type StripeControllerCreateStripeConnectAccountMutationError = void;

/**
 * @summary Connect Stripe Account
 */
export const useStripeControllerCreateStripeConnectAccount = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerCreateStripeConnectAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getStripeControllerCreateStripeConnectAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get Connected Stripe Account
 */
export const stripeControllerGetStripeConnectAccount = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeGetConnectedAccountResponseDto>(
    { url: `/stripe/connect-account`, method: 'GET', signal },
    options,
  );
};

export const getStripeControllerGetStripeConnectAccountQueryKey = () => {
  return [`/stripe/connect-account`] as const;
};

export const getStripeControllerGetStripeConnectAccountQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeControllerGetStripeConnectAccountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
  > = ({ signal }) =>
    stripeControllerGetStripeConnectAccount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeControllerGetStripeConnectAccountQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
>;
export type StripeControllerGetStripeConnectAccountQueryError = unknown;

export function useStripeControllerGetStripeConnectAccount<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
        TError,
        Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetStripeConnectAccount<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
        TError,
        Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeControllerGetStripeConnectAccount<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get Connected Stripe Account
 */

export function useStripeControllerGetStripeConnectAccount<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getStripeControllerGetStripeConnectAccountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get Connected Stripe Account
 */
export const prefetchStripeControllerGetStripeConnectAccount = async <
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getStripeControllerGetStripeConnectAccountQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeControllerGetStripeConnectAccountSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeControllerGetStripeConnectAccountQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
  > = ({ signal }) =>
    stripeControllerGetStripeConnectAccount(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeControllerGetStripeConnectAccountSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>
  >;
export type StripeControllerGetStripeConnectAccountSuspenseQueryError = unknown;

export function useStripeControllerGetStripeConnectAccountSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetStripeConnectAccountSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetStripeConnectAccountSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get Connected Stripe Account
 */

export function useStripeControllerGetStripeConnectAccountSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof stripeControllerGetStripeConnectAccount>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getStripeControllerGetStripeConnectAccountSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const stripeControllerUpdateStripeConnectAccount = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/stripe/update-connect-account`, method: 'POST', signal },
    options,
  );
};

export const getStripeControllerUpdateStripeConnectAccountMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['stripeControllerUpdateStripeConnectAccount'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>,
    void
  > = () => {
    return stripeControllerUpdateStripeConnectAccount(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerUpdateStripeConnectAccountMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>
  >;

export type StripeControllerUpdateStripeConnectAccountMutationError = unknown;

export const useStripeControllerUpdateStripeConnectAccount = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerUpdateStripeConnectAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getStripeControllerUpdateStripeConnectAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Process a purchase for a Space Group
 */
export const stripeControllerProcessPurchase = (
  processPurchaseDto: ProcessPurchaseDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<ProcessPurchaseResponseDto>(
    {
      url: `/stripe/process-purchase`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: processPurchaseDto,
      signal,
    },
    options,
  );
};

export const getStripeControllerProcessPurchaseMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerProcessPurchase>>,
    TError,
    { data: ProcessPurchaseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerProcessPurchase>>,
  TError,
  { data: ProcessPurchaseDto },
  TContext
> => {
  const mutationKey = ['stripeControllerProcessPurchase'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerProcessPurchase>>,
    { data: ProcessPurchaseDto }
  > = (props) => {
    const { data } = props ?? {};

    return stripeControllerProcessPurchase(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerProcessPurchaseMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerProcessPurchase>>
>;
export type StripeControllerProcessPurchaseMutationBody = ProcessPurchaseDto;
export type StripeControllerProcessPurchaseMutationError = void;

/**
 * @summary Process a purchase for a Space Group
 */
export const useStripeControllerProcessPurchase = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerProcessPurchase>>,
    TError,
    { data: ProcessPurchaseDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerProcessPurchase>>,
  TError,
  { data: ProcessPurchaseDto },
  TContext
> => {
  const mutationOptions =
    getStripeControllerProcessPurchaseMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stripeControllerGetPaymentLink = (
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/stripe/${encodeURIComponent(String(spaceGroupId))}/payment-link`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getStripeControllerGetPaymentLinkQueryKey = (
  spaceGroupId: string,
) => {
  return [`/stripe/${spaceGroupId}/payment-link`] as const;
};

export const getStripeControllerGetPaymentLinkQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeControllerGetPaymentLinkQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
  > = ({ signal }) =>
    stripeControllerGetPaymentLink(spaceGroupId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!spaceGroupId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeControllerGetPaymentLinkQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
>;
export type StripeControllerGetPaymentLinkQueryError = unknown;

export function useStripeControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
          TError,
          Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
          TError,
          Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useStripeControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useStripeControllerGetPaymentLink<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getStripeControllerGetPaymentLinkQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchStripeControllerGetPaymentLink = async <
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getStripeControllerGetPaymentLinkQueryOptions(
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getStripeControllerGetPaymentLinkSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getStripeControllerGetPaymentLinkQueryKey(spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
  > = ({ signal }) =>
    stripeControllerGetPaymentLink(spaceGroupId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type StripeControllerGetPaymentLinkSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>
>;
export type StripeControllerGetPaymentLinkSuspenseQueryError = unknown;

export function useStripeControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useStripeControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};

export function useStripeControllerGetPaymentLinkSuspense<
  TData = Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
  TError = unknown,
>(
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof stripeControllerGetPaymentLink>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getStripeControllerGetPaymentLinkSuspenseQueryOptions(
    spaceGroupId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Process a checkout for a Space Group
 */
export const stripeControllerProcessCheckout = (
  stripeControllerProcessCheckoutBody: StripeControllerProcessCheckoutBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeControllerProcessCheckout200>(
    {
      url: `/stripe/process-checkout`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: stripeControllerProcessCheckoutBody,
      signal,
    },
    options,
  );
};

export const getStripeControllerProcessCheckoutMutationOptions = <
  TError =
    | BadRequestException
    | UnauthorizedException
    | NotFoundException
    | InternalServerErrorException,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerProcessCheckout>>,
    TError,
    { data: StripeControllerProcessCheckoutBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerProcessCheckout>>,
  TError,
  { data: StripeControllerProcessCheckoutBody },
  TContext
> => {
  const mutationKey = ['stripeControllerProcessCheckout'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerProcessCheckout>>,
    { data: StripeControllerProcessCheckoutBody }
  > = (props) => {
    const { data } = props ?? {};

    return stripeControllerProcessCheckout(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerProcessCheckoutMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerProcessCheckout>>
>;
export type StripeControllerProcessCheckoutMutationBody =
  StripeControllerProcessCheckoutBody;
export type StripeControllerProcessCheckoutMutationError =
  | BadRequestException
  | UnauthorizedException
  | NotFoundException
  | InternalServerErrorException;

/**
 * @summary Process a checkout for a Space Group
 */
export const useStripeControllerProcessCheckout = <
  TError =
    | BadRequestException
    | UnauthorizedException
    | NotFoundException
    | InternalServerErrorException,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerProcessCheckout>>,
    TError,
    { data: StripeControllerProcessCheckoutBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerProcessCheckout>>,
  TError,
  { data: StripeControllerProcessCheckoutBody },
  TContext
> => {
  const mutationOptions =
    getStripeControllerProcessCheckoutMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Cancelar la suscripción de un usuario a un Space Group
 */
export const stripeControllerCancelSubscription = (
  stripeControllerCancelSubscriptionBody: StripeControllerCancelSubscriptionBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<StripeControllerCancelSubscription200>(
    {
      url: `/stripe/cancel-subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: stripeControllerCancelSubscriptionBody,
      signal,
    },
    options,
  );
};

export const getStripeControllerCancelSubscriptionMutationOptions = <
  TError =
    | BadRequestException
    | UnauthorizedException
    | NotFoundException
    | InternalServerErrorException,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCancelSubscription>>,
    TError,
    { data: StripeControllerCancelSubscriptionBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerCancelSubscription>>,
  TError,
  { data: StripeControllerCancelSubscriptionBody },
  TContext
> => {
  const mutationKey = ['stripeControllerCancelSubscription'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerCancelSubscription>>,
    { data: StripeControllerCancelSubscriptionBody }
  > = (props) => {
    const { data } = props ?? {};

    return stripeControllerCancelSubscription(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerCancelSubscriptionMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerCancelSubscription>>
>;
export type StripeControllerCancelSubscriptionMutationBody =
  StripeControllerCancelSubscriptionBody;
export type StripeControllerCancelSubscriptionMutationError =
  | BadRequestException
  | UnauthorizedException
  | NotFoundException
  | InternalServerErrorException;

/**
 * @summary Cancelar la suscripción de un usuario a un Space Group
 */
export const useStripeControllerCancelSubscription = <
  TError =
    | BadRequestException
    | UnauthorizedException
    | NotFoundException
    | InternalServerErrorException,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCancelSubscription>>,
    TError,
    { data: StripeControllerCancelSubscriptionBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerCancelSubscription>>,
  TError,
  { data: StripeControllerCancelSubscriptionBody },
  TContext
> => {
  const mutationOptions =
    getStripeControllerCancelSubscriptionMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stripeControllerHandleStripeWebhook = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/stripe/webhook`, method: 'POST', signal },
    options,
  );
};

export const getStripeControllerHandleStripeWebhookMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['stripeControllerHandleStripeWebhook'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>,
    void
  > = () => {
    return stripeControllerHandleStripeWebhook(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerHandleStripeWebhookMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>
>;

export type StripeControllerHandleStripeWebhookMutationError = unknown;

export const useStripeControllerHandleStripeWebhook = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerHandleStripeWebhook>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getStripeControllerHandleStripeWebhookMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create a new payment and return client secret
 */
export const stripeControllerCreatePayment = (
  createSubscriptionDto: CreateSubscriptionDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CreateSubscriptionResponseDto>(
    {
      url: `/stripe/create-subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createSubscriptionDto,
      signal,
    },
    options,
  );
};

export const getStripeControllerCreatePaymentMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreatePayment>>,
    TError,
    { data: CreateSubscriptionDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerCreatePayment>>,
  TError,
  { data: CreateSubscriptionDto },
  TContext
> => {
  const mutationKey = ['stripeControllerCreatePayment'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerCreatePayment>>,
    { data: CreateSubscriptionDto }
  > = (props) => {
    const { data } = props ?? {};

    return stripeControllerCreatePayment(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerCreatePaymentMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerCreatePayment>>
>;
export type StripeControllerCreatePaymentMutationBody = CreateSubscriptionDto;
export type StripeControllerCreatePaymentMutationError = void;

/**
 * @summary Create a new payment and return client secret
 */
export const useStripeControllerCreatePayment = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreatePayment>>,
    TError,
    { data: CreateSubscriptionDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerCreatePayment>>,
  TError,
  { data: CreateSubscriptionDto },
  TContext
> => {
  const mutationOptions =
    getStripeControllerCreatePaymentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const stripeControllerDisconnectStripeAccount = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/stripe/disconnect-stripe-account`, method: 'POST', signal },
    options,
  );
};

export const getStripeControllerDisconnectStripeAccountMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationKey = ['stripeControllerDisconnectStripeAccount'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>,
    void
  > = () => {
    return stripeControllerDisconnectStripeAccount(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerDisconnectStripeAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>
>;

export type StripeControllerDisconnectStripeAccountMutationError = unknown;

export const useStripeControllerDisconnectStripeAccount = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>,
    TError,
    void,
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerDisconnectStripeAccount>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    getStripeControllerDisconnectStripeAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Crea un Payment Intent en Stripe para procesar el pago de un curso
 * @summary Crear un Payment Intent para la compra de un curso
 */
export const stripeControllerCreateCoursePaymentIntent = (
  createCoursePaymentIntentDto: CreateCoursePaymentIntentDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CreateCoursePaymentIntentResponseDto>(
    {
      url: `/stripe/create-course-payment-intent`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createCoursePaymentIntentDto,
      signal,
    },
    options,
  );
};

export const getStripeControllerCreateCoursePaymentIntentMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>,
    TError,
    { data: CreateCoursePaymentIntentDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>,
  TError,
  { data: CreateCoursePaymentIntentDto },
  TContext
> => {
  const mutationKey = ['stripeControllerCreateCoursePaymentIntent'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>,
    { data: CreateCoursePaymentIntentDto }
  > = (props) => {
    const { data } = props ?? {};

    return stripeControllerCreateCoursePaymentIntent(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type StripeControllerCreateCoursePaymentIntentMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>
  >;
export type StripeControllerCreateCoursePaymentIntentMutationBody =
  CreateCoursePaymentIntentDto;
export type StripeControllerCreateCoursePaymentIntentMutationError = void;

/**
 * @summary Crear un Payment Intent para la compra de un curso
 */
export const useStripeControllerCreateCoursePaymentIntent = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>,
    TError,
    { data: CreateCoursePaymentIntentDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof stripeControllerCreateCoursePaymentIntent>>,
  TError,
  { data: CreateCoursePaymentIntentDto },
  TContext
> => {
  const mutationOptions =
    getStripeControllerCreateCoursePaymentIntentMutationOptions(options);

  return useMutation(mutationOptions);
};
