/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  Brand,
  BrandMembersDto,
  BrandUserRoleDto,
  BrandsControllerAddUserToBrandBody,
  BrandsControllerRemoveUserFromBrand200,
  BrandsControllerUpdateUserRoleInBrandBody,
  CreateBrandDto,
  DeleteBrandResponse,
  GetAllUsersByBrandIdResponseDto,
  SpaceGroupWithSpaces,
  UpdateBrandDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const brandsControllerGetError = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    { url: `/brands/error`, method: 'GET', signal },
    options,
  );
};

export const getBrandsControllerGetErrorQueryKey = () => {
  return [`/brands/error`] as const;
};

export const getBrandsControllerGetErrorQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetErrorQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetError>>
  > = ({ signal }) => brandsControllerGetError(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetError>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetErrorQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetError>>
>;
export type BrandsControllerGetErrorQueryError = void;

export function useBrandsControllerGetError<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof brandsControllerGetError>>,
        TError,
        Awaited<ReturnType<typeof brandsControllerGetError>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetError<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof brandsControllerGetError>>,
        TError,
        Awaited<ReturnType<typeof brandsControllerGetError>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetError<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useBrandsControllerGetError<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetErrorQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchBrandsControllerGetError = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetError>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetErrorQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetErrorSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetErrorQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetError>>
  > = ({ signal }) => brandsControllerGetError(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetError>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetErrorSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetError>>
>;
export type BrandsControllerGetErrorSuspenseQueryError = void;

export function useBrandsControllerGetErrorSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetErrorSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetErrorSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};

export function useBrandsControllerGetErrorSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetError>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetError>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getBrandsControllerGetErrorSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Creates a new brand for the authenticated user. Only users with the CREATOR role can perform this action.
 * @summary Create a new brand
 */
export const brandsControllerCreateBrand = (
  createBrandDto: CreateBrandDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<Brand>(
    {
      url: `/brands`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createBrandDto,
      signal,
    },
    options,
  );
};

export const getBrandsControllerCreateBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerCreateBrand>>,
    TError,
    { data: CreateBrandDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerCreateBrand>>,
  TError,
  { data: CreateBrandDto },
  TContext
> => {
  const mutationKey = ['brandsControllerCreateBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerCreateBrand>>,
    { data: CreateBrandDto }
  > = (props) => {
    const { data } = props ?? {};

    return brandsControllerCreateBrand(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerCreateBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerCreateBrand>>
>;
export type BrandsControllerCreateBrandMutationBody = CreateBrandDto;
export type BrandsControllerCreateBrandMutationError = void;

/**
 * @summary Create a new brand
 */
export const useBrandsControllerCreateBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerCreateBrand>>,
    TError,
    { data: CreateBrandDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerCreateBrand>>,
  TError,
  { data: CreateBrandDto },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerCreateBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves a list of all brands created by or accessible to the authenticated user.
 * @summary Get all brands for the authenticated user
 */
export const brandsControllerGetAllBrands = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<Brand[]>(
    { url: `/brands`, method: 'GET', signal },
    options,
  );
};

export const getBrandsControllerGetAllBrandsQueryKey = () => {
  return [`/brands`] as const;
};

export const getBrandsControllerGetAllBrandsQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetAllBrandsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
  > = ({ signal }) => brandsControllerGetAllBrands(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetAllBrandsQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
>;
export type BrandsControllerGetAllBrandsQueryError = void;

export function useBrandsControllerGetAllBrands<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
        TError,
        Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllBrands<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
        TError,
        Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetAllBrands<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all brands for the authenticated user
 */

export function useBrandsControllerGetAllBrands<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetAllBrandsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all brands for the authenticated user
 */
export const prefetchBrandsControllerGetAllBrands = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetAllBrandsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetAllBrandsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetAllBrandsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
  > = ({ signal }) => brandsControllerGetAllBrands(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetAllBrandsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetAllBrands>>
>;
export type BrandsControllerGetAllBrandsSuspenseQueryError = void;

export function useBrandsControllerGetAllBrandsSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllBrandsSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllBrandsSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all brands for the authenticated user
 */

export function useBrandsControllerGetAllBrandsSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof brandsControllerGetAllBrands>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getBrandsControllerGetAllBrandsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Adds a user to a brand with a specified role. Only users with the CREATOR role can perform this action.
 * @summary Add a user to a brand
 */
export const brandsControllerAddUserToBrand = (
  brandId: string,
  brandsControllerAddUserToBrandBody: BrandsControllerAddUserToBrandBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/users`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: brandsControllerAddUserToBrandBody,
      signal,
    },
    options,
  );
};

export const getBrandsControllerAddUserToBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>,
    TError,
    { brandId: string; data: BrandsControllerAddUserToBrandBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>,
  TError,
  { brandId: string; data: BrandsControllerAddUserToBrandBody },
  TContext
> => {
  const mutationKey = ['brandsControllerAddUserToBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>,
    { brandId: string; data: BrandsControllerAddUserToBrandBody }
  > = (props) => {
    const { brandId, data } = props ?? {};

    return brandsControllerAddUserToBrand(brandId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerAddUserToBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>
>;
export type BrandsControllerAddUserToBrandMutationBody =
  BrandsControllerAddUserToBrandBody;
export type BrandsControllerAddUserToBrandMutationError = void;

/**
 * @summary Add a user to a brand
 */
export const useBrandsControllerAddUserToBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>,
    TError,
    { brandId: string; data: BrandsControllerAddUserToBrandBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerAddUserToBrand>>,
  TError,
  { brandId: string; data: BrandsControllerAddUserToBrandBody },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerAddUserToBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get all users by brand ID
 */
export const brandsControllerGetAllUsersByBrandId = (
  brandId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GetAllUsersByBrandIdResponseDto>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/users`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getBrandsControllerGetAllUsersByBrandIdQueryKey = (
  brandId: string,
) => {
  return [`/brands/${brandId}/users`] as const;
};

export const getBrandsControllerGetAllUsersByBrandIdQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetAllUsersByBrandIdQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>
  > = ({ signal }) =>
    brandsControllerGetAllUsersByBrandId(brandId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetAllUsersByBrandIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>
>;
export type BrandsControllerGetAllUsersByBrandIdQueryError = void;

export function useBrandsControllerGetAllUsersByBrandId<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllUsersByBrandId<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetAllUsersByBrandId<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all users by brand ID
 */

export function useBrandsControllerGetAllUsersByBrandId<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetAllUsersByBrandIdQueryOptions(
    brandId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all users by brand ID
 */
export const prefetchBrandsControllerGetAllUsersByBrandId = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetAllUsersByBrandIdQueryOptions(
    brandId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetAllUsersByBrandIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetAllUsersByBrandIdQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>
  > = ({ signal }) =>
    brandsControllerGetAllUsersByBrandId(brandId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetAllUsersByBrandIdSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>>;
export type BrandsControllerGetAllUsersByBrandIdSuspenseQueryError = void;

export function useBrandsControllerGetAllUsersByBrandIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllUsersByBrandIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetAllUsersByBrandIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all users by brand ID
 */

export function useBrandsControllerGetAllUsersByBrandIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetAllUsersByBrandId>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getBrandsControllerGetAllUsersByBrandIdSuspenseQueryOptions(
      brandId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the membership details of a specific user within a brand, including role, joinedAt, leftAt, and more.
 * @summary Get brand member details
 */
export const brandsControllerGetBrandMember = (
  brandId: string,
  userId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<BrandMembersDto>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/users/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getBrandsControllerGetBrandMemberQueryKey = (
  brandId: string,
  userId: string,
) => {
  return [`/brands/${brandId}/users/${userId}`] as const;
};

export const getBrandsControllerGetBrandMemberQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetBrandMemberQueryKey(brandId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
  > = ({ signal }) =>
    brandsControllerGetBrandMember(brandId, userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(brandId && userId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetBrandMemberQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
>;
export type BrandsControllerGetBrandMemberQueryError = void;

export function useBrandsControllerGetBrandMember<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandMember<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetBrandMember<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get brand member details
 */

export function useBrandsControllerGetBrandMember<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetBrandMemberQueryOptions(
    brandId,
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get brand member details
 */
export const prefetchBrandsControllerGetBrandMember = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetBrandMemberQueryOptions(
    brandId,
    userId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetBrandMemberSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetBrandMemberQueryKey(brandId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
  > = ({ signal }) =>
    brandsControllerGetBrandMember(brandId, userId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetBrandMemberSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetBrandMember>>
>;
export type BrandsControllerGetBrandMemberSuspenseQueryError = void;

export function useBrandsControllerGetBrandMemberSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandMemberSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandMemberSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get brand member details
 */

export function useBrandsControllerGetBrandMemberSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandMember>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getBrandsControllerGetBrandMemberSuspenseQueryOptions(
    brandId,
    userId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a user's role in a brand. Only users with the CREATOR role can perform this action.
 * @summary Update a user's role in a brand
 */
export const brandsControllerUpdateUserRoleInBrand = (
  brandId: string,
  userId: string,
  brandsControllerUpdateUserRoleInBrandBody: BrandsControllerUpdateUserRoleInBrandBody,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<void>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/users/${encodeURIComponent(String(userId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: brandsControllerUpdateUserRoleInBrandBody,
    },
    options,
  );
};

export const getBrandsControllerUpdateUserRoleInBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>,
    TError,
    {
      brandId: string;
      userId: string;
      data: BrandsControllerUpdateUserRoleInBrandBody;
    },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>,
  TError,
  {
    brandId: string;
    userId: string;
    data: BrandsControllerUpdateUserRoleInBrandBody;
  },
  TContext
> => {
  const mutationKey = ['brandsControllerUpdateUserRoleInBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>,
    {
      brandId: string;
      userId: string;
      data: BrandsControllerUpdateUserRoleInBrandBody;
    }
  > = (props) => {
    const { brandId, userId, data } = props ?? {};

    return brandsControllerUpdateUserRoleInBrand(
      brandId,
      userId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerUpdateUserRoleInBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>
>;
export type BrandsControllerUpdateUserRoleInBrandMutationBody =
  BrandsControllerUpdateUserRoleInBrandBody;
export type BrandsControllerUpdateUserRoleInBrandMutationError = void;

/**
 * @summary Update a user's role in a brand
 */
export const useBrandsControllerUpdateUserRoleInBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>,
    TError,
    {
      brandId: string;
      userId: string;
      data: BrandsControllerUpdateUserRoleInBrandBody;
    },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerUpdateUserRoleInBrand>>,
  TError,
  {
    brandId: string;
    userId: string;
    data: BrandsControllerUpdateUserRoleInBrandBody;
  },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerUpdateUserRoleInBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Removes a user from a brand. Only users with the CREATOR role can perform this action.
 * @summary Remove a user from a brand
 */
export const brandsControllerRemoveUserFromBrand = (
  brandId: string,
  userId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<BrandsControllerRemoveUserFromBrand200>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/users/${encodeURIComponent(String(userId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getBrandsControllerRemoveUserFromBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>,
    TError,
    { brandId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>,
  TError,
  { brandId: string; userId: string },
  TContext
> => {
  const mutationKey = ['brandsControllerRemoveUserFromBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>,
    { brandId: string; userId: string }
  > = (props) => {
    const { brandId, userId } = props ?? {};

    return brandsControllerRemoveUserFromBrand(brandId, userId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerRemoveUserFromBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>
>;

export type BrandsControllerRemoveUserFromBrandMutationError = void;

/**
 * @summary Remove a user from a brand
 */
export const useBrandsControllerRemoveUserFromBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>,
    TError,
    { brandId: string; userId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerRemoveUserFromBrand>>,
  TError,
  { brandId: string; userId: string },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerRemoveUserFromBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Get user role in a specific brand
 */
export const brandsControllerGetUserRoleInBrand = (
  brandId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<BrandUserRoleDto>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/user-role`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getBrandsControllerGetUserRoleInBrandQueryKey = (
  brandId: string,
) => {
  return [`/brands/${brandId}/user-role`] as const;
};

export const getBrandsControllerGetUserRoleInBrandQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetUserRoleInBrandQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
  > = ({ signal }) =>
    brandsControllerGetUserRoleInBrand(brandId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetUserRoleInBrandQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
>;
export type BrandsControllerGetUserRoleInBrandQueryError = void;

export function useBrandsControllerGetUserRoleInBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetUserRoleInBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetUserRoleInBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get user role in a specific brand
 */

export function useBrandsControllerGetUserRoleInBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetUserRoleInBrandQueryOptions(
    brandId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user role in a specific brand
 */
export const prefetchBrandsControllerGetUserRoleInBrand = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetUserRoleInBrandQueryOptions(
    brandId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetUserRoleInBrandSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetUserRoleInBrandQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
  > = ({ signal }) =>
    brandsControllerGetUserRoleInBrand(brandId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetUserRoleInBrandSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>
>;
export type BrandsControllerGetUserRoleInBrandSuspenseQueryError = void;

export function useBrandsControllerGetUserRoleInBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetUserRoleInBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetUserRoleInBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get user role in a specific brand
 */

export function useBrandsControllerGetUserRoleInBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetUserRoleInBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getBrandsControllerGetUserRoleInBrandSuspenseQueryOptions(brandId, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the space groups of a brand with their spaces
 * @summary Get space groups of a brand
 */
export const brandsControllerGetSpaceGroupsOfBrand = (
  brandId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SpaceGroupWithSpaces[]>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/space-groups-with-spaces`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getBrandsControllerGetSpaceGroupsOfBrandQueryKey = (
  brandId: string,
) => {
  return [`/brands/${brandId}/space-groups-with-spaces`] as const;
};

export const getBrandsControllerGetSpaceGroupsOfBrandQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
  > = ({ signal }) =>
    brandsControllerGetSpaceGroupsOfBrand(brandId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!brandId,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetSpaceGroupsOfBrandQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
>;
export type BrandsControllerGetSpaceGroupsOfBrandQueryError = void;

export function useBrandsControllerGetSpaceGroupsOfBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupsOfBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetSpaceGroupsOfBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get space groups of a brand
 */

export function useBrandsControllerGetSpaceGroupsOfBrand<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetSpaceGroupsOfBrandQueryOptions(
    brandId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get space groups of a brand
 */
export const prefetchBrandsControllerGetSpaceGroupsOfBrand = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetSpaceGroupsOfBrandQueryOptions(
    brandId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetSpaceGroupsOfBrandSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
  > = ({ signal }) =>
    brandsControllerGetSpaceGroupsOfBrand(brandId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetSpaceGroupsOfBrandSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>
  >;
export type BrandsControllerGetSpaceGroupsOfBrandSuspenseQueryError = void;

export function useBrandsControllerGetSpaceGroupsOfBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupsOfBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupsOfBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get space groups of a brand
 */

export function useBrandsControllerGetSpaceGroupsOfBrandSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
  TError = void,
>(
  brandId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupsOfBrand>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getBrandsControllerGetSpaceGroupsOfBrandSuspenseQueryOptions(
      brandId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves a specific brand by its ID.
 * @summary Get a brand by id
 */
export const brandsControllerGetBrandById = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<Brand>(
    { url: `/brands/${encodeURIComponent(String(id))}`, method: 'GET', signal },
    options,
  );
};

export const getBrandsControllerGetBrandByIdQueryKey = (id: string) => {
  return [`/brands/${id}`] as const;
};

export const getBrandsControllerGetBrandByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetBrandByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetBrandById>>
  > = ({ signal }) => brandsControllerGetBrandById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetBrandByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetBrandById>>
>;
export type BrandsControllerGetBrandByIdQueryError = void;

export function useBrandsControllerGetBrandById<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetBrandById>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandById<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetBrandById>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetBrandById<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a brand by id
 */

export function useBrandsControllerGetBrandById<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetBrandByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a brand by id
 */
export const prefetchBrandsControllerGetBrandById = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetBrandByIdQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetBrandByIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBrandsControllerGetBrandByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetBrandById>>
  > = ({ signal }) => brandsControllerGetBrandById(id, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetBrandByIdSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetBrandById>>
>;
export type BrandsControllerGetBrandByIdSuspenseQueryError = void;

export function useBrandsControllerGetBrandByIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandByIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetBrandByIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get a brand by id
 */

export function useBrandsControllerGetBrandByIdSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetBrandById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getBrandsControllerGetBrandByIdSuspenseQueryOptions(
    id,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an existing brand with the provided data.
 * @summary Update a brand
 */
export const brandsControllerUpdateBrand = (
  id: string,
  updateBrandDto: UpdateBrandDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<Brand>(
    {
      url: `/brands/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateBrandDto,
    },
    options,
  );
};

export const getBrandsControllerUpdateBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerUpdateBrand>>,
    TError,
    { id: string; data: UpdateBrandDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerUpdateBrand>>,
  TError,
  { id: string; data: UpdateBrandDto },
  TContext
> => {
  const mutationKey = ['brandsControllerUpdateBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerUpdateBrand>>,
    { id: string; data: UpdateBrandDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return brandsControllerUpdateBrand(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerUpdateBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerUpdateBrand>>
>;
export type BrandsControllerUpdateBrandMutationBody = UpdateBrandDto;
export type BrandsControllerUpdateBrandMutationError = void;

/**
 * @summary Update a brand
 */
export const useBrandsControllerUpdateBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerUpdateBrand>>,
    TError,
    { id: string; data: UpdateBrandDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerUpdateBrand>>,
  TError,
  { id: string; data: UpdateBrandDto },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerUpdateBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a specific brand by its ID. Only the creator of the brand can delete it.
 * @summary Delete a brand by id
 */
export const brandsControllerDeleteBrand = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<DeleteBrandResponse>(
    { url: `/brands/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options,
  );
};

export const getBrandsControllerDeleteBrandMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerDeleteBrand>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerDeleteBrand>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationKey = ['brandsControllerDeleteBrand'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerDeleteBrand>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return brandsControllerDeleteBrand(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerDeleteBrandMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerDeleteBrand>>
>;

export type BrandsControllerDeleteBrandMutationError = void;

/**
 * @summary Delete a brand by id
 */
export const useBrandsControllerDeleteBrand = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerDeleteBrand>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerDeleteBrand>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerDeleteBrandMutationOptions(options);

  return useMutation(mutationOptions);
};
export const brandsControllerGetSpaceGroupPublicInfo = (
  brandId: string,
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/space-groups/${encodeURIComponent(String(spaceGroupId))}/public-info`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getBrandsControllerGetSpaceGroupPublicInfoQueryKey = (
  brandId: string,
  spaceGroupId: string,
) => {
  return [
    `/brands/${brandId}/space-groups/${spaceGroupId}/public-info`,
  ] as const;
};

export const getBrandsControllerGetSpaceGroupPublicInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetSpaceGroupPublicInfoQueryKey(brandId, spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
  > = ({ signal }) =>
    brandsControllerGetSpaceGroupPublicInfo(
      brandId,
      spaceGroupId,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(brandId && spaceGroupId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetSpaceGroupPublicInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
>;
export type BrandsControllerGetSpaceGroupPublicInfoQueryError = void;

export function useBrandsControllerGetSpaceGroupPublicInfo<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupPublicInfo<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
          TError,
          Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useBrandsControllerGetSpaceGroupPublicInfo<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };

export function useBrandsControllerGetSpaceGroupPublicInfo<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getBrandsControllerGetSpaceGroupPublicInfoQueryOptions(
    brandId,
    spaceGroupId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const prefetchBrandsControllerGetSpaceGroupPublicInfo = async <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getBrandsControllerGetSpaceGroupPublicInfoQueryOptions(
    brandId,
    spaceGroupId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getBrandsControllerGetSpaceGroupPublicInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBrandsControllerGetSpaceGroupPublicInfoQueryKey(brandId, spaceGroupId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
  > = ({ signal }) =>
    brandsControllerGetSpaceGroupPublicInfo(
      brandId,
      spaceGroupId,
      requestOptions,
      signal,
    );

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type BrandsControllerGetSpaceGroupPublicInfoSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>
  >;
export type BrandsControllerGetSpaceGroupPublicInfoSuspenseQueryError = void;

export function useBrandsControllerGetSpaceGroupPublicInfoSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupPublicInfoSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useBrandsControllerGetSpaceGroupPublicInfoSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};

export function useBrandsControllerGetSpaceGroupPublicInfoSuspense<
  TData = Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
  TError = void,
>(
  brandId: string,
  spaceGroupId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof brandsControllerGetSpaceGroupPublicInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getBrandsControllerGetSpaceGroupPublicInfoSuspenseQueryOptions(
      brandId,
      spaceGroupId,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const brandsControllerJoinSpaceGroup = (
  brandId: string,
  spaceGroupId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/brands/${encodeURIComponent(String(brandId))}/space-groups/${encodeURIComponent(String(spaceGroupId))}/join`,
      method: 'POST',
      signal,
    },
    options,
  );
};

export const getBrandsControllerJoinSpaceGroupMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>,
    TError,
    { brandId: string; spaceGroupId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>,
  TError,
  { brandId: string; spaceGroupId: string },
  TContext
> => {
  const mutationKey = ['brandsControllerJoinSpaceGroup'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>,
    { brandId: string; spaceGroupId: string }
  > = (props) => {
    const { brandId, spaceGroupId } = props ?? {};

    return brandsControllerJoinSpaceGroup(
      brandId,
      spaceGroupId,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type BrandsControllerJoinSpaceGroupMutationResult = NonNullable<
  Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>
>;

export type BrandsControllerJoinSpaceGroupMutationError = void;

export const useBrandsControllerJoinSpaceGroup = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>,
    TError,
    { brandId: string; spaceGroupId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof brandsControllerJoinSpaceGroup>>,
  TError,
  { brandId: string; spaceGroupId: string },
  TContext
> => {
  const mutationOptions =
    getBrandsControllerJoinSpaceGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
