import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { DateToString } from '@/utils/dateUtils';

import { useStripeMetricsControllerGetPayoutHistory } from '../../api/generated/api/stripe-metrics/stripe-metrics';

interface PayoutAccount {
  bank: string;
  lastFour: string;
}

interface PayoutItem {
  amount: string;
  account: PayoutAccount;
  initiated: string;
  expected: string;
  status: string;
}

const PayoutHistoryTab = () => {
  const { brandId } = useParams();
  const [payouts, setPayouts] = useState<PayoutItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  console.log(brandId);
  const {
    data,
    isLoading: isLoadingPayouts,
    error: payoutsError,
  } = useStripeMetricsControllerGetPayoutHistory(brandId ?? '');

  useEffect(() => {
    if (isLoadingPayouts) {
      setIsLoading(true);
      return;
    }

    if (payoutsError) {
      setError('Error al cargar el historial de pagos');
      setIsLoading(false);
      return;
    }

    if (data) {
      setPayouts(data.payoutHistory);
      setIsLoading(false);
    }
  }, [data, isLoadingPayouts, payoutsError]);

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
        Loading payout history...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-red-500">
        {error}
      </div>
    );
  }

  if (!payouts || payouts.length === 0) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
        No payout history
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow className="border-light bg-light-2 hover:bg-light-3 dark:border-dark-light dark:bg-dark-1 dark:hover:bg-dark-2">
          <TableHead>Amount</TableHead>
          <TableHead>Payout account</TableHead>
          <TableHead>Initiated</TableHead>
          <TableHead>Expected date</TableHead>
          <TableHead>Status</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {payouts.map((row, index) => (
          <TableRow
            key={index}
            className="border-0 hover:bg-light-2 dark:hover:bg-dark-2"
          >
            <TableCell className="px-2.5 py-5">{row.amount}</TableCell>
            <TableCell className="px-2.5 py-5">{`${row.account.bank} ••••${row.account.lastFour}`}</TableCell>
            <TableCell className="px-2.5 py-5">
              {DateToString(row.initiated)}
            </TableCell>
            <TableCell className="px-2.5 py-5">
              {DateToString(row.expected)}
            </TableCell>
            <TableCell className="flex items-center gap-2.5 px-2.5 py-5">
              <div
                className={`h-2.5 w-2.5 flex-none rounded-full ${
                  row.status === 'Pending'
                    ? 'bg-yellow-accent'
                    : row.status === 'Paid'
                      ? 'bg-green-accent'
                      : 'bg-red-accent'
                }`}
              />
              {row.status}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default PayoutHistoryTab;
