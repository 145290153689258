import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';

import { useBrandsControllerGetAllBrandsSuspense } from '@/api';
import { CompassIcon } from '@/assets/icon/compass';
import { SettingsIcon } from '@/assets/icon/settings';
import { UserIcon } from '@/assets/icon/user';
import { SelectBrand } from '@/components/layout/select-brand';
import { SpaceGroups } from '@/components/layout/space-groups';
import { ThemeToggle } from '@/components/theme-toggle';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card, CardContent } from '@/components/ui/card';
import { useAuth } from '@/hooks/useContext';
import { useCreatorSignup } from '@/hooks/useCreatorSignup';
import { cn, valueOrUndefined } from '@/lib/utils';

import CreatorPanel from './creator-panel';

export const Sidebar = () => {
  const { isCreatorSignup } = useCreatorSignup();
  const { user } = useAuth();
  const { brandId } = useParams();
  const navigate = useNavigate();
  const getAllBrandsQuery = useBrandsControllerGetAllBrandsSuspense();
  const brands = getAllBrandsQuery.data ?? [];
  // remove duplicates
  const brandsRefined = brands.filter(
    (brand, index, self) => index === self.findIndex((t) => t.id === brand.id),
  );
  const selectedBrand = brandsRefined.find((c) => c.id === brandId);

  const setBrandId = (id: string) => {
    navigate(`/brands/${id}`);
  };

  return (
    <aside className="grid h-full max-h-screen w-[80vw] grid-rows-[1fr,auto] overflow-y-auto overflow-x-hidden md:w-65">
      <div className="hide-scrollbar flex flex-col gap-2.5 overflow-x-hidden px-2.5 pt-6">
        {brandsRefined.length > 0 ? (
          <SelectBrand
            brandId={brandId}
            setBrandId={setBrandId}
            selectedBrand={selectedBrand}
            brands={brandsRefined}
          />
        ) : (
          <div />
        )}
        {brandsRefined.length === 0 && isCreatorSignup && (
          <div className="flex flex-col gap-1">
            {[
              brandsRefined.length === 0 && isCreatorSignup
                ? {
                    Icon: CompassIcon,
                    label: 'Getting started',
                    link: '/getting-started',
                  }
                : null,
            ]
              .filter((val) => val !== null)
              .map((item) => (
                <NavLink
                  key={item.label}
                  to={item.link}
                  className={({ isActive }) =>
                    cn(
                      'h-10 px-4 py-2',
                      'inline-flex items-center justify-center whitespace-nowrap rounded-md ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
                      'hover:bg-accent hover:text-accent-foreground',
                      'grid w-full grid-cols-[auto,1fr] gap-2 rounded-full',
                      isActive && 'bg-[#F2F7FF] dark:bg-[#2C2E30]',
                    )
                  }
                >
                  {({ isActive }) => (
                    <>
                      <item.Icon
                        className={cn(
                          'h-[1.15rem] w-[1.15rem] fill-black opacity-40 dark:fill-white dark:opacity-60',
                          isActive &&
                            'fill-[#4D8EED] opacity-100 dark:fill-[#4D8EED] dark:opacity-100',
                        )}
                      />
                      <span
                        className={cn(
                          'w-full overflow-hidden text-ellipsis text-base font-medium text-[#9E9E9E] dark:text-gray-300',
                          isActive && 'text-black dark:text-white',
                        )}
                      >
                        {item.label}
                      </span>
                    </>
                  )}
                </NavLink>
              ))}
          </div>
        )}

        <CreatorPanel brandId={brandId} />
        {brandId && <SpaceGroups brandId={brandId} />}
      </div>

      <div className="sticky bottom-0 z-50 flex bg-transparent p-2.5">
        <div className="flex w-full flex-col gap-4 rounded-lg bg-light-2 p-4 dark:bg-dark-2">
          <ThemeToggle />
          <Card className="rounded-2xl border border-light-3 bg-transparent dark:border-dark-3">
            <CardContent className="grid w-full grid-cols-[auto,1fr,auto] items-center gap-2 space-y-0 rounded-2xl p-1">
              <Avatar className="h-10 w-10 rounded-xl">
                <AvatarImage src={valueOrUndefined(user?.avatarUrl)} />
                <AvatarFallback>
                  <UserIcon className="stroke-textParagraph p-1 dark:stroke-white" />
                </AvatarFallback>
              </Avatar>
              <div className="flex flex-col overflow-hidden">
                <h6 className="truncate text-base font-medium">
                  {user?.name || 'User'}
                </h6>
              </div>
              <Link to={brandId ? `/brands/${brandId}/settings` : '/settings'}>
                <SettingsIcon className="h-5 min-w-5 fill-black pr-1 dark:fill-white" />
              </Link>
            </CardContent>
          </Card>
        </div>
      </div>
    </aside>
  );
};
