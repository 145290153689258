import { create } from 'zustand';

import {
  AddSpacesToSpaceGroupDto,
  CreateSpaceGroupDto,
  UpdateSpaceGroupMonetizationSettingsDto,
} from '@/api';

import {
  chooseSpaceOrSpaceGroup,
  handleBack,
  handleChooseSpaces,
  onAddClick,
} from './CreateNew.helper';

export type Steps =
  | 'none'
  | 'choose-space-or-space-group'
  | 'create-new-space-group'
  | 'create-pricing-variant'
  | 'add-spaces'
  | 'monetization';

export type SpaceType = 'Space' | 'Space group';

export type CreateNewSpaceGroupState = {
  spaceType: SpaceType;
  step: Steps;
  isDialogOpen: boolean;
  spacesList?: AddSpacesToSpaceGroupDto;
  spaceGroupData: CreateSpaceGroupDto;
  monetizationData: UpdateSpaceGroupMonetizationSettingsDto;
  provissionalSpaceGroupId?: string;
  formState: {
    name: string;
    permissions: 'OPEN' | 'CLOSED';
    pricingType: 'FREE' | 'PAID';
  };
};

type CreateNewSpaceGroupActions = {
  handleBack: () => void;
  setIsDialogOpen: (open: boolean) => void;
  onAddClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  chooseSpaceOrSpaceGroup: (type: SpaceType) => void;
  handleChooseSpaces: (
    spaces: AddSpacesToSpaceGroupDto,
    handleFinish: (chosenSpaces?: AddSpacesToSpaceGroupDto) => void,
  ) => void;
  setSpaceGroupData: (data: Partial<CreateSpaceGroupDto>) => void;
  updateFormState: (
    updates: Partial<CreateNewSpaceGroupState['formState']>,
  ) => void;
  setMonetizationData: (data: UpdateSpaceGroupMonetizationSettingsDto) => void;
  handleContinueCreateSpaceGroup: () => void;
  onAddPrincingVariantClick: () => void;
  onSavePricingVariant: () => void;
  setStep: (step: Steps) => void;
  setSpaceGroupId: (spaceGroupId?: string) => void;
  onClose: () => void;
  onDialogOpenChange: (open: boolean) => void;
  // Add a dedicated method to set the brandId
  setBrandId: (brandId: string) => void;
};

export type CreateNewSpaceGroupStore = CreateNewSpaceGroupState &
  CreateNewSpaceGroupActions;

export const useCreateNewSpaceGroupStore = create<CreateNewSpaceGroupStore>(
  (set, get) => ({
    step: 'none',
    spaceType: 'Space',
    isDialogOpen: false,
    spacesList: undefined,
    spaceGroupData: {
      brandId: '',
      name: '',
      pricingType: 'FREE',
      isPrivate: false,
    } as CreateSpaceGroupDto,
    formState: {
      name: '',
      permissions: 'OPEN',
      pricingType: 'FREE',
    },
    monetizationData: {
      allowDirectMessaging: false,
      messageFrequencyType: 'CAPPED',
      freeMessagesLimit: 0,
      freeMessagesFrequency: 'PER_DAY',
      allowCallMeetings: false,
      freeCallsLimit: 0,
      freeCallsFrequency: 'PER_DAY',
      freeCallDuration: 0,
      freeCallDurationType: 'MINUTES',
    },
    provissionalSpaceGroupId: undefined,

    setBrandId: (brandId: string) => {
      set((state) => ({
        spaceGroupData: {
          ...state.spaceGroupData,
          brandId,
        },
      }));
      console.log(`Brand ID set to: ${brandId}`);
    },

    handleBack: () =>
      set((state) => {
        const newStep = handleBack(state.spaceType, state.step);
        return { step: newStep };
      }),

    setIsDialogOpen: (open: boolean) => set({ isDialogOpen: open }),

    onAddClick: (e: React.MouseEvent<HTMLButtonElement>) => {
      const result = onAddClick(e);
      set(result);
    },

    chooseSpaceOrSpaceGroup: (type: SpaceType) =>
      set(chooseSpaceOrSpaceGroup(type)),

    handleChooseSpaces: (
      spaces: AddSpacesToSpaceGroupDto,
      handleFinish: (chosenSpaces?: AddSpacesToSpaceGroupDto) => void,
    ) => handleChooseSpaces(get().spaceType, spaces, set, handleFinish),

    setSpaceGroupData: (data: Partial<CreateSpaceGroupDto>) => {
      const current = get().spaceGroupData;
      const brandId = data.brandId || current.brandId;

      set({
        spaceGroupData: {
          ...current,
          ...data,
          brandId,
        } as CreateSpaceGroupDto,
      });
    },

    updateFormState: (
      updates: Partial<CreateNewSpaceGroupState['formState']>,
    ) => {
      const current = get().formState;
      set({
        formState: {
          ...current,
          ...updates,
        },
      });
      const { spaceGroupData } = get();
      const name = updates.name !== undefined ? updates.name : current.name;
      const permissions =
        updates.permissions !== undefined
          ? updates.permissions
          : current.permissions;
      const pricingType =
        updates.pricingType !== undefined
          ? updates.pricingType
          : current.pricingType;

      set({
        spaceGroupData: {
          ...spaceGroupData,
          name,
          setPermission: permissions,
          pricingType,
        } as CreateSpaceGroupDto,
      });
    },

    setMonetizationData: (data: UpdateSpaceGroupMonetizationSettingsDto) =>
      set({ monetizationData: data }),

    handleContinueCreateSpaceGroup: () => set({ step: 'add-spaces' }),

    onAddPrincingVariantClick: () => set({ step: 'create-pricing-variant' }),

    onSavePricingVariant: () => {
      const { formState, spaceGroupData } = get();
      set({
        step: 'create-new-space-group',
        spaceGroupData: {
          ...spaceGroupData,
          name: formState.name,
          setPermission: formState.permissions,
          pricingType: formState.pricingType,
        } as CreateSpaceGroupDto,
      });
    },

    setStep: (step: Steps) => set({ step }),

    setSpaceGroupId: (spaceGroupId?: string) =>
      set({ provissionalSpaceGroupId: spaceGroupId }),

    onClose: () => set({ isDialogOpen: false }),

    onDialogOpenChange: (open: boolean) => set({ isDialogOpen: open }),
  }),
);
