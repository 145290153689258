/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  CreateBrandInviteDto,
  CreateSpaceGroupInviteDto,
  FreeInviteResponse,
  InvitationsControllerAcceptInvitationBody,
  InvitationsControllerGetFreeInviteBySpaceGroupId400,
  InvitationsControllerGetFreeInviteBySpaceGroupId401,
  InvitationsControllerGetFreeInviteBySpaceGroupId404,
  ProcessInviteResponseDto,
  SuccessMessageResponseDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Create and send a brand invitation
 */
export const invitationsControllerCreateBrandInvite = (
  createBrandInviteDto: CreateBrandInviteDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/invitations/brand`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createBrandInviteDto,
      signal,
    },
    options,
  );
};

export const getInvitationsControllerCreateBrandInviteMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    TError,
    { data: CreateBrandInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
  TError,
  { data: CreateBrandInviteDto },
  TContext
> => {
  const mutationKey = ['invitationsControllerCreateBrandInvite'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    { data: CreateBrandInviteDto }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerCreateBrandInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerCreateBrandInviteMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>
>;
export type InvitationsControllerCreateBrandInviteMutationBody =
  CreateBrandInviteDto;
export type InvitationsControllerCreateBrandInviteMutationError = void;

/**
 * @summary Create and send a brand invitation
 */
export const useInvitationsControllerCreateBrandInvite = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
    TError,
    { data: CreateBrandInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerCreateBrandInvite>>,
  TError,
  { data: CreateBrandInviteDto },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerCreateBrandInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Create and send a space group invitation
 */
export const invitationsControllerCreateSpaceGroupInvite = (
  createSpaceGroupInviteDto: CreateSpaceGroupInviteDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/invitations/space-group`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createSpaceGroupInviteDto,
      signal,
    },
    options,
  );
};

export const getInvitationsControllerCreateSpaceGroupInviteMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    TError,
    { data: CreateSpaceGroupInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
  TError,
  { data: CreateSpaceGroupInviteDto },
  TContext
> => {
  const mutationKey = ['invitationsControllerCreateSpaceGroupInvite'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    { data: CreateSpaceGroupInviteDto }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerCreateSpaceGroupInvite(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerCreateSpaceGroupInviteMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>
  >;
export type InvitationsControllerCreateSpaceGroupInviteMutationBody =
  CreateSpaceGroupInviteDto;
export type InvitationsControllerCreateSpaceGroupInviteMutationError = void;

/**
 * @summary Create and send a space group invitation
 */
export const useInvitationsControllerCreateSpaceGroupInvite = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
    TError,
    { data: CreateSpaceGroupInviteDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerCreateSpaceGroupInvite>>,
  TError,
  { data: CreateSpaceGroupInviteDto },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerCreateSpaceGroupInviteMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Process an invitation
 */
export const invitationsControllerAcceptInvitation = (
  invitationsControllerAcceptInvitationBody: InvitationsControllerAcceptInvitationBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<ProcessInviteResponseDto>(
    {
      url: `/invitations/accept`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: invitationsControllerAcceptInvitationBody,
      signal,
    },
    options,
  );
};

export const getInvitationsControllerAcceptInvitationMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    TError,
    { data: InvitationsControllerAcceptInvitationBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
  TError,
  { data: InvitationsControllerAcceptInvitationBody },
  TContext
> => {
  const mutationKey = ['invitationsControllerAcceptInvitation'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    { data: InvitationsControllerAcceptInvitationBody }
  > = (props) => {
    const { data } = props ?? {};

    return invitationsControllerAcceptInvitation(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type InvitationsControllerAcceptInvitationMutationResult = NonNullable<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>
>;
export type InvitationsControllerAcceptInvitationMutationBody =
  InvitationsControllerAcceptInvitationBody;
export type InvitationsControllerAcceptInvitationMutationError = void;

/**
 * @summary Process an invitation
 */
export const useInvitationsControllerAcceptInvitation = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
    TError,
    { data: InvitationsControllerAcceptInvitationBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof invitationsControllerAcceptInvitation>>,
  TError,
  { data: InvitationsControllerAcceptInvitationBody },
  TContext
> => {
  const mutationOptions =
    getInvitationsControllerAcceptInvitationMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Creates an invitation for the specified email to join the space group without requiring payment
 * @summary Generate a free invite link for a space group
 */
export const invitationsControllerGetFreeInviteBySpaceGroupId = (
  spaceGroupId: string,
  email: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<FreeInviteResponse>(
    {
      url: `/invitations/${encodeURIComponent(String(spaceGroupId))}/free-invite/${encodeURIComponent(String(email))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryKey = (
  spaceGroupId: string,
  email: string,
) => {
  return [`/invitations/${spaceGroupId}/free-invite/${email}`] as const;
};

export const getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryOptions = <
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryKey(
      spaceGroupId,
      email,
    );

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>>
  > = ({ signal }) =>
    invitationsControllerGetFreeInviteBySpaceGroupId(
      spaceGroupId,
      email,
      requestOptions,
      signal,
    );

  return {
    queryKey,
    queryFn,
    enabled: !!(spaceGroupId && email),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<
      ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
    >,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type InvitationsControllerGetFreeInviteBySpaceGroupIdQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>>
  >;
export type InvitationsControllerGetFreeInviteBySpaceGroupIdQueryError =
  | InvitationsControllerGetFreeInviteBySpaceGroupId400
  | InvitationsControllerGetFreeInviteBySpaceGroupId401
  | InvitationsControllerGetFreeInviteBySpaceGroupId404;

export function useInvitationsControllerGetFreeInviteBySpaceGroupId<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
          >,
          TError,
          Awaited<
            ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvitationsControllerGetFreeInviteBySpaceGroupId<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<
            ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
          >,
          TError,
          Awaited<
            ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
          >
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useInvitationsControllerGetFreeInviteBySpaceGroupId<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Generate a free invite link for a space group
 */

export function useInvitationsControllerGetFreeInviteBySpaceGroupId<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryOptions(
      spaceGroupId,
      email,
      options,
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Generate a free invite link for a space group
 */
export const prefetchInvitationsControllerGetFreeInviteBySpaceGroupId = async <
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  queryClient: QueryClient,
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryOptions(
      spaceGroupId,
      email,
      options,
    );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getInvitationsControllerGetFreeInviteBySpaceGroupIdSuspenseQueryOptions =
  <
    TData = Awaited<
      ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
    >,
    TError =
      | InvitationsControllerGetFreeInviteBySpaceGroupId400
      | InvitationsControllerGetFreeInviteBySpaceGroupId401
      | InvitationsControllerGetFreeInviteBySpaceGroupId404,
  >(
    spaceGroupId: string,
    email: string,
    options?: {
      query?: Partial<
        UseSuspenseQueryOptions<
          Awaited<
            ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
          >,
          TError,
          TData
        >
      >;
      request?: SecondParameter<typeof customFetch>;
    },
  ) => {
    const { query: queryOptions, request: requestOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getInvitationsControllerGetFreeInviteBySpaceGroupIdQueryKey(
        spaceGroupId,
        email,
      );

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
      >
    > = ({ signal }) =>
      invitationsControllerGetFreeInviteBySpaceGroupId(
        spaceGroupId,
        email,
        requestOptions,
        signal,
      );

    return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
      Awaited<
        ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
      >,
      TError,
      TData
    > & { queryKey: DataTag<QueryKey, TData> };
  };

export type InvitationsControllerGetFreeInviteBySpaceGroupIdSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>>
  >;
export type InvitationsControllerGetFreeInviteBySpaceGroupIdSuspenseQueryError =

    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404;

export function useInvitationsControllerGetFreeInviteBySpaceGroupIdSuspense<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvitationsControllerGetFreeInviteBySpaceGroupIdSuspense<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useInvitationsControllerGetFreeInviteBySpaceGroupIdSuspense<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Generate a free invite link for a space group
 */

export function useInvitationsControllerGetFreeInviteBySpaceGroupIdSuspense<
  TData = Awaited<
    ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
  >,
  TError =
    | InvitationsControllerGetFreeInviteBySpaceGroupId400
    | InvitationsControllerGetFreeInviteBySpaceGroupId401
    | InvitationsControllerGetFreeInviteBySpaceGroupId404,
>(
  spaceGroupId: string,
  email: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<
          ReturnType<typeof invitationsControllerGetFreeInviteBySpaceGroupId>
        >,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getInvitationsControllerGetFreeInviteBySpaceGroupIdSuspenseQueryOptions(
      spaceGroupId,
      email,
      options,
    );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
