import React from 'react';

const PrivacyPolicyPage: React.FC = () => {
  return (
    <div className="mx-auto max-w-3xl px-6 py-8">
      {/* Título principal */}
      <h1 className="mb-4 text-center text-3xl font-bold">Privacy Policy</h1>

      {/* Fecha de actualización */}
      <p className="mb-6 text-center italic">
        Last Updated: September 29, 2021
      </p>

      {/* Introducción */}
      <p className="mb-6 text-justify">
        This Privacy Policy (“Privacy Policy”) explains our privacy practices
        for the activities described here. Please read this Privacy Policy
        carefully to learn how we collect, use, share, and otherwise process
        information relating to individuals (“Personal Data”), and to learn
        about your rights and choices regarding your Personal Data.
      </p>
      <p className="mb-6 text-justify">
        A reference to “Teachly,” “we,” “us” or the “Platform” means Teachly,
        Inc. and its affiliates.
      </p>

      {/* Sección 1: General Information and Scope */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        1. General Information and Scope
      </h2>
      <p className="mb-6 text-justify">
        <strong>Company Information</strong>
        <br />
        Official Name: Creator Community Company (Teachly)
        <br />
        Physical Address: 7 Little Cove Lane, Old Greenwich, CT 06870
        <br />
        Primary Contact: hello@teachly.ai
        <br />
        Last Updated: September 29, 2021
      </p>
      <p className="mb-6 text-justify">
        <strong>Scope of Application</strong>
        <br />
        The policy applies to all interactions with:
        <ul className="ml-4 mt-2 list-inside list-disc">
          <li>app.teachly.ai website</li>
          <li>Mobile applications</li>
          <li>Service platforms</li>
          <li>Associated social media pages</li>
          <li>Email and phone communications</li>
          <li>Creator-subscriber interactions</li>
        </ul>
      </p>

      {/* Sección 2: Detailed Collection and Use of Personal Data */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        2. Detailed Collection and Use of Personal Data
      </h2>

      {/* 2.A. Personal Identification Information */}
      <h3 className="mb-2 text-lg font-medium">
        A. Personal Identification Information
      </h3>
      <p className="mb-4 text-justify">
        <strong>Basic Data:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>Full name</li>
          <li>Email address</li>
          <li>Phone number</li>
          <li>Postal address</li>
          <li>Bank account information (for payments)</li>
        </ul>
      </p>
      <p className="mb-4 text-justify">
        <strong>Profile Data:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>Username</li>
          <li>Password</li>
          <li>Content preferences</li>
          <li>Interaction history</li>
          <li>Profile photos</li>
        </ul>
      </p>
      <p className="mb-6 text-justify">
        <strong>Professional Information:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>Work experience</li>
          <li>Educational credentials</li>
          <li>Certifications</li>
          <li>Teaching specialties</li>
        </ul>
      </p>

      {/* 2.B. Technical and Usage Data */}
      <h3 className="mb-2 text-lg font-medium">B. Technical and Usage Data</h3>
      <p className="mb-4 text-justify">
        <strong>Device Information:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>IP address</li>
          <li>Browser type</li>
          <li>Operating system</li>
          <li>Device identifiers</li>
          <li>System configuration</li>
        </ul>
      </p>
      <p className="mb-4 text-justify">
        <strong>Usage Data:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>Pages visited</li>
          <li>Time spent</li>
          <li>Navigation patterns</li>
          <li>Content interactions</li>
          <li>Visit frequency</li>
        </ul>
      </p>
      <p className="mb-6 text-justify">
        <strong>Location Data:</strong>
        <ul className="ml-4 list-inside list-disc">
          <li>Geographic location</li>
          <li>Time zone</li>
          <li>Country of access</li>
          <li>Regional preferences</li>
        </ul>
      </p>

      {/* Sección 3: Specific Purposes of Data Use */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        3. Specific Purposes of Data Use
      </h2>

      {/* 3.A. Core Functionality */}
      <h3 className="mb-2 text-lg font-medium">A. Core Functionality</h3>
      <p className="mb-4 text-justify">
        <strong>Account Management:</strong> Profile creation and maintenance,
        user authentication, experience personalization, subscription
        management, payment processing.
      </p>
      <p className="mb-4 text-justify">
        <strong>Content Delivery:</strong> Educational material distribution,
        exclusive content access, personalized recommendations, multimedia
        content streaming, creator-subscriber interactions.
      </p>
      <p className="mb-6 text-justify">
        <strong>Communications:</strong> Important notifications, service
        updates, newsletters, query responses, technical support.
      </p>

      {/* 3.B. Improvement and Development */}
      <h3 className="mb-2 text-lg font-medium">
        B. Improvement and Development
      </h3>
      <p className="mb-4 text-justify">
        <strong>Analysis and Optimization:</strong> User behavior studies,
        performance analysis, trend identification, functionality improvement,
        feature development.
      </p>
      <p className="mb-6 text-justify">
        <strong>Research and Development:</strong> Market research, new feature
        testing, satisfaction analysis, product development, service innovation.
      </p>

      {/* Sección 4: Data Sharing and Protection */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        4. Data Sharing and Protection
      </h2>

      {/* 4.A. Authorized Third Parties */}
      <h3 className="mb-2 text-lg font-medium">A. Authorized Third Parties</h3>
      <p className="mb-4 text-justify">
        <strong>Service Providers:</strong> Payment processors, hosting
        services, data analytics, customer support, security services.
      </p>
      <p className="mb-6 text-justify">
        <strong>Business Partners:</strong> Marketing platforms, verification
        services, content providers, integration services, specialized
        consultants.
      </p>

      {/* 4.B. Security Measures */}
      <h3 className="mb-2 text-lg font-medium">B. Security Measures</h3>
      <p className="mb-4 text-justify">
        <strong>Technical Protection:</strong> Data encryption, security
        firewalls, continuous monitoring, backup systems, secure access
        protocols.
      </p>
      <p className="mb-6 text-justify">
        <strong>Administrative Controls:</strong> Access policies, staff
        training, regular audits, response procedures, incident management.
      </p>

      {/* Sección 5: User Rights and Control */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        5. User Rights and Control
      </h2>

      {/* 5.A. Fundamental Rights */}
      <h3 className="mb-2 text-lg font-medium">A. Fundamental Rights</h3>
      <p className="mb-4 text-justify">
        <strong>Access and Control:</strong> Personal data access, information
        correction, data deletion, processing restriction, data portability.
      </p>
      <p className="mb-6 text-justify">
        <strong>Preferences and Consent:</strong> Cookie management,
        communication preferences, privacy options, sharing controls, consent
        withdrawal.
      </p>

      {/* 5.B. Exercise Procedures */}
      <h3 className="mb-2 text-lg font-medium">B. Exercise Procedures</h3>
      <p className="mb-4 text-justify">
        <strong>Rights Requests:</strong> Verification process, response
        timeframes, request formats, required documentation, communication
        channels.
      </p>
      <p className="mb-6 text-justify">
        <strong>User Support:</strong> Dedicated support, query resolution,
        complaint handling, technical assistance, case tracking.
      </p>

      {/* Sección 6: Legal and Regulatory Compliance */}
      <h2 className="mb-4 text-center text-2xl font-semibold">
        6. Legal and Regulatory Compliance
      </h2>

      {/* 6.A. Regulatory Framework */}
      <h3 className="mb-2 text-lg font-medium">A. Regulatory Framework</h3>
      <p className="mb-4 text-justify">
        <strong>Applicable Regulations:</strong> GDPR (Europe), CCPA
        (California), LGPD (Brazil), PIPEDA (Canada), other local regulations.
      </p>
      <p className="mb-6 text-justify">
        <strong>Specific Obligations:</strong> Breach notification, impact
        assessments, activity records, regulatory reporting, compliance updates.
      </p>

      {/* 6.B. Contact and Responsibility */}
      <h3 className="mb-2 text-lg font-medium">
        B. Contact and Responsibility
      </h3>
      <p className="mb-4 text-justify">
        <strong>Primary Contact Information:</strong>
        <br />
        Creator Community Company
        <br />
        Attention: Privacy Policy
        <br />
        7 Little Cove Lane
        <br />
        Old Greenwich, CT 06870
        <br />
        Email: hello@teachly.ai
      </p>
      <p className="mb-6 text-justify">
        <strong>Additional Communication Channels:</strong> Web contact form,
        privacy hotline, live support chat, certified postal mail, local privacy
        representatives.
      </p>

      {/* Conclusión */}
      <p className="mt-8 text-justify">
        This privacy policy represents Teachly's commitment to personal data
        protection and transparency in its processing, ensuring users have
        control over their information while maintaining platform functionality
        and security.
      </p>
    </div>
  );
};

export default PrivacyPolicyPage;
