import { Link } from 'react-router-dom';

import { Button } from '@/components/ui/button';

export const NotFoundPage = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-4">
        <h1 className="text-5xl font-bold">404</h1>
        <p className="text-lg">Page not found</p>
        <Button asChild>
          <Link to="/">Go to home</Link>
        </Button>
      </div>
    </div>
  );
};
