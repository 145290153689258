import { AccordionItem } from '@radix-ui/react-accordion';
import { DragHandleDots2Icon } from '@radix-ui/react-icons';
import { ArrowUpDownIcon, EllipsisVerticalIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  Chapter,
  useCoursesSpacesControllerDeleteChapter,
  useCoursesSpacesControllerDeleteCourseLesson,
  useCoursesSpacesControllerGetChaptersByCourseId,
  useCoursesSpacesControllerMoveLessonBetweenChapters,
  useCoursesSpacesControllerOrganizeChapters,
  useCoursesSpacesControllerOrganizeLessons,
  useCoursesSpacesControllerRenameChapter,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { ArrowRightIcon } from '@/assets/icon/arrowRight';
import { PencilAltIcon } from '@/assets/icon/pencilAlt';
import { TextCircleIcon } from '@/assets/icon/textCircle';
import { TrashAltIcon } from '@/assets/icon/trash-alt';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import InputField from '@/components/ui/InputField';
import {
  Accordion,
  AccordionContent,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import AddChaptersDialog from '@/components/ui/chapters-components/AddChaptersDialog';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useBrandPermits } from '@/hooks/useBrandPermits';

const ChapterListPage = () => {
  const hasPermits = useBrandPermits();
  const navigate = useNavigate();
  const { brandId, spaceGroupId, coursesSpaceId, courseId } = useParams();
  const getChaptersWithLessonsMutation =
    useCoursesSpacesControllerGetChaptersByCourseId(courseId!);
  const renameChapterMutation = useCoursesSpacesControllerRenameChapter();
  const deleteLessonMutation = useCoursesSpacesControllerDeleteCourseLesson();
  const deleteChapterMutation = useCoursesSpacesControllerDeleteChapter();
  const reorderChaptersMutation = useCoursesSpacesControllerOrganizeChapters();
  const reorderLessonsMutation = useCoursesSpacesControllerOrganizeLessons();
  const moveLessonToChapterMutation =
    useCoursesSpacesControllerMoveLessonBetweenChapters();

  useUpdateConfigHeader({
    showNotifications: true,
    label: 'Chapters',
    action: null,
  });

  const [chapters, setChapters] = useState<Chapter[]>([]);
  const [openRename, setOpenRename] = useState(false);
  const [selectedChapter, setSelectedChapter] = useState<Chapter>();
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    if (getChaptersWithLessonsMutation.data) {
      setChapters(getChaptersWithLessonsMutation.data!);
    }
    setIsLoading(false);
  }, [getChaptersWithLessonsMutation.data]);

  const handleRenameChapter = () => {
    try {
      renameChapterMutation.mutate(
        {
          courseId: courseId!,
          chapterId: selectedChapter!.id,
          data: {
            name,
          },
        },
        {
          onSuccess: () => {
            setOpenRename(false);
            getChaptersWithLessonsMutation.refetch();
            setName('');
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddLesson = (chapterId: string) => {
    navigate(
      `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/chapter/${chapterId}/lessons/create`,
    );
  };

  const handleDeleteLesson = (lessonId: string) => {
    deleteLessonMutation.mutate(
      {
        lessonId,
      },
      {
        onSuccess: () => {
          getChaptersWithLessonsMutation.refetch();
        },
      },
    );
  };

  const handleDeleteChapter = (chapterId: string) => {
    deleteChapterMutation.mutate(
      {
        chapterId,
      },
      {
        onSuccess: () => {
          getChaptersWithLessonsMutation.refetch();
        },
      },
    );
  };

  const handleReorderChapters = (oldIndex: number, newIndex: number) => {
    const newLessons = [...chapters];
    const [removed] = newLessons.splice(oldIndex, 1);
    newLessons.splice(newIndex, 0, removed);
    reorderChaptersMutation.mutate(
      {
        courseId: courseId!,
        data: {
          chapterIds: newLessons.map((chapter) => chapter.id),
        },
      },
      {
        onSuccess: () => {
          getChaptersWithLessonsMutation.refetch();
        },
      },
    );
    setChapters(newLessons);
  };

  const handleReorderLessonsInsideAChapter = (
    chapterId: string,
    oldIndex: number,
    newIndex: number,
  ) => {
    const newChapters = [...chapters];
    const chapterIndex = newChapters.findIndex((chpt) => chpt.id === chapterId);
    const newLessons = [...newChapters[chapterIndex].lessons];
    const [removed] = newLessons.splice(oldIndex, 1);
    newLessons.splice(newIndex, 0, removed);
    newChapters[chapterIndex].lessons = newLessons;

    reorderLessonsMutation.mutate({
      chapterId,
      data: {
        lessonIds: newLessons.map((lesson) => lesson.id),
      },
    });
    setChapters(newChapters);
  };

  const handleMoveToChapter = (
    originChapterId: string,
    destinationChapterId: string,
    lessonId: string,
  ) => {
    moveLessonToChapterMutation.mutate(
      {
        targetChapterId: destinationChapterId,
        lessonId,
        sourceChapterId: originChapterId,
      },
      {
        onSuccess: () => {
          getChaptersWithLessonsMutation.refetch();
        },
      },
    );
  };

  return (
    <div className="flex h-full flex-col gap-16 overflow-auto px-6 py-10 xl:px-25 xl:py-16">
      <div className="flex w-full flex-col justify-between gap-15 sm:flex-row sm:gap-0">
        <Link
          to={`/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`}
          className="flex w-fit items-center gap-2.5"
        >
          <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
          Back to courses
        </Link>
        {hasPermits && (
          <AddChaptersDialog
            refetch={() => getChaptersWithLessonsMutation.refetch()}
          />
        )}
      </div>
      <div className="flex h-full flex-col gap-6">
        {isLoading && (
          <div className="flex h-full items-center justify-center">
            <Loading />
          </div>
        )}
        {!isLoading && chapters.length > 0 && (
          <Accordion
            type="multiple"
            className="flex flex-col gap-4"
            defaultValue={[chapters[0].id]}
          >
            {hasPermits ? (
              <SortableList
                onSortEnd={handleReorderChapters}
                className="flex flex-col gap-6"
                draggedItemClassName="dragged"
              >
                {chapters
                  .sort((a, b) => a.order - b.order)
                  .map((chpt, i) => (
                    <SortableItem key={chpt.id}>
                      <AccordionItem
                        value={chpt.id}
                        className="rounded-lg bg-light-2 px-4 dark:bg-dark-2"
                        defaultChecked={i === 0}
                      >
                        <AccordionTrigger className="gap-2 text-2xl font-semibold">
                          <SortableKnob>
                            <DragHandleDots2Icon className="h-6 w-6 flex-shrink-0" />
                          </SortableKnob>
                          <h1 className="w-full text-start">{chpt.name}</h1>
                          {hasPermits && (
                            <DropdownMenu>
                              <DropdownMenuTrigger>
                                <EllipsisVerticalIcon />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                side="bottom"
                                align="end"
                              >
                                <DropdownMenuItem
                                  className="flex items-center gap-2.5 p-2.5 font-medium"
                                  onSelect={() => {
                                    setSelectedChapter(chpt);
                                    setName(chpt.name);
                                    setOpenRename(true);
                                  }}
                                >
                                  <TextCircleIcon className="h-4 w-4 stroke-black dark:stroke-white" />
                                  Rename chapter
                                </DropdownMenuItem>
                                <DropdownMenuItem
                                  className="flex items-center gap-2.5 font-medium text-error hover:!text-error"
                                  onSelect={() => {
                                    handleDeleteChapter(chpt.id);
                                  }}
                                >
                                  <TrashAltIcon className="h-4 w-4 stroke-error" />
                                  Delete chapter
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          )}
                        </AccordionTrigger>
                        <AccordionContent className="mb-4 rounded-lg bg-light-3 dark:bg-dark-3">
                          <div className="flex flex-col gap-6 px-4 pt-4">
                            {chpt.lessons.length === 0 ? (
                              <p className="flex flex-col items-center justify-center gap-4 py-40 text-center text-lg font-medium">
                                No lessons created yet
                                <Button
                                  onClick={() => handleAddLesson(chpt.id)}
                                >
                                  Add a lesson
                                </Button>
                              </p>
                            ) : (
                              <>
                                <SortableList
                                  onSortEnd={(oldIndex, newIndex) => {
                                    handleReorderLessonsInsideAChapter(
                                      chpt.id,
                                      oldIndex,
                                      newIndex,
                                    );
                                  }}
                                  className="flex flex-col gap-6"
                                  draggedItemClassName="dragged"
                                >
                                  {chpt.lessons.map((lesson) => (
                                    <SortableItem key={lesson.id}>
                                      <h2
                                        className="flex cursor-pointer items-center justify-between text-lg font-medium"
                                        onClick={() => {
                                          navigate(
                                            `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/chapter/${chpt.id}/lessons/${lesson.id}`,
                                          );
                                        }}
                                      >
                                        <div className="flex items-center gap-2.5">
                                          <SortableKnob>
                                            <DragHandleDots2Icon className="h-6 w-6 flex-shrink-0" />
                                          </SortableKnob>
                                          {lesson.name}
                                        </div>
                                        <div className="flex items-center gap-2.5">
                                          <DropdownMenu>
                                            <DropdownMenuTrigger>
                                              <EllipsisVerticalIcon />
                                            </DropdownMenuTrigger>
                                            <DropdownMenuContent
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                              side="bottom"
                                              align="end"
                                            >
                                              <DropdownMenuItem
                                                className="flex items-center gap-2.5 p-2.5 font-medium"
                                                onSelect={() => {
                                                  navigate(
                                                    `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/chapter/${chpt.id}/lessons/${lesson.id}/edit`,
                                                  );
                                                }}
                                              >
                                                <PencilAltIcon className="h-4 w-4 fill-black dark:fill-white" />
                                                Edit lesson
                                              </DropdownMenuItem>
                                              <DropdownMenuSub>
                                                <DropdownMenuSubTrigger className="flex items-center gap-2.5">
                                                  <ArrowUpDownIcon className="h-4 w-4" />
                                                  Move to
                                                </DropdownMenuSubTrigger>
                                                <DropdownMenuPortal>
                                                  <DropdownMenuSubContent>
                                                    {chapters.map((c) => (
                                                      <DropdownMenuItem
                                                        key={c.id}
                                                        onSelect={() => {
                                                          handleMoveToChapter(
                                                            chpt.id,
                                                            c.id,
                                                            lesson.id,
                                                          );
                                                        }}
                                                        disabled={
                                                          c.id === chpt.id
                                                        }
                                                      >
                                                        {c.name}
                                                      </DropdownMenuItem>
                                                    ))}
                                                  </DropdownMenuSubContent>
                                                </DropdownMenuPortal>
                                              </DropdownMenuSub>
                                              <DropdownMenuItem
                                                className="flex items-center gap-2.5 font-medium text-error hover:!text-error"
                                                onSelect={() =>
                                                  handleDeleteLesson(lesson.id)
                                                }
                                              >
                                                <TrashAltIcon className="h-4 w-4 stroke-error" />
                                                Delete lesson
                                              </DropdownMenuItem>
                                            </DropdownMenuContent>
                                          </DropdownMenu>
                                          <ArrowRightIcon className="h-6 w-6 fill-black dark:fill-white" />
                                        </div>
                                      </h2>
                                    </SortableItem>
                                  ))}
                                </SortableList>
                                <Button
                                  onClick={() => handleAddLesson(chpt.id)}
                                  className="w-fit"
                                >
                                  Add a lesson
                                </Button>
                              </>
                            )}
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </SortableItem>
                  ))}
              </SortableList>
            ) : (
              chapters
                .sort((a, b) => a.order - b.order)
                .map((chpt, i) => (
                  <AccordionItem
                    value={chpt.id}
                    className="rounded-lg bg-light-2 px-4 dark:bg-dark-2"
                    defaultChecked={i === 0}
                  >
                    <AccordionTrigger className="gap-2 text-2xl font-semibold">
                      <h1 className="w-full text-start">{chpt.name}</h1>
                    </AccordionTrigger>
                    <AccordionContent className="mb-4 rounded-lg bg-light-3 dark:bg-dark-3">
                      <div className="flex flex-col gap-6 px-4 pt-4">
                        {chpt.lessons.length === 0 ? (
                          <p className="flex flex-col items-center justify-center gap-4 py-40 text-center text-lg font-medium">
                            No lessons created yet
                            <Button onClick={() => handleAddLesson(chpt.id)}>
                              Add a lesson
                            </Button>
                          </p>
                        ) : (
                          <>
                            {chpt.lessons.map((lesson) => (
                              <h2
                                className="flex cursor-pointer items-center justify-between text-lg font-medium"
                                onClick={() => {
                                  navigate(
                                    `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}/courses/${courseId}/chapter/${chpt.id}/lessons/${lesson.id}`,
                                  );
                                }}
                              >
                                <div className="flex items-center gap-2.5">
                                  {lesson.name}
                                </div>
                                <div className="flex items-center gap-2.5">
                                  {/* maybe add here a check icon if lesson is completed */}
                                  <ArrowRightIcon className="h-6 w-6 fill-black dark:fill-white" />
                                </div>
                              </h2>
                            ))}
                          </>
                        )}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                ))
            )}
          </Accordion>
        )}
      </div>
      <Dialog open={openRename} onOpenChange={setOpenRename}>
        <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
          <DialogHeader className="flex-row items-center justify-between space-y-0">
            <DialogTitle className="text-2xl font-semibold">
              Rename this automation
            </DialogTitle>
            <Button
              onClick={() => setOpenRename(false)}
              variant="icon"
              size="icon"
              className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
            >
              <XIcon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <InputField
            type="text"
            title="Automation name"
            inputClassName="!border !border-light dark:!border-dark-light !bg-transparent"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            onClick={handleRenameChapter}
            disabled={!name || renameChapterMutation.isPending}
            className="w-fit self-end"
          >
            Save changes
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ChapterListPage;
