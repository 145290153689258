import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useStripeMetricsControllerGetTransactionHistory } from '@/api';
import { DateToString } from '@/utils/dateUtils';

interface TransactionItem {
  id: string;
  name: string;
  email: string;
  spaceGroup: string;
  amount: string;
  photo: string | null;
  date: string;
}

const TransactionHistoryTab = () => {
  const { brandId } = useParams();
  const [transactions, setTransactions] = useState<TransactionItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const {
    data,
    isLoading: isLoadingTransactions,
    error: transactionsError,
  } = useStripeMetricsControllerGetTransactionHistory(brandId || '');

  useEffect(() => {
    if (isLoadingTransactions) {
      setIsLoading(true);
      return;
    }

    if (transactionsError) {
      setError('Error al cargar el historial de transacciones');
      setIsLoading(false);
      return;
    }

    if (data) {
      setTransactions(data.transactions as TransactionItem[]);
      setIsLoading(false);
    }
  }, [data, isLoadingTransactions, transactionsError]);

  // Mostramos un estado de carga
  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
        Loading transaction history...
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-red-500">
        {error}
      </div>
    );
  }

  if (!transactions || transactions.length === 0) {
    return (
      <div className="flex w-full items-center justify-center pt-4 text-black dark:text-white">
        No transaction history
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {transactions.map((data, index) => (
        <div
          className="flex w-full items-start gap-2.5 rounded-lg bg-light-2 px-2.5 py-4 dark:bg-dark-2"
          key={index}
        >
          {data.photo ? (
            <img src={data.photo} alt="user" className="h-10 w-10 rounded-lg" />
          ) : (
            <div className="flex min-h-10 min-w-10 items-center justify-center rounded-lg bg-brand text-white">
              {data.name && data.name.length > 1
                ? data.name.split('')[0] + data.name.split('')[1].toUpperCase()
                : data.email
                  ? data.email.split('')[0].toUpperCase()
                  : 'U'}
            </div>
          )}
          <div className="flex w-full flex-col gap-1">
            <div className="font-medium text-black dark:text-white">
              {data.email + ' '}
              <span className="text-textParagraph dark:text-dark-textParagraph">
                subscribed to ({data.spaceGroup}) for
              </span>
              {' $' + data.amount}
            </div>
            <div className="text-xs font-medium text-textParagraph dark:text-dark-textParagraph">
              {DateToString(data.date)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TransactionHistoryTab;
