import * as Dialog from '@radix-ui/react-dialog';
import React from 'react';

import { CheckIcon } from '@/assets/icon/check-alt';

interface PaymentSuccessModalProps {
  open: boolean;
  onContinue: () => void;
}

const PaymentSuccessModal: React.FC<PaymentSuccessModalProps> = ({
  open,
  onContinue,
}) => {
  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-50 bg-black/50" />
        <Dialog.Content className="fixed left-1/2 top-1/2 z-50 w-[90%] max-w-md -translate-x-1/2 -translate-y-1/2 transform rounded-lg bg-white p-6 shadow-lg dark:bg-dark-1">
          <div className="flex flex-col items-center text-center">
            <div className="mb-4 flex h-16 w-16 items-center justify-center rounded-full bg-brand/20">
              <CheckIcon className="h-8 w-8 stroke-green-500" />
            </div>

            <Dialog.Title className="mb-2 text-2xl font-bold text-black dark:text-white">
              Payment Successful!
            </Dialog.Title>

            <Dialog.Description className="mb-6 text-textParagraph dark:text-dark-textParagraph">
              To access your purchase, you need to create an account or sign in.
            </Dialog.Description>

            <button
              onClick={onContinue}
              className="w-full rounded-md bg-brand px-4 py-3 font-medium text-white transition-colors hover:bg-brand/90"
            >
              Continue to create account
            </button>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default PaymentSuccessModal;
