import React from 'react';

const TermsOfServicePage: React.FC = () => {
  return (
    <div className="mx-auto max-w-3xl px-6 py-8">
      {/* Título principal */}
      <h1 className="mb-4 text-center text-3xl font-bold">Terms of Service</h1>

      {/* Fecha de actualización */}
      <p className="mb-8 text-center italic">Last Updated: April 2, 2024</p>

      {/* Introducción */}
      <p className="mb-6 text-justify">
        Welcome to Teachly! This is a legal document. By using Teachly, you
        agree to the terms below.
      </p>

      {/* Sección: Platform Overview & Core Structure */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Platform Overview &amp; Core Structure
      </h2>
      <p className="mb-6 text-justify">
        <strong>Platform Definition:</strong> Teachly is an exclusive-community
        and content platform (launched 2024). Primary purpose: Enabling creators
        to monetize teaching their expertise. Focuses on direct
        creator-subscriber educational interactions. The platform hosts diverse
        content types including videos, text, live streams, and interactive
        content. Located and governed under US law (New York jurisdiction).
      </p>

      {/* Sección: User Categories */}
      <h3 className="mb-2 text-lg font-medium">User Categories</h3>
      <p className="mb-6 text-justify">
        <strong>Creators:</strong> Must be 18+ years old. Can build subscriber
        communities, set their own subscription fees and content pricing, create
        exclusive/locked content, offer memberships and direct messaging, and
        control content distribution. Responsible for content quality and
        accuracy. Must comply with FTC guidelines for endorsements/advertising.
      </p>
      <p className="mb-6 text-justify">
        <strong>Subscribers:</strong> Must be 18+ years old. Can follow creators
        and join communities, access exclusive content through paid memberships,
        engage in direct messaging with creators, purchase locked content,
        participate in creator communities, and receive creator notifications
        and updates.
      </p>

      {/* Sección: Content & Intellectual Property */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Content &amp; Intellectual Property
      </h2>
      <p className="mb-6 text-justify">
        <strong>Content Rights:</strong> Users retain ownership of their
        original content but grant Teachly a broad, worldwide license to use the
        content. This license includes the right to modify, distribute, and
        monetize content. The content license survives account termination.
        Teachly owns the platform features, design, and infrastructure.
      </p>
      <p className="mb-6 text-justify">
        <strong>Prohibited Content:</strong>
        <br />
        - Harmful/Illegal Content: Violence or threats, terrorist content,
        harmful instructions, illegal activities.
        <br />
        - Adult/Explicit Content: No nudity, no sexual content, no sexually
        suggestive material, no adult-oriented discussions.
        <br />
        - Deceptive Content: No impersonation, no misleading information, no
        fake engagement, no fraudulent claims.
        <br />- Protected Content: No copyright infringement, no trademark
        violations, no unauthorized use of others' IP, must respect publicity
        rights.
      </p>

      {/* Sección: Financial Terms & Payments */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Financial Terms &amp; Payments
      </h2>
      <p className="mb-6 text-justify">
        <strong>Subscription System:</strong> Auto-renewal by default, clear
        cancellation procedures, multiple membership tiers allowed, pricing set
        by creators, platform takes a commission (percentage not specified).
      </p>
      <p className="mb-6 text-justify">
        <strong>Payment Terms:</strong> All payments generally non-refundable,
        no pro-rata refunds for cancellations, multiple payment methods
        accepted, clear pricing disclosure required, currency in USD by default.
      </p>
      <p className="mb-6 text-justify">
        <strong>Monetization Features:</strong>
        <br />
        - Membership Subscriptions: Recurring payments, multiple tier options,
        customizable benefits, auto-renewal system.
        <br />
        - Direct Messaging: Pay-per-message options, bulk message packages, no
        guarantee of creator response, non-refundable.
        <br />- Locked Content: One-time purchases, access controls, creator-set
        pricing, platform-managed delivery.
      </p>

      {/* Sección: Privacy & Security */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Privacy &amp; Security
      </h2>
      <p className="mb-6 text-justify">
        <strong>Data Collection:</strong> Session replay software used, usage
        tracking implemented, analytics data gathered, user interaction
        monitoring.
      </p>
      <p className="mb-6 text-justify">
        <strong>Security Measures:</strong> Users must protect login
        credentials, two-factor authentication available, regular security
        updates, breach notification procedures.
      </p>
      <p className="mb-6 text-justify">
        <strong>Data Processing:</strong> US-based data storage, GDPR compliance
        measures, data encryption standards, regular security audits.
      </p>

      {/* Sección: Legal Framework & Dispute Resolution */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Legal Framework &amp; Dispute Resolution
      </h2>
      <p className="mb-6 text-justify">
        <strong>Governing Law:</strong> New York law applies, US jurisdiction,
        mandatory arbitration, individual claims only.
      </p>
      <p className="mb-6 text-justify">
        <strong>Dispute Process:</strong>
        <br />
        - Initial Resolution: 60-day informal resolution period, written notice
        required, good faith negotiation, direct communication first.
        <br />
        - Arbitration: Binding arbitration required, no class actions,
        individual claims only, AAA rules apply.
        <br />- Limitations: One-year filing deadline, limited damages
        available, no punitive damages, capped liability ($100 or earnings).
      </p>

      {/* Sección: Platform Rights & Responsibilities */}
      <h2 className="mb-3 text-center text-2xl font-semibold">
        Platform Rights &amp; Responsibilities
      </h2>
      <p className="mb-6 text-justify">
        <strong>Teachly's Rights:</strong> Modify service at will, remove
        content as needed, terminate accounts if necessary, update terms with
        notice.
      </p>
      <p className="mb-6 text-justify">
        <strong>Platform Limitations:</strong> No professional advice provided,
        no content quality guarantees, limited liability for user interactions,
        no responsibility for third-party content.
      </p>
      <p className="mb-6 text-justify">
        <strong>User Obligations:</strong> Accurate information provision,
        content quality maintenance, terms compliance, community guidelines
        adherence.
      </p>

      {/* Conclusión */}
      <p className="mt-8 text-justify">
        These terms establish a comprehensive framework for using Teachly while
        protecting both users and the platform. They emphasize content quality,
        user safety, and clear monetization rules while maintaining necessary
        legal protections and dispute resolution procedures. The terms reflect
        Teachly's focus on educational content and creator-subscriber
        relationships while maintaining platform integrity and user trust. They
        balance creator freedom with necessary restrictions to maintain a safe,
        productive learning environment.
      </p>
    </div>
  );
};

export default TermsOfServicePage;
