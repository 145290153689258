import { Path } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form';
import { FieldValues } from 'react-hook-form';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { cn } from '@/lib/utils';

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../ui/form';

export const FormInputSelect = <T extends FieldValues>({
  label,
  form,
  name,
  className,
  options,
  placeholder,
}: {
  label: string;
  form: UseFormReturn<T>;
  name: Path<T>;
  className?: string;
  options: { value: string; label: string }[];
  placeholder?: string;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('space-y-1', className)}>
          <FormLabel
            className={cn(
              'text-base font-medium text-black dark:text-white',
              fieldState.error && 'text-destructive dark:text-destructive',
            )}
          >
            {label}
          </FormLabel>
          <Select
            onValueChange={field.onChange}
            defaultValue={field.value}
            value={field.value}
          >
            <FormControl>
              <SelectTrigger
                className={cn(
                  'h-12 text-base text-black dark:text-white',
                  fieldState.error &&
                    'border-destructive dark:border-destructive',
                  !field.value && 'dark:!text-opacity-50',
                )}
              >
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
