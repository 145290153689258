import StatusMessage from '@/components/StatusMessage';
import { Button } from '@/components/ui/button';

const SuccessPage = () => {
  return (
    <div className="flex h-screen items-center justify-center bg-light-2 dark:bg-dark-2">
      <StatusMessage
        status="success"
        title="Spacegroup deleted"
        message={'The space group was deleted successfully'}
        bottomComponent={
          <Button>
            <a href="/">Go to home</a>
          </Button>
        }
      />
    </div>
  );
};

export default SuccessPage;
