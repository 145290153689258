import { z } from 'zod';

export type CreateNewSpaceGroupProps = {
  handleBack: () => void;
  onClose: () => void;
  provissionalSpaceGroupId: string;
};

export const permissions = ['OPEN', 'CLOSED'] as const;
export const pricingTypes = ['FREE', 'PAID'] as const;

export const formSchema = z.object({
  name: z.string().nonempty('Name is required'),
  permissions: z.enum(permissions),
  pricingType: z.enum(pricingTypes),
});

export type FormSchema = z.infer<typeof formSchema>;
