export const TrashAltIcon = (props) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13 4.16797L12.5869 10.8514C12.4813 12.5589 12.4285 13.4127 12.0005 14.0266C11.7889 14.33 11.5165 14.5862 11.2005 14.7786C10.5614 15.168 9.706 15.168 7.99513 15.168C6.28208 15.168 5.42553 15.168 4.78603 14.7779C4.46987 14.5851 4.19733 14.3285 3.98579 14.0245C3.55792 13.4097 3.5063 12.5547 3.40307 10.8448L3 4.16797"
      strokeLinecap="round"
    />
    <path
      d="M2 4.16536H14M10.7038 4.16536L10.2487 3.22652C9.9464 2.60287 9.7952 2.29104 9.53447 2.09657C9.47667 2.05343 9.4154 2.01506 9.35133 1.98183C9.0626 1.83203 8.71607 1.83203 8.023 1.83203C7.31253 1.83203 6.95733 1.83203 6.66379 1.98811C6.59873 2.0227 6.53665 2.06263 6.47819 2.10748C6.21443 2.30983 6.06709 2.63306 5.77241 3.27954L5.36861 4.16536"
      strokeLinecap="round"
    />
  </svg>
);
