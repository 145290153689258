import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';

import {
  CourseWithChaptersAndLessons,
  useCoursesSpacesControllerDeleteCourse,
  useCoursesSpacesControllerGetCourseWithLessonsByCourseId,
  useCoursesSpacesControllerTogglePublishCourse,
  useCoursesSpacesControllerUpdateCourse,
} from '@/api';
import { ArrowLeftAltIcon } from '@/assets/icon/arrowLeftAlt';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import ConfirmationDialog from '@/components/confirmation-dialog';
import { ImageDropzone } from '@/components/image-dropzone';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { imageToBase64 } from '@/lib/utils';

const EditCoursePage = () => {
  const { coursesSpaceId, brandId, spaceGroupId, courseId } = useParams();
  useUpdateConfigHeader(
    {
      showNotifications: true,
      label: 'Edit Course',
      action: null,
    },
    [],
  );

  const courseQuery = useCoursesSpacesControllerGetCourseWithLessonsByCourseId(
    courseId ?? '',
  );

  const course = courseQuery.data;

  if (courseQuery.isLoading) {
    return <Loading />;
  }

  if (!course) {
    throw new Error(
      `No course found for course id ${courseId}, ${courseQuery.error}`,
    );
  }

  return (
    <EditCourseInner
      course={course}
      brandId={brandId ?? ''}
      spaceGroupId={spaceGroupId ?? ''}
      coursesSpaceId={coursesSpaceId ?? ''}
      refetch={courseQuery.refetch}
    />
  );
};

const EditCourseInner = ({
  course,
  brandId,
  spaceGroupId,
  coursesSpaceId,
  refetch,
}: {
  course: CourseWithChaptersAndLessons;
  brandId: string;
  spaceGroupId: string;
  coursesSpaceId: string;
  refetch: () => void;
}) => {
  const [name, setName] = useState<string>();
  const [coverImage, setCoverImage] = useState<File | null>(null);
  const [isUsingOriginalImage, setIsUsingOriginalImage] =
    useState<boolean>(true);
  const [accessType, setAccessType] = useState<
    'BUY_NOW_UNLOCK' | 'TIME_UNLOCK' | 'FREE'
  >(course.accessType);
  const [pricing, setPricing] = useState<number | null>(null);
  const [daysToUnlock, setDaysToUnlock] = useState<number | null>(null);

  const [nameError, setNameError] = useState<string | null>(null);
  const [coverImageError, setCoverImageError] = useState<string | null>(null);

  const deleteCourseMutation = useCoursesSpacesControllerDeleteCourse();

  const togglePublishCourseMutation =
    useCoursesSpacesControllerTogglePublishCourse();

  useEffect(() => {
    setName(course.name);
    // setCoverImage(course.image)
    setAccessType(course.accessType);
    setPricing(course.price);
    setDaysToUnlock(course.unlockAfterDays);
  }, [course]);

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleCoverImage = (file: File | null) => {
    setCoverImage(file);
  };

  const handleRemoveCoverImage = () => {
    setCoverImage(null);
  };

  const handleRemoveOriginalImage = () => {
    setIsUsingOriginalImage(false);
  };

  const getImageUrl = () => {
    if (coverImage) {
      return URL.createObjectURL(coverImage);
    }
    return '';
  };

  const updateCourseMutation = useCoursesSpacesControllerUpdateCourse({
    mutation: {
      onSuccess: () => {
        navigate(
          `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`,
        );
      },
    },
  });

  const navigate = useNavigate();

  const handleGoBack = () => {
    if (!updateCourseMutation.isPending)
      navigate(
        `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`,
      );
  };

  const handleSaveAndContinue = async () => {
    const createCourseSchema = z
      .object({
        name: z.string().min(1, 'Name is required'),
        coverImage: z.instanceof(File).nullish(),
        isUsingOriginalImage: z.boolean(),
      })
      .refine(
        (data) => {
          if (data.isUsingOriginalImage) {
            return data.coverImage === null;
          }
          return data.coverImage !== null;
        },
        {
          message: 'Cover image is required',
          path: ['coverImage'],
        },
      );

    setNameError(null);
    setCoverImageError(null);

    const result = createCourseSchema.safeParse({
      name,
      coverImage,
      isUsingOriginalImage,
    });

    if (!result.success) {
      setNameError(result.error.formErrors.fieldErrors.name?.[0] ?? null);
      setCoverImageError(
        result.error.formErrors.fieldErrors.coverImage?.[0] ?? null,
      );

      return;
    }

    const data = result.data;

    const image = data?.coverImage
      ? await imageToBase64(data.coverImage)
      : undefined;

    if (
      data.name === course.name &&
      data.isUsingOriginalImage &&
      course.accessType === accessType &&
      course.price === pricing &&
      course.unlockAfterDays === daysToUnlock
    ) {
      handleGoBack();
      return;
    }

    updateCourseMutation.mutate({
      courseId: course.id,
      data: {
        name,
        image,
        accessType,
        price: pricing ?? undefined,
        unlockAfterDays: daysToUnlock ?? undefined,
      },
    });
  };

  const handleDeleteCourse = () => {
    try {
      deleteCourseMutation.mutate(
        {
          courseId: course.id,
        },
        {
          onSuccess: () => {
            navigate(
              `/brands/${brandId}/space-groups/${spaceGroupId}/courses-spaces/${coursesSpaceId}`,
            );
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleTogglePublish = () => {
    try {
      togglePublishCourseMutation.mutate(
        {
          courseId: course.id,
        },
        {
          onSuccess: () => {
            refetch();
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getImageInput = () => {
    if (isUsingOriginalImage && course.image) {
      return (
        <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
          <img src={course.image} className="w-80" />
          <Button
            variant="ghost"
            className="border border-light dark:border-dark-light"
            onClick={handleRemoveOriginalImage}
          >
            Remove image
          </Button>
        </div>
      );
    }

    if (coverImage) {
      return (
        <>
          <div className="flex flex-col items-center justify-center gap-6 space-y-2 rounded-lg border-2 border-dashed bg-[#F3F4F6] px-2 py-4 hover:border-muted-foreground/50 dark:bg-[#202224]">
            <img src={getImageUrl()} className="w-80" />
            <Button
              variant="ghost"
              className="border border-light dark:border-dark-light"
              onClick={handleRemoveCoverImage}
            >
              Replace image
            </Button>
          </div>
        </>
      );
    }

    return (
      <>
        <ImageDropzone
          onFileChange={handleCoverImage}
          infoText="JPEG, PNG, PDF, and MP4 formats, up to 50 MB."
          accept=".jpg, .jpeg, .png"
          ratio={3 / 2}
        />
        {coverImageError && <p className="text-red-500">{coverImageError}</p>}
      </>
    );
  };

  return (
    <div className="flex flex-col gap-6 overflow-auto px-6 py-10 xl:px-75 xl:py-16">
      <div
        onClick={handleGoBack}
        className="flex w-fit cursor-pointer items-center gap-2.5"
      >
        <ArrowLeftAltIcon className="h-6 w-6 stroke-black dark:stroke-white" />
        Back to course
      </div>
      <div className="flex flex-col gap-6 rounded-lg bg-light-2 p-6 dark:bg-dark-2">
        <h1 className="text-2xl font-semibold text-black dark:text-white">
          Course Setup
        </h1>
        <div className="flex flex-col gap-4">
          <h2 className="text-lg font-semibold text-black dark:text-white">
            Course name
          </h2>
          <InputField
            onChange={handleChangeName}
            type="text"
            value={name}
            placeholder="Ex.: Fitness Journey"
            inputClassName="!bg-transparent border border-light dark:border-dark-light"
            error={nameError ?? undefined}
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <h2 className="text-lg font-semibold text-black dark:text-white">
            Course access
          </h2>
          <div className="flex gap-1">
            <div
              className="flex w-full cursor-pointer flex-col gap-2.5 rounded-lg border border-light p-2.5 dark:border-dark-light"
              onClick={() => {
                if (accessType === 'BUY_NOW_UNLOCK') {
                  setAccessType('FREE');
                } else {
                  setAccessType('BUY_NOW_UNLOCK');
                }
              }}
            >
              <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-brand">
                {accessType === 'BUY_NOW_UNLOCK' && (
                  <div className="h-3 w-3 flex-shrink-0 rounded-full border border-transparent bg-brand" />
                )}
              </div>
              <div className="flex h-[53px] w-full flex-col gap-1">
                <h3 className="text-sm font-medium">Buy Now Unlock</h3>
                <p className="text-xs text-textParagraph dark:text-dark-textParagraph">
                  Members pay a one-time price to unlock
                </p>
              </div>
            </div>
            <div
              className="flex w-full cursor-pointer flex-col gap-2.5 rounded-lg border border-light p-2.5 dark:border-dark-light"
              onClick={() => {
                if (accessType === 'TIME_UNLOCK') {
                  setAccessType('FREE');
                } else {
                  setAccessType('TIME_UNLOCK');
                }
              }}
            >
              <div className="flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-brand">
                {accessType === 'TIME_UNLOCK' && (
                  <div className="h-3 w-3 flex-shrink-0 rounded-full border border-transparent bg-brand" />
                )}
              </div>
              <div className="flex h-[53px] w-full flex-col gap-1">
                <h3 className="text-sm font-medium">Time Unlock</h3>
                <p className="text-xs text-textParagraph dark:text-dark-textParagraph">
                  Members unlock after X Days
                </p>
              </div>
            </div>
          </div>
        </div>
        {accessType === 'BUY_NOW_UNLOCK' && (
          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold text-black dark:text-white">
              Pricing
            </h2>
            <InputField
              onChange={(e) => setPricing(Number(e.target.value))}
              type="number"
              value={pricing || 0}
              placeholder="$"
              inputClassName="!bg-transparent border border-light dark:border-dark-light"
              startChar="$"
            />
          </div>
        )}
        {accessType === 'TIME_UNLOCK' && (
          <div className="flex flex-col gap-4">
            <h2 className="text-lg font-semibold text-black dark:text-white">
              Unlock after
            </h2>
            <InputField
              onChange={(e) => setDaysToUnlock(Number(e.target.value))}
              type="number"
              value={daysToUnlock || 0}
              inputClassName="!bg-transparent border border-light dark:border-dark-light"
              endChar="days"
            />
          </div>
        )}
        <div className="flex w-full flex-col gap-6">
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold text-black dark:text-white">
              Cover Image
            </h2>
            <p className="text-textParagraph dark:text-dark-textParagraph">
              Select an image for your course
            </p>
          </div>
          {getImageInput()}
        </div>
        <div className="flex items-center justify-between text-lg font-semibold">
          Publish course
          <Switch
            checked={course.isPublished}
            onCheckedChange={handleTogglePublish}
            disabled={togglePublishCourseMutation.isPending}
          />
        </div>
        <div className="flex w-full justify-end gap-3.5">
          <ConfirmationDialog
            title="Delete course"
            description="Are you sure you want to delete this course? This action cannot be undone and all the chapters and lessons will be erased."
            onConfirm={handleDeleteCourse}
            trigger={<Button variant={'destructive'}>Delete course</Button>}
            disableButtons={deleteCourseMutation.isPending}
          />
          <Button
            onClick={handleSaveAndContinue}
            disabled={updateCourseMutation.isPending}
          >
            Save and continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditCoursePage;
