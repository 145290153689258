import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { XIcon } from 'lucide-react';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { usePriceVariantControllerCreatePriceVariant } from '@/api/generated/api/price-variants/price-variants';
import { usePriceVariantControllerUpdatePriceVariant } from '@/api/generated/api/price-variants/price-variants';
import { ArrowLeftIcon } from '@/assets/icon/arrowLeft';
import { FormInput, FormInputSelect } from '@/components/design-system';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
} from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Form } from '@/components/ui/form';
import { Switch } from '@/components/ui/switch';
import { useToast } from '@/hooks/use-toast';
import { onlyFirstLetterCapital } from '@/lib/utils';

import {
  parseFormResponseToCreatePriceVariantDto,
  parseFormResponseToUpdatePriceVariantDto,
} from './CreatePricingVariant.helper';
import {
  FormSchema,
  billingFrequencies,
  formSchema,
  revoqueAccessAfterOptions,
} from './CreatePricingVariant.types';

import { useCreateNewSpaceGroupStore } from '../../CreateNew.store';

// Array de opciones para el input select de billing frequency
const billingFrequencyOptions = billingFrequencies.map((billingFrequency) => ({
  value: billingFrequency,
  label: `${onlyFirstLetterCapital(billingFrequency)} Payment`,
}));

interface PriceVariantDto {
  id?: string;
  name?: string;
  billingType?: 'ONE_TIME_PAYMENT' | 'RECURRING';
  recurringInterval?: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
  intervalCount?: number;
  price?: number;
  revokeAccessPeriod?:
    | 'NEVER'
    | 'DAY_7'
    | 'MONTH_1'
    | 'MONTH_3'
    | 'MONTH_6'
    | 'YEAR_1'
    | 'CUSTOM';
  subscriptionPeriod?: 'DAY_7' | 'MONTH_1' | 'MONTH_3' | 'MONTH_6' | 'YEAR_1';
  numberOfSplitPayments?: number;
  splitPaymentPeriod?: 'WEEK' | 'MONTH';
}

interface CreatePricingVariantProps {
  provvisionalSpaceGroupId: string;
  onClose?: () => void;
  onBack?: () => void;
  mode?: 'create' | 'edit';
  variantToEdit?: PriceVariantDto; // si estamos en modo edición
}

function mapBackendRevokePeriodToForm(
  backendValue:
    | 'DAY_7'
    | 'MONTH_1'
    | 'MONTH_3'
    | 'MONTH_6'
    | 'YEAR_1'
    | 'NEVER'
    | 'CUSTOM',
):
  | 'NEVER'
  | 'AFTER SEVEN DAYS'
  | 'AFTER ONE MONTH'
  | 'AFTER THREE MONTHS'
  | 'AFTER SIX MONTHS'
  | 'AFTER ONE YEAR'
  | 'AFTER OTHER # OF DAYS' {
  switch (backendValue) {
    case 'DAY_7':
      return 'AFTER SEVEN DAYS';
    case 'MONTH_1':
      return 'AFTER ONE MONTH';
    case 'MONTH_3':
      return 'AFTER THREE MONTHS';
    case 'MONTH_6':
      return 'AFTER SIX MONTHS';
    case 'YEAR_1':
      return 'AFTER ONE YEAR';
    case 'CUSTOM':
      return 'AFTER OTHER # OF DAYS';
    case 'NEVER':
    default:
      return 'NEVER';
  }
}

export const CreatePricingVariant: React.FC<CreatePricingVariantProps> = ({
  provvisionalSpaceGroupId,
  onClose,
  onBack,
  mode = 'create',
  variantToEdit,
}) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();

  // Importamos del store las funciones originales
  const {
    handleBack: defaultHandleBack,
    setIsDialogOpen: defaultSetIsDialogOpen,
    setStep,
  } = useCreateNewSpaceGroupStore();

  // Creamos handlers que usen onClose/onBack si existen; de lo contrario, usan la lógica del store
  const handleClose = () => {
    if (onClose) {
      onClose();
    } else {
      defaultSetIsDialogOpen(false);
    }
  };

  const handleBackAction = () => {
    if (onBack) {
      onBack();
    } else {
      defaultHandleBack();
    }
  };

  // 1) Definimos valores por defecto si estamos creando
  const defaultValuesForCreate: FormSchema = {
    variantName: '',
    billingFrequency: undefined,
    oneTimePrice: '',
    revoqueAccessAfter: 'NEVER',
    recurringPrice: '',
    subscriptionPeriod: undefined,
    enableSplitPayments: false,
    splitPayments: 0,
    revoqueAccessAfterDays: 0,
    paymentFrequency: 'MONTH',
  };

  // 2) Definimos valores por defecto si estamos editando
  const defaultValuesForEdit: FormSchema = {
    variantName: variantToEdit?.name || '',
    billingFrequency:
      variantToEdit?.billingType === 'ONE_TIME_PAYMENT'
        ? 'ONE TIME'
        : variantToEdit?.billingType === 'RECURRING'
          ? 'RECURRING'
          : undefined,
    oneTimePrice:
      variantToEdit?.billingType === 'ONE_TIME_PAYMENT'
        ? String(variantToEdit?.price ?? '')
        : '',
    recurringPrice:
      variantToEdit?.billingType === 'RECURRING'
        ? String(variantToEdit?.price ?? '')
        : '',
    subscriptionPeriod:
      variantToEdit?.billingType === 'RECURRING'
        ? variantToEdit?.subscriptionPeriod
        : undefined,
    revoqueAccessAfter: variantToEdit?.revokeAccessPeriod
      ? mapBackendRevokePeriodToForm(variantToEdit.revokeAccessPeriod)
      : 'NEVER',
    enableSplitPayments: false,
    splitPayments: 0,
    paymentFrequency: variantToEdit?.splitPaymentPeriod ?? 'MONTH',
    revoqueAccessAfterDays: 0,
  };

  // Si la variante es de pago único y tiene numberOfSplitPayments > 1, habilitar split payments
  if (
    variantToEdit?.billingType === 'ONE_TIME_PAYMENT' &&
    variantToEdit.numberOfSplitPayments &&
    variantToEdit.numberOfSplitPayments > 1
  ) {
    defaultValuesForEdit.enableSplitPayments = true;
    defaultValuesForEdit.splitPayments = variantToEdit.numberOfSplitPayments;
    defaultValuesForEdit.paymentFrequency =
      variantToEdit.splitPaymentPeriod === 'WEEK' ? 'WEEK' : 'MONTH';
  }

  // 3) Elegimos los valores iniciales según el modo
  const initialValues =
    mode === 'edit' ? defaultValuesForEdit : defaultValuesForCreate;

  // useForm
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: initialValues,
  });

  // Para el caso en que reciba variantToEdit más tarde (si fuese asíncrono), re-sincronizamos:
  useEffect(() => {
    if (mode === 'edit' && variantToEdit) {
      form.reset(defaultValuesForEdit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantToEdit, mode]);

  // Hooks de create y update
  const createPriceVariantMutation =
    usePriceVariantControllerCreatePriceVariant();
  const updatePriceVariantMutation =
    usePriceVariantControllerUpdatePriceVariant();

  const subscriptionPeriodOptions = [
    { value: 'DAY_7', label: '7 days payment' },
    { value: 'MONTH_1', label: '1 month payment' },
    { value: 'MONTH_3', label: '3 months payment' },
    { value: 'MONTH_6', label: '6 months payment' },
    { value: 'YEAR_1', label: '1 year payment' },
  ];

  // Permite solo números y punto decimal en los campos de precio
  const onPriceChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    priceInput: 'oneTimePrice' | 'recurringPrice',
  ) => {
    const value = e.target.value;

    // Allow empty string, digits, and at most one decimal point
    const isValidPrice = value === '' || /^\d*\.?\d*$/.test(value);

    if (isValidPrice) {
      form.setValue(priceInput, value);
      form.clearErrors(priceInput);
    } else {
      // Don't update the form value for invalid input
      e.preventDefault();
    }
  };

  // Lógica para crear o actualizar
  const onSaveVariant = async () => {
    console.log('onSaveVariant');
    console.log('formValues', form.getValues());
    if (!provvisionalSpaceGroupId) {
      toast({
        title: 'Space group not found',
        description: 'Please create a space group first.',
        variant: 'destructive',
      });
      return;
    }

    // Validate that prices are greater than zero and are valid numbers
    const oneTimePrice = form.getValues('oneTimePrice');
    const recurringPrice = form.getValues('recurringPrice');
    const billingFrequency = form.getValues('billingFrequency');

    if (
      (billingFrequency === 'ONE TIME' &&
        (isNaN(Number(oneTimePrice)) || Number(oneTimePrice) <= 0)) ||
      (billingFrequency === 'RECURRING' &&
        (isNaN(Number(recurringPrice)) || Number(recurringPrice) <= 0))
    ) {
      toast({
        title: 'Price validation error',
        description: 'Prices must be valid numbers greater than zero.',
        variant: 'destructive',
      });
      return;
    }

    // Validate form before submission
    const isValid = await form.trigger();
    if (!isValid) {
      toast({
        title: 'Validation error',
        description: 'Please fill in all required fields correctly.',
        variant: 'destructive',
      });
      return;
    }

    const formValues = form.getValues();
    console.log('formValues', formValues);

    // Additional validation for specific billing frequency
    if (
      formValues.billingFrequency === 'ONE TIME' &&
      !formValues.oneTimePrice
    ) {
      toast({
        title: 'Validation error',
        description: 'Please enter a price for the one-time payment.',
        variant: 'destructive',
      });
      return;
    }

    if (formValues.billingFrequency === 'RECURRING') {
      if (!formValues.recurringPrice) {
        toast({
          title: 'Validation error',
          description: 'Please enter a price for the recurring payment.',
          variant: 'destructive',
        });
        return;
      }
      if (!formValues.subscriptionPeriod) {
        toast({
          title: 'Validation error',
          description: 'Please select a subscription period.',
          variant: 'destructive',
        });
        return;
      }
    }

    // Validate that we have a valid frequency when split payments is enabled
    if (
      formValues.enableSplitPayments &&
      (!formValues.paymentFrequency ||
        !['MONTH', 'WEEK'].includes(formValues.paymentFrequency))
    ) {
      toast({
        title: 'Validation error',
        description: 'Please select a payment frequency for split payments.',
        variant: 'destructive',
      });
      return;
    }

    try {
      if (mode === 'edit' && variantToEdit) {
        // EDITAR
        const updateDto = parseFormResponseToUpdatePriceVariantDto(formValues);

        await updatePriceVariantMutation.mutateAsync({
          spaceGroupId: provvisionalSpaceGroupId,
          variantId: variantToEdit.id as string,
          data: updateDto,
        });

        toast({
          title: 'Payment link updated successfully',
          description: 'Your payment link was updated successfully.',
          variant: 'success',
        });
        handleBackAction();
      } else {
        // CREAR
        const priceVariant = parseFormResponseToCreatePriceVariantDto(
          provvisionalSpaceGroupId,
          formValues,
        );

        await createPriceVariantMutation.mutateAsync(priceVariant);

        toast({
          title: 'Payment link created successfully',
          description: 'Your payment link was created successfully.',
          variant: 'success',
        });
        handleBackAction();
      }

      // Invalidate and refetch price variants to ensure data is fresh
      await queryClient.invalidateQueries({
        queryKey: ['priceVariants', provvisionalSpaceGroupId],
      });

      // Only set the step back to create-new-space-group
      // Do NOT call onClose or handleClose as that would close the entire dialog
      setStep('create-new-space-group');

      // Reset the form for a clean state
      form.reset(defaultValuesForCreate);
    } catch (error: any) {
      const actionName = mode === 'edit' ? 'updating' : 'creating';
      toast({
        title: `Error ${actionName} pricing variant`,
        description: error?.message || 'Something went wrong.',
        variant: 'destructive',
      });
    }
  };

  const shouldShowOneTimePaymentInputs =
    form.watch('billingFrequency') === 'ONE TIME';
  const shouldShowRecurringPaymentInputs =
    form.watch('billingFrequency') === 'RECURRING';

  return (
    <>
      <DialogHeader className="flex-row items-center justify-between space-y-0">
        <DialogTitle className="flex items-center gap-2.5 text-2xl font-semibold">
          <Button variant="icon" size="icon" onClick={handleBackAction}>
            <ArrowLeftIcon className="h-6 w-6 cursor-pointer fill-black dark:fill-white" />
          </Button>
          {mode === 'edit' ? 'Edit Payment Link' : 'Add Payment Link'}
        </DialogTitle>
        <Button
          onClick={handleClose}
          variant="icon"
          size="icon"
          className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <XIcon className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </Button>
      </DialogHeader>

      <Form {...form}>
        <form>
          <Accordion
            type="multiple"
            defaultValue={['general', 'pricing']}
            className="flex flex-col gap-4"
          >
            <AccordionItem
              value="general"
              className="rounded-[10px] border-none"
            >
              <AccordionContent className="mx-1 mb-4 mt-8 flex flex-col gap-8">
                <FormInput
                  form={form}
                  label="Payment link name"
                  name="variantName"
                  placeholder="Enter payment link name"
                />
              </AccordionContent>
            </AccordionItem>
            <FormInputSelect
              form={form}
              name="billingFrequency"
              label="Billing frequency"
              placeholder="Select billing duration"
              options={billingFrequencyOptions}
            />

            {shouldShowOneTimePaymentInputs && (
              <>
                <FormInput
                  form={form}
                  name="oneTimePrice"
                  label="Price"
                  placeholder="Enter price"
                  prefix="$"
                  onChange={(e) => onPriceChange(e, 'oneTimePrice')}
                />

                <FormInputSelect
                  form={form}
                  name="revoqueAccessAfter"
                  label="Revoke access after"
                  placeholder="Select duration"
                  options={revoqueAccessAfterOptions.map((option) => ({
                    value: option,
                    label: onlyFirstLetterCapital(option),
                  }))}
                />

                <section className="flex flex-col gap-2 rounded-lg border border-gray-700 p-4">
                  <div className="flex flex-row justify-between">
                    <h2>Enable Split Payments</h2>
                    <Switch
                      checked={form.watch('enableSplitPayments')}
                      onCheckedChange={(checked) =>
                        form.setValue('enableSplitPayments', checked)
                      }
                    />
                  </div>
                  {form.watch('enableSplitPayments') && (
                    <>
                      <p className="text-sm text-gray-500">
                        After the customer makes the number of payments (minimum
                        2), they will stop being charged but will retain access
                        to their membership.
                      </p>
                      <FormInput
                        form={form}
                        name="splitPayments"
                        placeholder="Enter number of payments"
                        type="number"
                      />

                      <p className="text-sm text-gray-500">
                        Frequency of payments
                      </p>
                      <div className="flex items-center gap-2">
                        {['MONTH', 'WEEK'].map((frequency) => {
                          const current =
                            form.watch('paymentFrequency') || 'MONTH';
                          const isActive = current === frequency;
                          return (
                            <Button
                              key={frequency}
                              type="button"
                              variant={isActive ? 'default' : 'outline'}
                              onClick={() =>
                                form.setValue(
                                  'paymentFrequency',
                                  frequency as 'WEEK' | 'MONTH',
                                )
                              }
                            >
                              {frequency.charAt(0).toUpperCase() +
                                frequency.slice(1)}
                            </Button>
                          );
                        })}
                      </div>
                    </>
                  )}
                </section>

                {form.watch('revoqueAccessAfter') ===
                  'AFTER OTHER # OF DAYS' && (
                  <FormInput
                    form={form}
                    name="revoqueAccessAfterDays"
                    label="Days"
                    placeholder="Enter days"
                    type="number"
                  />
                )}
              </>
            )}

            {shouldShowRecurringPaymentInputs && (
              <>
                <FormInput
                  form={form}
                  name="recurringPrice"
                  label="Price"
                  placeholder="Enter price"
                  prefix="$"
                  onChange={(e) => onPriceChange(e, 'recurringPrice')}
                />

                <FormInputSelect
                  form={form}
                  name="subscriptionPeriod"
                  label="Subscription period"
                  placeholder="Select subscription period"
                  options={subscriptionPeriodOptions}
                />
              </>
            )}
          </Accordion>

          <Button
            type="button"
            className="mt-8 w-full rounded-lg"
            onClick={onSaveVariant}
          >
            Save and continue
          </Button>
        </form>
      </Form>
    </>
  );
};
