import { XIcon } from 'lucide-react';
import { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

type Props = {
  onClose?: () => void;
  onCancel?: () => void;
  onConfirm: () => void;
  title: string;
  description: React.ReactNode | string;
  confirmText?: string;
  cancelText?: string;
  trigger: React.ReactNode | string;
  disableButtons?: boolean;
};

const ConfirmationDialog = ({
  description,
  onCancel,
  onClose,
  onConfirm,
  title,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  trigger,
  disableButtons = false,
}: Props) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{trigger}</DialogTrigger>
      <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
        <DialogHeader className="flex-row items-center justify-between space-y-0">
          <DialogTitle className="text-2xl font-semibold">{title}</DialogTitle>
          <Button
            onClick={() => {
              setOpen(false);
              onClose?.();
            }}
            variant="icon"
            size="icon"
            className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <DialogDescription className="font-medium text-[#9E9E9E] dark:text-[#AEAEAE]">
          {description}
        </DialogDescription>
        <DialogFooter className="flex flex-row justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
              onCancel?.();
            }}
            type="button"
          >
            {cancelText}
          </Button>
          <Button
            variant="destructive"
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            type="button"
            disabled={disableButtons}
          >
            {confirmText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
