/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  AutomationResponseDto,
  CreateAutomationDto,
  UpdateAutomationDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new automation with a trigger, actions, and associated space groups.
 * @summary Create a new automation
 */
export const automationsControllerCreate = (
  createAutomationDto: CreateAutomationDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<unknown>(
    {
      url: `/automations`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createAutomationDto,
      signal,
    },
    options,
  );
};

export const getAutomationsControllerCreateMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerCreate>>,
    TError,
    { data: CreateAutomationDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automationsControllerCreate>>,
  TError,
  { data: CreateAutomationDto },
  TContext
> => {
  const mutationKey = ['automationsControllerCreate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automationsControllerCreate>>,
    { data: CreateAutomationDto }
  > = (props) => {
    const { data } = props ?? {};

    return automationsControllerCreate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomationsControllerCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerCreate>>
>;
export type AutomationsControllerCreateMutationBody = CreateAutomationDto;
export type AutomationsControllerCreateMutationError = void;

/**
 * @summary Create a new automation
 */
export const useAutomationsControllerCreate = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerCreate>>,
    TError,
    { data: CreateAutomationDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof automationsControllerCreate>>,
  TError,
  { data: CreateAutomationDto },
  TContext
> => {
  const mutationOptions =
    getAutomationsControllerCreateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves a list of all automations.
 * @summary Get all automations
 */
export const automationsControllerFindAll = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<AutomationResponseDto[]>(
    { url: `/automations`, method: 'GET', signal },
    options,
  );
};

export const getAutomationsControllerFindAllQueryKey = () => {
  return [`/automations`] as const;
};

export const getAutomationsControllerFindAllQueryOptions = <
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAutomationsControllerFindAllQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof automationsControllerFindAll>>
  > = ({ signal }) => automationsControllerFindAll(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof automationsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AutomationsControllerFindAllQueryResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerFindAll>>
>;
export type AutomationsControllerFindAllQueryError = void;

export function useAutomationsControllerFindAll<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof automationsControllerFindAll>>,
        TError,
        Awaited<ReturnType<typeof automationsControllerFindAll>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindAll<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof automationsControllerFindAll>>,
        TError,
        Awaited<ReturnType<typeof automationsControllerFindAll>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAutomationsControllerFindAll<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all automations
 */

export function useAutomationsControllerFindAll<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAutomationsControllerFindAllQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all automations
 */
export const prefetchAutomationsControllerFindAll = async <
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindAll>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAutomationsControllerFindAllQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getAutomationsControllerFindAllSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAutomationsControllerFindAllQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof automationsControllerFindAll>>
  > = ({ signal }) => automationsControllerFindAll(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof automationsControllerFindAll>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AutomationsControllerFindAllSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerFindAll>>
>;
export type AutomationsControllerFindAllSuspenseQueryError = void;

export function useAutomationsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all automations
 */

export function useAutomationsControllerFindAllSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindAll>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof automationsControllerFindAll>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getAutomationsControllerFindAllSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Retrieves the details of a specific automation by ID.
 * @summary Get one automation
 */
export const automationsControllerFindOne = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<unknown>(
    {
      url: `/automations/${encodeURIComponent(String(id))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getAutomationsControllerFindOneQueryKey = (id: string) => {
  return [`/automations/${id}`] as const;
};

export const getAutomationsControllerFindOneQueryOptions = <
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAutomationsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof automationsControllerFindOne>>
  > = ({ signal }) => automationsControllerFindOne(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof automationsControllerFindOne>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AutomationsControllerFindOneQueryResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerFindOne>>
>;
export type AutomationsControllerFindOneQueryError = void;

export function useAutomationsControllerFindOne<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof automationsControllerFindOne>>,
          TError,
          Awaited<ReturnType<typeof automationsControllerFindOne>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindOne<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof automationsControllerFindOne>>,
          TError,
          Awaited<ReturnType<typeof automationsControllerFindOne>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useAutomationsControllerFindOne<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get one automation
 */

export function useAutomationsControllerFindOne<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getAutomationsControllerFindOneQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get one automation
 */
export const prefetchAutomationsControllerFindOne = async <
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getAutomationsControllerFindOneQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getAutomationsControllerFindOneSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAutomationsControllerFindOneQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof automationsControllerFindOne>>
  > = ({ signal }) => automationsControllerFindOne(id, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof automationsControllerFindOne>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type AutomationsControllerFindOneSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerFindOne>>
>;
export type AutomationsControllerFindOneSuspenseQueryError = void;

export function useAutomationsControllerFindOneSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindOneSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useAutomationsControllerFindOneSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get one automation
 */

export function useAutomationsControllerFindOneSuspense<
  TData = Awaited<ReturnType<typeof automationsControllerFindOne>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof automationsControllerFindOne>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getAutomationsControllerFindOneSuspenseQueryOptions(
    id,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates a specific automation by ID with the provided data.
 * @summary Update an existing automation
 */
export const automationsControllerUpdate = (
  id: string,
  updateAutomationDto: UpdateAutomationDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<unknown>(
    {
      url: `/automations/${encodeURIComponent(String(id))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateAutomationDto,
    },
    options,
  );
};

export const getAutomationsControllerUpdateMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerUpdate>>,
    TError,
    { id: string; data: UpdateAutomationDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automationsControllerUpdate>>,
  TError,
  { id: string; data: UpdateAutomationDto },
  TContext
> => {
  const mutationKey = ['automationsControllerUpdate'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automationsControllerUpdate>>,
    { id: string; data: UpdateAutomationDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return automationsControllerUpdate(id, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomationsControllerUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerUpdate>>
>;
export type AutomationsControllerUpdateMutationBody = UpdateAutomationDto;
export type AutomationsControllerUpdateMutationError = void;

/**
 * @summary Update an existing automation
 */
export const useAutomationsControllerUpdate = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerUpdate>>,
    TError,
    { id: string; data: UpdateAutomationDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof automationsControllerUpdate>>,
  TError,
  { id: string; data: UpdateAutomationDto },
  TContext
> => {
  const mutationOptions =
    getAutomationsControllerUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a specific automation by ID.
 * @summary Delete an automation
 */
export const automationsControllerRemove = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<unknown>(
    { url: `/automations/${encodeURIComponent(String(id))}`, method: 'DELETE' },
    options,
  );
};

export const getAutomationsControllerRemoveMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerRemove>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof automationsControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationKey = ['automationsControllerRemove'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof automationsControllerRemove>>,
    { id: string }
  > = (props) => {
    const { id } = props ?? {};

    return automationsControllerRemove(id, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type AutomationsControllerRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof automationsControllerRemove>>
>;

export type AutomationsControllerRemoveMutationError = void;

/**
 * @summary Delete an automation
 */
export const useAutomationsControllerRemove = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof automationsControllerRemove>>,
    TError,
    { id: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof automationsControllerRemove>>,
  TError,
  { id: string },
  TContext
> => {
  const mutationOptions =
    getAutomationsControllerRemoveMutationOptions(options);

  return useMutation(mutationOptions);
};
