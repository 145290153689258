export const WorkFlowIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.5 4.16797C7.5 5.54868 6.38071 6.66797 5 6.66797C3.61929 6.66797 2.5 5.54868 2.5 4.16797C2.5 2.78726 3.61929 1.66797 5 1.66797C6.38071 1.66797 7.5 2.78726 7.5 4.16797Z"
      stroke-width="1.5"
    />
    <path
      d="M17.5 11.668C17.5 13.0487 16.3807 14.168 15 14.168C13.6192 14.168 12.5 13.0487 12.5 11.668C12.5 10.2872 13.6192 9.16797 15 9.16797C16.3807 9.16797 17.5 10.2872 17.5 11.668Z"
      stroke-width="1.5"
    />
    <path
      d="M7.5 15.832C7.5 17.2128 6.38071 18.332 5 18.332C3.61929 18.332 2.5 17.2128 2.5 15.832C2.5 14.4513 3.61929 13.332 5 13.332C6.38071 13.332 7.5 14.4513 7.5 15.832Z"
      stroke-width="1.5"
    />
    <path
      d="M5 6.66797V13.3346"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5 11.668H10C7.23857 11.668 5 9.42939 5 6.66797"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
