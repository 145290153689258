export const LightningIcon = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M13.4859 7.38703C13.467 7.30689 13.4286 7.23267 13.374 7.17096C13.3195 7.10924 13.2506 7.06195 13.1734 7.03328L9.57279 5.68265L10.489 1.09953C10.5098 0.99304 10.4953 0.882694 10.4479 0.78514C10.4004 0.687587 10.3225 0.608118 10.2259 0.558728C10.1293 0.509337 10.0193 0.492703 9.91239 0.511337C9.80552 0.529971 9.7076 0.582861 9.63342 0.662027L2.63342 8.16203C2.57657 8.22193 2.53544 8.29499 2.51371 8.37467C2.49198 8.45435 2.49033 8.53817 2.5089 8.61865C2.52747 8.69912 2.56569 8.77374 2.62013 8.83584C2.67458 8.89795 2.74356 8.94559 2.82092 8.97453L6.42279 10.3252L5.50904 14.9033C5.4883 15.0098 5.50275 15.1201 5.55021 15.2177C5.59768 15.3152 5.67559 15.3947 5.77219 15.4441C5.86878 15.4935 5.97882 15.5101 6.08569 15.4915C6.19257 15.4728 6.29049 15.4199 6.36467 15.3408L13.3647 7.84078C13.4205 7.78086 13.4607 7.70814 13.4819 7.62902C13.503 7.54991 13.5044 7.46681 13.4859 7.38703ZM6.83467 13.3764L7.48904 10.1027C7.51246 9.98655 7.49391 9.8659 7.43668 9.7622C7.37945 9.6585 7.28726 9.57848 7.17654 9.5364L3.87404 8.29578L9.16279 2.62953L8.50904 5.90328C8.48562 6.01938 8.50418 6.14003 8.56141 6.24373C8.61864 6.34743 8.71083 6.42745 8.82154 6.46953L12.1215 7.70703L6.83467 13.3764Z" />
  </svg>
);
