import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { ArrowRightFilledIcon } from '@/assets/icon/arrowRightFilled';
// import { ArrowRightFilledIcon } from '@/assets/icon/arrowRightFilled';
import { BarChartIcon } from '@/assets/icon/bar-chart';
import { BrushIcon } from '@/assets/icon/brush';
// import { BrushIcon } from '@/assets/icon/brush';
import { HomeIcon } from '@/assets/icon/home';
import { MailReceiveIcon } from '@/assets/icon/mail-receive';
import { MembersIcon } from '@/assets/icon/members';
import { MoneyReceiveIcon } from '@/assets/icon/money-receive';
import { WorkFlowIcon } from '@/assets/icon/workFlow';
import { useBrandPermits } from '@/hooks/useBrandPermits';
import { cn } from '@/lib/utils';
import { useInboxCount } from '@/stores/inboxCount';

// import { LinkIcon } from '@/assets/icon/link';
// import { MailReceiveIcon } from '@/assets/icon/mail-receive';
// import { MembersIcon } from '@/assets/icon/members';
// import { SparklesIcon } from '@/assets/icon/sparkles';

const items = [
  {
    title: 'Dashboard',
    brandPermissionOnly: true,
    icon: (
      <HomeIcon className="h-5 min-w-5 stroke-light dark:stroke-dark-light-gray" />
    ),
    type: 'dropdown',
    content: [
      {
        title: 'Analytics',
        icon: (
          <BarChartIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
        ),
        link: (brandId: string) => `/brands/${brandId}/analytics`,
      },
      // {
      //   title: 'Landing page',
      //   icon: (
      //     <SparklesIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
      //   ),
      //   link: '/',
      // },
      {
        title: 'Members',
        icon: (
          <MembersIcon className="h-5 min-w-5 fill-none stroke-black dark:stroke-white" />
        ),
        link: (brandId: string) => `/brands/${brandId}/members`,
      },
      {
        title: 'Branding',
        icon: (
          <BrushIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
        ),
        link: (brandId: string) => `/brands/${brandId}/branding`,
      },
      // {
      //   title: 'Links',
      //   icon: (
      //     <LinkIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
      //   ),
      //   link: '/',
      // },
      {
        title: 'Payout',
        icon: (
          <MoneyReceiveIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
        ),
        link: (brandId: string) => `/brands/${brandId}/payouts`,
      },
      {
        title: 'Workflow',
        icon: (
          <WorkFlowIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
        ),
        link: (brandId: string) => `/brands/${brandId}/workflow`,
      },
    ],
  },
] as const;

const CreatorPanel = ({ brandId }: { brandId?: string }) => {
  const hasBrandPermission = useBrandPermits();
  const [itemSelected, setItemSelected] = useState<string>('');

  const handleOpenItem = (itemTitle: string) => {
    if (itemSelected === itemTitle) {
      setItemSelected('');
    } else {
      setItemSelected(itemTitle);
    }
  };

  const itemsFiltered = items.filter((item) => {
    if (item.brandPermissionOnly) {
      return brandId && hasBrandPermission;
    }
    return true;
  });

  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-light-2 p-2.5 dark:bg-dark-2">
      {itemsFiltered.map((item) => {
        return (
          <div key={item.title} className="flex w-full flex-col gap-1">
            <div
              className="flex cursor-pointer items-center gap-2 p-2.5"
              onClick={() => handleOpenItem(item.title)}
            >
              {item.icon}
              <div className="w-full text-textParagraph dark:text-dark-textParagraph">
                {item.title}
              </div>
              {item.type === 'dropdown' && (
                <ArrowRightFilledIcon
                  className={`min-h-4 min-w-4 fill-black dark:fill-white ${itemSelected === item.title ? 'rotate-270' : 'rotate-90'}`}
                />
              )}
            </div>
            {itemSelected === item.title &&
              brandId &&
              item.type === 'dropdown' && (
                <div className="flex flex-col gap-2 rounded-lg bg-light-3 p-[5px] dark:bg-dark-3">
                  {item.content?.map((subItem) => (
                    <NavLink
                      key={subItem.title}
                      className={({ isActive }) =>
                        `flex cursor-pointer items-center gap-2.5 rounded-lg p-2.5 ${isActive ? 'bg-light-4 dark:bg-dark-4' : 'opacity-50'}`
                      }
                      to={subItem.link(brandId) || ''}
                    >
                      {subItem.icon}
                      {subItem.title}
                    </NavLink>
                  ))}
                </div>
              )}
          </div>
        );
      })}
      <InboxButton brandId={brandId} />
    </div>
  );
};

export default CreatorPanel;

const InboxButton = ({ brandId }: { brandId?: string }) => {
  const inboxCount = useInboxCount();

  const link = brandId ? `/brands/${brandId}/chats` : '/chats';

  return (
    <NavLink
      className={({ isActive }) =>
        `flex w-full flex-col gap-1 rounded-lg ${isActive ? 'bg-light-3 text-black dark:bg-dark-3 dark:text-white' : ''}`
      }
      to={link}
    >
      {({ isActive }) => (
        <div className="flex cursor-pointer items-center gap-2 p-2.5">
          <div className={cn('h-5 min-w-5', !isActive && 'opacity-50')}>
            <MailReceiveIcon className="h-5 min-w-5 stroke-black dark:stroke-white" />
          </div>
          <div
            className={cn(
              'w-full text-textParagraph dark:text-dark-textParagraph',
              isActive && 'text-black dark:text-white',
            )}
          >
            Inbox
          </div>
          {inboxCount > 0 && (
            <span className="flex h-5 w-5 items-center justify-center rounded-sm bg-red-500 px-1 text-xs text-white">
              {inboxCount}
            </span>
          )}
        </div>
      )}
    </NavLink>
  );
};
