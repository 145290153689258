import { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import {
  FormControl,
  FormField,
  FormInput as FormInputBase,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { cn } from '@/lib/utils';

export const FormInput = <T extends FieldValues>({
  label,
  form,
  name,
  formItemClassName,
  placeholder,
  type = 'text',
  prefix,
  onChange,
}: {
  label?: string;
  form: UseFormReturn<T>;
  name: Path<T>;
  formItemClassName?: string;
  placeholder?: string;
  type?: 'text' | 'number';
  prefix?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => {
  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field, fieldState }) => (
        <FormItem className={cn('space-y-1', formItemClassName)}>
          {label && (
            <FormLabel
              className={cn(
                'text-base font-medium text-black dark:text-white',
                fieldState.error && 'text-destructive dark:text-destructive',
              )}
            >
              {label}
            </FormLabel>
          )}
          <FormControl>
            <div className="relative">
              {prefix && (
                <span className="absolute left-3 top-1/2 -translate-y-1/2 text-black/50 dark:text-white/50">
                  {prefix}
                </span>
              )}
              <FormInputBase
                type={type}
                placeholder={placeholder}
                className={cn(
                  'hide-arrow p-3 text-base font-normal text-black shadow-sm placeholder:text-[#9E9E9E] dark:text-white dark:placeholder:text-[#AEAEAE]',
                  prefix && 'pl-7',
                  fieldState.error &&
                    'border-destructive dark:border-destructive',
                )}
                {...field}
                onChange={(e) => {
                  const value =
                    type === 'number'
                      ? e.target.value === ''
                        ? ''
                        : Number(e.target.value)
                      : e.target.value;

                  field.onChange(value);
                  onChange?.(e);
                }}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
