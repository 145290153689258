import { useQueryClient } from '@tanstack/react-query';
import { PlusIcon } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AddSpacesToSpaceGroupDto,
  CreateSpaceGroupDto,
  SpaceGroupWithSpaces,
  UpdateSpaceGroupMonetizationSettingsDto,
  getBrandsControllerGetSpaceGroupsOfBrandQueryKey,
  useSpaceGroupControllerAddSpacesToSpaceGroup,
  useSpaceGroupControllerCreateSpaceGroup,
  useSpaceGroupControllerUpdateSettings,
} from '@/api';
import { Button } from '@/components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from '@/components/ui/dialog';
import { useToast } from '@/hooks/use-toast';

import { AddSpacesDialogContent } from './add-spaces-dialog';
import { ChooseSpaceOrSpaceGroupDialogContent } from './choose-space-or-space-group-dialog';
import ConfigMonetization from './config-monetization';
import { CreateNewSpaceGroupDialogContent } from './create-new-space-group-dialog';

import Stepper from '../ui/stepper';

type Steps =
  | 'none'
  | 'choose-space-or-space-group'
  | 'create-new-space-group'
  | 'add-spaces'
  | 'monetization';

export const AddNewSpaceOrSpaceGroupDialog = ({
  spaceGroup,
}: {
  spaceGroup: SpaceGroupWithSpaces;
}) => {
  const navigate = useNavigate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [step, setStep] = useState<Steps>('none');

  const [spaceType, setSpaceType] = useState<'Space' | 'Space group'>('Space');
  const [spaceGroupData, setSpaceGroupData] = useState<
    CreateSpaceGroupDto | undefined
  >(undefined);

  const [spacesList, setSpacesList] = useState<AddSpacesToSpaceGroupDto>();
  const { toast } = useToast();

  const [monetizationData, setMonetizationData] =
    useState<UpdateSpaceGroupMonetizationSettingsDto>({
      allowDirectMessaging: false,
      messageFrequencyType: 'CAPPED',
      freeMessagesLimit: 0,
      freeMessagesFrequency: 'PER_DAY',
      allowCallMeetings: false,
      freeCallsLimit: 0,
      freeCallsFrequency: 'PER_DAY',
      freeCallDuration: 0,
      freeCallDurationType: 'MINUTES',
    });

  const brandId = spaceGroup.brandId;

  const queryClient = useQueryClient();
  const addSpacesToSpaceGroupMutation =
    useSpaceGroupControllerAddSpacesToSpaceGroup();
  const createSpaceGroupMutation = useSpaceGroupControllerCreateSpaceGroup();
  const updateSpaceGroupMonetizationMutation =
    useSpaceGroupControllerUpdateSettings();

  const chooseSpaceOrSpaceGroup = (type: 'Space' | 'Space group') => {
    if (type === 'Space') {
      setStep('add-spaces');
      setSpaceType('Space');
      return;
    }
    setStep('create-new-space-group');
    setSpaceType('Space group');
  };

  const handleContinueCreateSpaceGroup = () => {
    setStep('add-spaces');
  };

  const handleChooseSpaces = async (spaces: AddSpacesToSpaceGroupDto) => {
    setSpacesList(spaces);
    if (spaceType === 'Space group') {
      setStep('monetization');
    } else {
      handleFinish(spaces);
    }
  };

  const handleFinish = async (chosenSpaces?: AddSpacesToSpaceGroupDto) => {
    try {
      let spaceGroupId = spaceGroup.id;
      const finalSpaces = chosenSpaces ?? spacesList;

      if (spaceGroupData) {
        const res = await createSpaceGroupMutation.mutateAsync({
          data: spaceGroupData,
        });
        spaceGroupId = res.id;
      }

      await addSpacesToSpaceGroupMutation.mutateAsync({
        spaceGroupId,
        data: finalSpaces!,
      });

      if (spaceGroupData) {
        await updateSpaceGroupMonetizationMutation.mutateAsync({
          spaceGroupId,
          data: monetizationData!,
        });
      }

      await queryClient.invalidateQueries({
        queryKey: getBrandsControllerGetSpaceGroupsOfBrandQueryKey(brandId),
      });

      setIsDialogOpen(false);
      if (monetizationData.allowCallMeetings) {
        navigate(`/brands/${brandId}/settings?type=Monetization`);
      }
    } catch (err) {
      toast({
        title: 'Error',
        description: 'An error occurred while adding the spaces',
      });
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setStep('choose-space-or-space-group');
    } else {
      setStep('none');
      setSpaceGroupData(undefined);
    }
    setIsDialogOpen(open);
  };

  const handleBack = () => {
    if (spaceType === 'Space') {
      setStep('choose-space-or-space-group');
      return;
    }
    if (step === 'create-new-space-group') {
      setStep('choose-space-or-space-group');
      return;
    }
    if (step === 'add-spaces') {
      setStep('create-new-space-group');
      return;
    }
    if (step === 'monetization') {
      setStep('add-spaces');
      return;
    }
    setStep('create-new-space-group');
  };

  return (
    <Dialog open={isDialogOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild className="w-full justify-start p-2 font-medium">
        <Button
          variant="icon"
          size="icon"
          className="flex w-fit items-center justify-between rounded-lg"
          onClick={(e) => {
            e.stopPropagation();
            setStep('choose-space-or-space-group');
            setIsDialogOpen(true);
          }}
        >
          <PlusIcon className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
        {step !== 'choose-space-or-space-group' && (
          <Stepper
            current={step}
            steps={[
              { value: 'create-new-space-group', index: 1 },
              { value: 'add-spaces', index: 2 },
              { value: 'monetization', index: 3 },
            ]}
          />
        )}
        {step === 'choose-space-or-space-group' && (
          <ChooseSpaceOrSpaceGroupDialogContent
            key={isDialogOpen.toString()}
            onContinue={chooseSpaceOrSpaceGroup}
            onClose={() => setIsDialogOpen(false)}
          />
        )}
        {step === 'create-new-space-group' && (
          <CreateNewSpaceGroupDialogContent
            key={isDialogOpen.toString()}
            brandId={brandId}
            handleContinue={handleContinueCreateSpaceGroup}
            onClose={() => setIsDialogOpen(false)}
            setData={setSpaceGroupData}
            initialData={spaceGroupData}
            handleBack={handleBack}
          />
        )}
        {step === 'add-spaces' && (
          <AddSpacesDialogContent
            key={isDialogOpen.toString()}
            onChooseSpaces={handleChooseSpaces}
            onClose={() => setIsDialogOpen(false)}
            handleBack={handleBack}
            isLoading={
              addSpacesToSpaceGroupMutation.isPending ||
              createSpaceGroupMutation.isPending
            }
          />
        )}
        {step === 'monetization' && (
          <ConfigMonetization
            onClose={() => setIsDialogOpen(false)}
            handleBack={handleBack}
            isLoading={
              addSpacesToSpaceGroupMutation.isPending ||
              createSpaceGroupMutation.isPending ||
              updateSpaceGroupMonetizationMutation.isPending
            }
            updateMonetization={setMonetizationData}
            monetizationData={monetizationData!}
            handleContinue={() => handleFinish()}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
