import { EllipsisVerticalIcon, XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AutomationResponseDto,
  CreateAutomationActionDtoType,
  CreateAutomationDto,
  CreateAutomationDtoTrigger,
  useAutomationsControllerCreate,
  useAutomationsControllerFindAll,
  useAutomationsControllerRemove,
  useAutomationsControllerUpdate,
} from '@/api';
import { CopyIcon } from '@/assets/icon/copy';
import { LightningIcon } from '@/assets/icon/lightning';
import { PencilAltIcon } from '@/assets/icon/pencilAlt';
import { SwapIcon } from '@/assets/icon/swap';
import { TextCircleIcon } from '@/assets/icon/textCircle';
import { TrashAltIcon } from '@/assets/icon/trash-alt';
import { Loading } from '@/components/Loading';
import { useUpdateConfigHeader } from '@/components/config-header-provider';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Switch } from '@/components/ui/switch';
import { DateTimeFormatToStringDate } from '@/utils/dateUtils';

const WorkflowPage = () => {
  const { brandId } = useParams();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState('triggered');

  const getAutomationsMutations = useAutomationsControllerFindAll();

  const automationList: AutomationResponseDto[] =
    getAutomationsMutations.data || [];

  useUpdateConfigHeader({
    showNotifications: true,
    label: 'Workflows',
    action: null,
  });
  if (getAutomationsMutations.isLoading) return <Loading />;

  return (
    <div className="flex flex-col gap-10 px-12 pt-10">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold">Workflows</h1>
        <Button
          className="text-base font-medium"
          onClick={() => navigate(`/brands/${brandId}/workflow/create`)}
        >
          Custom workflow
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex gap-2.5">
          <Button
            variant={selectedTab === 'triggered' ? 'secondary' : 'ghost'}
            className={`px-6 py-2.5 text-base font-medium hover:bg-light-3 hover:dark:bg-dark-3 ${selectedTab === 'triggered' && 'bg-light-2 dark:bg-dark-2'}`}
            onClick={() => setSelectedTab('triggered')}
          >
            Triggered
          </Button>
          <Button
            variant={selectedTab === 'audience' ? 'secondary' : 'ghost'}
            className={`px-6 py-2.5 text-base font-medium hover:bg-light-3 hover:dark:bg-dark-3 ${selectedTab === 'audience' && 'bg-light-2 dark:bg-dark-2'}`}
            onClick={() => setSelectedTab('audience')}
            disabled
          >
            Audience
          </Button>
        </div>
        <div className="grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
          {automationList.map((automation: AutomationResponseDto) => (
            <WorkflowCard
              key={automation.id}
              automation={automation}
              refetchWorkflows={getAutomationsMutations.refetch}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

type Props = {
  refetchWorkflows: () => void;
  automation: AutomationResponseDto;
};

const WorkflowCard = ({ refetchWorkflows, automation }: Props) => {
  const navigate = useNavigate();

  const deleteAutomationMutation = useAutomationsControllerRemove();
  const createAutomationMutation = useAutomationsControllerCreate();
  const updateAutomationMutation = useAutomationsControllerUpdate();

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [name, setName] = useState('');

  useEffect(() => {
    if (isDialogOpen) setName(automation.name);
  }, [isDialogOpen]);

  const handleDelete = async () => {
    try {
      await deleteAutomationMutation
        .mutateAsync({ id: automation.id })
        .then(() => {
          console.log('delete');
          refetchWorkflows();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDuplicate = async () => {
    const payload: CreateAutomationDto = {
      name: automation.name,
      trigger: automation?.trigger! as CreateAutomationDtoTrigger,
      isActive: true,
      brandId: automation.brandId!,
      spaceGroupIds: automation?.spaceGroups.map(
        (spaceGroup) => spaceGroup.spaceGroupId,
      ),
      actions: automation?.actions.map((action) => ({
        type: action.type as CreateAutomationActionDtoType,
        subjectTemplate: action.subjectTemplate,
        messageTemplate: action.messageTemplate,
      })),
    };
    try {
      createAutomationMutation.mutateAsync(
        { data: payload },
        {
          onSuccess: () => {
            refetchWorkflows();
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleRename = async () => {
    try {
      updateAutomationMutation.mutateAsync(
        {
          id: automation.id,
          data: { ...automation, name },
        },
        {
          onSuccess: () => {
            refetchWorkflows();
            setIsDialogOpen(false);
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = () => {
    navigate(
      `/brands/${automation.brandId}/workflow/create?id=${automation.id}`,
    );
  };

  const handleToggle = async () => {
    try {
      updateAutomationMutation.mutateAsync(
        {
          id: automation.id,
          data: { ...automation, isActive: !automation.isActive },
        },
        {
          onSuccess: () => {
            refetchWorkflows();
          },
        },
      );
    } catch (error) {
      console.log(error);
    }
  };

  const closeDialog = () => {
    setName('');
    setIsDialogOpen(false);
  };

  return (
    <div className="flex flex-col gap-8 rounded-2xl bg-light-2 p-4 dark:bg-dark-2">
      <div className="flex w-full items-center justify-between">
        <Switch
          checked={automation.isActive}
          onCheckedChange={handleToggle}
          disabled={updateAutomationMutation.isPending}
        />
        <DropdownMenu>
          <DropdownMenuTrigger disabled={deleteAutomationMutation.isPending}>
            <EllipsisVerticalIcon className="h-4 w-4" />
          </DropdownMenuTrigger>
          <DropdownMenuContent side="bottom" align="start" className="min-w-40">
            <DropdownMenuItem
              className="flex items-center gap-2.5 p-2.5"
              onSelect={handleEdit}
            >
              <PencilAltIcon className="h-4 w-4 fill-black dark:fill-white" />
              Edit
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-2.5 p-2.5"
              onSelect={handleDuplicate}
            >
              <CopyIcon className="h-4 w-4 stroke-black dark:stroke-white" />
              Duplicate
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-2.5 p-2.5"
              onSelect={() => setIsDialogOpen(true)}
            >
              <TextCircleIcon className="h-4 w-4 stroke-black dark:stroke-white" />
              Rename
            </DropdownMenuItem>
            <DropdownMenuItem
              className="flex items-center gap-2.5 p-2.5"
              onSelect={handleDelete}
            >
              <TrashAltIcon className="h-4 w-4 stroke-black dark:stroke-white" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="flex flex-col gap-2.5">
        <div className="text-base font-medium">{automation.name}</div>
        <div className="text-xxs font-medium text-textParagraph dark:text-dark-textParagraph">
          Last edited:{' '}
          {DateTimeFormatToStringDate(automation.updatedAt).datePart}
        </div>
      </div>
      <div className="flex gap-6">
        <div className="flex items-start gap-1 text-sm font-medium">
          <LightningIcon className="h-4 w-4 fill-black dark:fill-white" />
          <div className="text-textParagraph dark:text-dark-textParagraph">
            Total runs
          </div>
          {automation.totalRuns}
        </div>
        <div className="flex items-start gap-1 text-sm font-medium">
          <SwapIcon className="h-4 w-4 fill-black dark:fill-white" />
          <div className="text-textParagraph dark:text-dark-textParagraph">
            Steps
          </div>
          {automation.actions.length}
        </div>
      </div>
      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-10 lg:w-1/2">
          <DialogHeader className="flex-row items-center justify-between space-y-0">
            <DialogTitle className="text-2xl font-semibold">
              Rename this automation
            </DialogTitle>
            <Button
              onClick={closeDialog}
              variant="icon"
              size="icon"
              className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
            >
              <XIcon className="h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <InputField
            type="text"
            title="Automation name"
            inputClassName="!border !border-light dark:!border-dark-light !bg-transparent"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            onClick={handleRename}
            disabled={!name || updateAutomationMutation.isPending}
            className="w-fit self-end"
          >
            Save changes
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default WorkflowPage;
