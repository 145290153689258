/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  AuthResponseDto,
  AuthResponseWithTokens,
  CometChatTokenDto,
  CreatorMonetizationSettingsDto,
  ForgotPasswordDto,
  GoogleAuthDto,
  GoogleAuthResponseDto,
  LoginUserDto,
  MeetingsSpaceGroupInfoDto,
  RegisterUserDto,
  ResetPasswordDto,
  SetupProfileDto,
  SuccessMessageResponseDto,
  UpdateCreatorMonetizationSettingsDto,
  UpdatePasswordDto,
  UpdateProfileDto,
  User,
  UserControllerCheckEmail200,
  UserControllerCheckEmailBody,
  UserControllerConfirmAccount200,
  UserControllerConfirmAccount400,
  UserControllerConfirmAccount404,
  UserControllerConfirmAccount500,
  UserControllerConfirmAccountBody,
  UserControllerForgotPassword200,
  UserControllerGetCometChatAuthTokenParams,
  UserControllerResendConfirmationEmail200,
  UserControllerResendConfirmationEmail400,
  UserControllerResendConfirmationEmail404,
  UserControllerResendConfirmationEmail500,
  UserControllerResendConfirmationEmailBody,
  UserControllerResendOtpBody,
  UserControllerResetPassword200,
  UserControllerVerifyResetToken200,
  UserRoleDto,
  UserSpaceGroupResponseDto,
  VerifyResetTokenDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * @summary Get authenticated user information
 */
export const userControllerGetAuthenticatedUser = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<User>(
    { url: `/users/me`, method: 'GET', signal },
    options,
  );
};

export const getUserControllerGetAuthenticatedUserQueryKey = () => {
  return [`/users/me`] as const;
};

export const getUserControllerGetAuthenticatedUserQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetAuthenticatedUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
  > = ({ signal }) =>
    userControllerGetAuthenticatedUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetAuthenticatedUserQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
>;
export type UserControllerGetAuthenticatedUserQueryError = void;

export function useUserControllerGetAuthenticatedUser<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAuthenticatedUser<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetAuthenticatedUser<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get authenticated user information
 */

export function useUserControllerGetAuthenticatedUser<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getUserControllerGetAuthenticatedUserQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get authenticated user information
 */
export const prefetchUserControllerGetAuthenticatedUser = async <
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getUserControllerGetAuthenticatedUserQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetAuthenticatedUserSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetAuthenticatedUserQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
  > = ({ signal }) =>
    userControllerGetAuthenticatedUser(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetAuthenticatedUserSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>
>;
export type UserControllerGetAuthenticatedUserSuspenseQueryError = void;

export function useUserControllerGetAuthenticatedUserSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAuthenticatedUserSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAuthenticatedUserSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get authenticated user information
 */

export function useUserControllerGetAuthenticatedUserSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAuthenticatedUser>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetAuthenticatedUserSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Register a new user
 */
export const userControllerRegisterNewUser = (
  registerUserDto: RegisterUserDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<AuthResponseWithTokens>(
    {
      url: `/users/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registerUserDto,
      signal,
    },
    options,
  );
};

export const getUserControllerRegisterNewUserMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRegisterNewUser>>,
    TError,
    { data: RegisterUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerRegisterNewUser>>,
  TError,
  { data: RegisterUserDto },
  TContext
> => {
  const mutationKey = ['userControllerRegisterNewUser'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerRegisterNewUser>>,
    { data: RegisterUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerRegisterNewUser(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerRegisterNewUserMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerRegisterNewUser>>
>;
export type UserControllerRegisterNewUserMutationBody = RegisterUserDto;
export type UserControllerRegisterNewUserMutationError = void;

/**
 * @summary Register a new user
 */
export const useUserControllerRegisterNewUser = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerRegisterNewUser>>,
    TError,
    { data: RegisterUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerRegisterNewUser>>,
  TError,
  { data: RegisterUserDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerRegisterNewUserMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary User login
 */
export const userControllerLogin = (
  loginUserDto: LoginUserDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<AuthResponseDto>(
    {
      url: `/users/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginUserDto,
      signal,
    },
    options,
  );
};

export const getUserControllerLoginMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerLogin>>,
    TError,
    { data: LoginUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerLogin>>,
  TError,
  { data: LoginUserDto },
  TContext
> => {
  const mutationKey = ['userControllerLogin'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerLogin>>,
    { data: LoginUserDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerLogin(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerLoginMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerLogin>>
>;
export type UserControllerLoginMutationBody = LoginUserDto;
export type UserControllerLoginMutationError = void;

/**
 * @summary User login
 */
export const useUserControllerLogin = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerLogin>>,
    TError,
    { data: LoginUserDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerLogin>>,
  TError,
  { data: LoginUserDto },
  TContext
> => {
  const mutationOptions = getUserControllerLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Confirms a user account using a confirmation token sent via email.
 * @summary Confirm user account
 */
export const userControllerConfirmAccount = (
  userControllerConfirmAccountBody: UserControllerConfirmAccountBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerConfirmAccount200>(
    {
      url: `/users/confirm-account`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userControllerConfirmAccountBody,
      signal,
    },
    options,
  );
};

export const getUserControllerConfirmAccountMutationOptions = <
  TError =
    | UserControllerConfirmAccount400
    | UserControllerConfirmAccount404
    | UserControllerConfirmAccount500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerConfirmAccount>>,
    TError,
    { data: UserControllerConfirmAccountBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerConfirmAccount>>,
  TError,
  { data: UserControllerConfirmAccountBody },
  TContext
> => {
  const mutationKey = ['userControllerConfirmAccount'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerConfirmAccount>>,
    { data: UserControllerConfirmAccountBody }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerConfirmAccount(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerConfirmAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerConfirmAccount>>
>;
export type UserControllerConfirmAccountMutationBody =
  UserControllerConfirmAccountBody;
export type UserControllerConfirmAccountMutationError =
  | UserControllerConfirmAccount400
  | UserControllerConfirmAccount404
  | UserControllerConfirmAccount500;

/**
 * @summary Confirm user account
 */
export const useUserControllerConfirmAccount = <
  TError =
    | UserControllerConfirmAccount400
    | UserControllerConfirmAccount404
    | UserControllerConfirmAccount500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerConfirmAccount>>,
    TError,
    { data: UserControllerConfirmAccountBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerConfirmAccount>>,
  TError,
  { data: UserControllerConfirmAccountBody },
  TContext
> => {
  const mutationOptions =
    getUserControllerConfirmAccountMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Resends the account confirmation email to the specified email address if the account is not yet confirmed.
 * @summary Resend account confirmation email
 */
export const userControllerResendConfirmationEmail = (
  userControllerResendConfirmationEmailBody: UserControllerResendConfirmationEmailBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerResendConfirmationEmail200>(
    {
      url: `/users/resend-confirmation-email`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userControllerResendConfirmationEmailBody,
      signal,
    },
    options,
  );
};

export const getUserControllerResendConfirmationEmailMutationOptions = <
  TError =
    | UserControllerResendConfirmationEmail400
    | UserControllerResendConfirmationEmail404
    | UserControllerResendConfirmationEmail500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>,
    TError,
    { data: UserControllerResendConfirmationEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>,
  TError,
  { data: UserControllerResendConfirmationEmailBody },
  TContext
> => {
  const mutationKey = ['userControllerResendConfirmationEmail'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>,
    { data: UserControllerResendConfirmationEmailBody }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerResendConfirmationEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerResendConfirmationEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>
>;
export type UserControllerResendConfirmationEmailMutationBody =
  UserControllerResendConfirmationEmailBody;
export type UserControllerResendConfirmationEmailMutationError =
  | UserControllerResendConfirmationEmail400
  | UserControllerResendConfirmationEmail404
  | UserControllerResendConfirmationEmail500;

/**
 * @summary Resend account confirmation email
 */
export const useUserControllerResendConfirmationEmail = <
  TError =
    | UserControllerResendConfirmationEmail400
    | UserControllerResendConfirmationEmail404
    | UserControllerResendConfirmationEmail500,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>,
    TError,
    { data: UserControllerResendConfirmationEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerResendConfirmationEmail>>,
  TError,
  { data: UserControllerResendConfirmationEmailBody },
  TContext
> => {
  const mutationOptions =
    getUserControllerResendConfirmationEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Resend OTP
 */
export const userControllerResendOtp = (
  userControllerResendOtpBody: UserControllerResendOtpBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<void>(
    {
      url: `/users/resend-otp`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userControllerResendOtpBody,
      signal,
    },
    options,
  );
};

export const getUserControllerResendOtpMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResendOtp>>,
    TError,
    { data: UserControllerResendOtpBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerResendOtp>>,
  TError,
  { data: UserControllerResendOtpBody },
  TContext
> => {
  const mutationKey = ['userControllerResendOtp'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerResendOtp>>,
    { data: UserControllerResendOtpBody }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerResendOtp(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerResendOtpMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerResendOtp>>
>;
export type UserControllerResendOtpMutationBody = UserControllerResendOtpBody;
export type UserControllerResendOtpMutationError = void;

/**
 * @summary Resend OTP
 */
export const useUserControllerResendOtp = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResendOtp>>,
    TError,
    { data: UserControllerResendOtpBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerResendOtp>>,
  TError,
  { data: UserControllerResendOtpBody },
  TContext
> => {
  const mutationOptions = getUserControllerResendOtpMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Set up a user's profile (requires authentication)
 */
export const userControllerSetupProfile = (
  setupProfileDto: SetupProfileDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<User>(
    {
      url: `/users/setup-profile`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: setupProfileDto,
    },
    options,
  );
};

export const getUserControllerSetupProfileMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerSetupProfile>>,
    TError,
    { data: SetupProfileDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerSetupProfile>>,
  TError,
  { data: SetupProfileDto },
  TContext
> => {
  const mutationKey = ['userControllerSetupProfile'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerSetupProfile>>,
    { data: SetupProfileDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerSetupProfile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerSetupProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerSetupProfile>>
>;
export type UserControllerSetupProfileMutationBody = SetupProfileDto;
export type UserControllerSetupProfileMutationError = void;

/**
 * @summary Set up a user's profile (requires authentication)
 */
export const useUserControllerSetupProfile = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerSetupProfile>>,
    TError,
    { data: SetupProfileDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerSetupProfile>>,
  TError,
  { data: SetupProfileDto },
  TContext
> => {
  const mutationOptions = getUserControllerSetupProfileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Check if an email address is already registered
 */
export const userControllerCheckEmail = (
  userControllerCheckEmailBody: UserControllerCheckEmailBody,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerCheckEmail200>(
    {
      url: `/users/check-email`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userControllerCheckEmailBody,
      signal,
    },
    options,
  );
};

export const getUserControllerCheckEmailMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerCheckEmail>>,
    TError,
    { data: UserControllerCheckEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerCheckEmail>>,
  TError,
  { data: UserControllerCheckEmailBody },
  TContext
> => {
  const mutationKey = ['userControllerCheckEmail'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerCheckEmail>>,
    { data: UserControllerCheckEmailBody }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerCheckEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerCheckEmailMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerCheckEmail>>
>;
export type UserControllerCheckEmailMutationBody = UserControllerCheckEmailBody;
export type UserControllerCheckEmailMutationError = unknown;

/**
 * @summary Check if an email address is already registered
 */
export const useUserControllerCheckEmail = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerCheckEmail>>,
    TError,
    { data: UserControllerCheckEmailBody },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerCheckEmail>>,
  TError,
  { data: UserControllerCheckEmailBody },
  TContext
> => {
  const mutationOptions = getUserControllerCheckEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all space groups associated with the authenticated user
 * @summary Get user space groups
 */
export const userControllerGetUserSpaceGroups = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserSpaceGroupResponseDto[]>(
    { url: `/users/space-groups`, method: 'GET', signal },
    options,
  );
};

export const getUserControllerGetUserSpaceGroupsQueryKey = () => {
  return [`/users/space-groups`] as const;
};

export const getUserControllerGetUserSpaceGroupsQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
  > = ({ signal }) => userControllerGetUserSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserSpaceGroupsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
>;
export type UserControllerGetUserSpaceGroupsQueryError = void;

export function useUserControllerGetUserSpaceGroups<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserSpaceGroups<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetUserSpaceGroups<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get user space groups
 */

export function useUserControllerGetUserSpaceGroups<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUserControllerGetUserSpaceGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user space groups
 */
export const prefetchUserControllerGetUserSpaceGroups = async <
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getUserControllerGetUserSpaceGroupsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetUserSpaceGroupsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserSpaceGroupsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
  > = ({ signal }) => userControllerGetUserSpaceGroups(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserSpaceGroupsSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>
>;
export type UserControllerGetUserSpaceGroupsSuspenseQueryError = void;

export function useUserControllerGetUserSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get user space groups
 */

export function useUserControllerGetUserSpaceGroupsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetUserSpaceGroups>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetUserSpaceGroupsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a user by ID
 */
export const userControllerGetCometChatAuthToken = (
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CometChatTokenDto>(
    { url: `/users/comet-chat-auth-token`, method: 'GET', params, signal },
    options,
  );
};

export const getUserControllerGetCometChatAuthTokenQueryKey = (
  params?: UserControllerGetCometChatAuthTokenParams,
) => {
  return [`/users/comet-chat-auth-token`, ...(params ? [params] : [])] as const;
};

export const getUserControllerGetCometChatAuthTokenQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetCometChatAuthTokenQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>
  > = ({ signal }) =>
    userControllerGetCometChatAuthToken(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetCometChatAuthTokenQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>
>;
export type UserControllerGetCometChatAuthTokenQueryError = void;

export function useUserControllerGetCometChatAuthToken<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params: undefined | UserControllerGetCometChatAuthTokenParams,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetCometChatAuthToken<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetCometChatAuthToken<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a user by ID
 */

export function useUserControllerGetCometChatAuthToken<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUserControllerGetCometChatAuthTokenQueryOptions(
    params,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a user by ID
 */
export const prefetchUserControllerGetCometChatAuthToken = async <
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  queryClient: QueryClient,
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getUserControllerGetCometChatAuthTokenQueryOptions(
    params,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetCometChatAuthTokenSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetCometChatAuthTokenQueryKey(params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>
  > = ({ signal }) =>
    userControllerGetCometChatAuthToken(params, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetCometChatAuthTokenSuspenseQueryResult =
  NonNullable<Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>>;
export type UserControllerGetCometChatAuthTokenSuspenseQueryError = void;

export function useUserControllerGetCometChatAuthTokenSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params: undefined | UserControllerGetCometChatAuthTokenParams,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetCometChatAuthTokenSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetCometChatAuthTokenSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get a user by ID
 */

export function useUserControllerGetCometChatAuthTokenSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
  TError = void,
>(
  params?: UserControllerGetCometChatAuthTokenParams,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetCometChatAuthToken>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetCometChatAuthTokenSuspenseQueryOptions(params, options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all users
 */
export const userControllerGetAllUsers = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<User[]>({ url: `/users`, method: 'GET', signal }, options);
};

export const getUserControllerGetAllUsersQueryKey = () => {
  return [`/users`] as const;
};

export const getUserControllerGetAllUsersQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetAllUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetAllUsers>>
  > = ({ signal }) => userControllerGetAllUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetAllUsers>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetAllUsersQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAllUsers>>
>;
export type UserControllerGetAllUsersQueryError = unknown;

export function useUserControllerGetAllUsers<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetAllUsers>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetAllUsers>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAllUsers<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetAllUsers>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetAllUsers>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetAllUsers<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all users
 */

export function useUserControllerGetAllUsers<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUserControllerGetAllUsersQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all users
 */
export const prefetchUserControllerGetAllUsers = async <
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetAllUsers>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getUserControllerGetAllUsersQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetAllUsersSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetAllUsersQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetAllUsers>>
  > = ({ signal }) => userControllerGetAllUsers(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetAllUsers>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetAllUsersSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetAllUsers>>
>;
export type UserControllerGetAllUsersSuspenseQueryError = unknown;

export function useUserControllerGetAllUsersSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAllUsersSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetAllUsersSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all users
 */

export function useUserControllerGetAllUsersSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetAllUsers>>,
  TError = unknown,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetAllUsers>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetAllUsersSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user role
 */
export const userControllerGetUserRole = (
  brandId: string,
  userId: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserRoleDto>(
    {
      url: `/users/role/${encodeURIComponent(String(brandId))}/${encodeURIComponent(String(userId))}`,
      method: 'GET',
      signal,
    },
    options,
  );
};

export const getUserControllerGetUserRoleQueryKey = (
  brandId: string,
  userId: string,
) => {
  return [`/users/role/${brandId}/${userId}`] as const;
};

export const getUserControllerGetUserRoleQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetUserRoleQueryKey(brandId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserRole>>
  > = ({ signal }) =>
    userControllerGetUserRole(brandId, userId, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!(brandId && userId),
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserRole>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserRoleQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserRole>>
>;
export type UserControllerGetUserRoleQueryError = void;

export function useUserControllerGetUserRole<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetUserRole>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetUserRole>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserRole<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetUserRole>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetUserRole>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetUserRole<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get user role
 */

export function useUserControllerGetUserRole<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUserControllerGetUserRoleQueryOptions(
    brandId,
    userId,
    options,
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get user role
 */
export const prefetchUserControllerGetUserRole = async <
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  queryClient: QueryClient,
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getUserControllerGetUserRoleQueryOptions(
    brandId,
    userId,
    options,
  );

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetUserRoleSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetUserRoleQueryKey(brandId, userId);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserRole>>
  > = ({ signal }) =>
    userControllerGetUserRole(brandId, userId, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserRole>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserRoleSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserRole>>
>;
export type UserControllerGetUserRoleSuspenseQueryError = void;

export function useUserControllerGetUserRoleSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserRoleSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserRoleSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get user role
 */

export function useUserControllerGetUserRoleSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserRole>>,
  TError = void,
>(
  brandId: string,
  userId: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserRole>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getUserControllerGetUserRoleSuspenseQueryOptions(
    brandId,
    userId,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Authenticate user with Google via Cognito
 */
export const userControllerGoogleAuth = (
  googleAuthDto: GoogleAuthDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<GoogleAuthResponseDto>(
    {
      url: `/users/google-auth`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: googleAuthDto,
      signal,
    },
    options,
  );
};

export const getUserControllerGoogleAuthMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerGoogleAuth>>,
    TError,
    { data: GoogleAuthDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerGoogleAuth>>,
  TError,
  { data: GoogleAuthDto },
  TContext
> => {
  const mutationKey = ['userControllerGoogleAuth'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerGoogleAuth>>,
    { data: GoogleAuthDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerGoogleAuth(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerGoogleAuthMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGoogleAuth>>
>;
export type UserControllerGoogleAuthMutationBody = GoogleAuthDto;
export type UserControllerGoogleAuthMutationError = void;

/**
 * @summary Authenticate user with Google via Cognito
 */
export const useUserControllerGoogleAuth = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerGoogleAuth>>,
    TError,
    { data: GoogleAuthDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerGoogleAuth>>,
  TError,
  { data: GoogleAuthDto },
  TContext
> => {
  const mutationOptions = getUserControllerGoogleAuthMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update user profile
 */
export const userControllerUpdateProfile = (
  updateProfileDto: UpdateProfileDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/users/update-profile`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateProfileDto,
    },
    options,
  );
};

export const getUserControllerUpdateProfileMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateProfile>>,
    TError,
    { data: UpdateProfileDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateProfile>>,
  TError,
  { data: UpdateProfileDto },
  TContext
> => {
  const mutationKey = ['userControllerUpdateProfile'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateProfile>>,
    { data: UpdateProfileDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerUpdateProfile(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateProfileMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdateProfile>>
>;
export type UserControllerUpdateProfileMutationBody = UpdateProfileDto;
export type UserControllerUpdateProfileMutationError = void;

/**
 * @summary Update user profile
 */
export const useUserControllerUpdateProfile = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateProfile>>,
    TError,
    { data: UpdateProfileDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateProfile>>,
  TError,
  { data: UpdateProfileDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdateProfileMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Initiates the password reset process by sending an email with a reset token.
 * @summary Request password reset email
 */
export const userControllerForgotPassword = (
  forgotPasswordDto: ForgotPasswordDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerForgotPassword200>(
    {
      url: `/users/forgot-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: forgotPasswordDto,
      signal,
    },
    options,
  );
};

export const getUserControllerForgotPasswordMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerForgotPassword>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerForgotPassword>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const mutationKey = ['userControllerForgotPassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerForgotPassword>>,
    { data: ForgotPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerForgotPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerForgotPassword>>
>;
export type UserControllerForgotPasswordMutationBody = ForgotPasswordDto;
export type UserControllerForgotPasswordMutationError = void;

/**
 * @summary Request password reset email
 */
export const useUserControllerForgotPassword = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerForgotPassword>>,
    TError,
    { data: ForgotPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerForgotPassword>>,
  TError,
  { data: ForgotPasswordDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Verifies the validity of the reset token before allowing password reset.
 * @summary Verify reset token
 */
export const userControllerVerifyResetToken = (
  verifyResetTokenDto: VerifyResetTokenDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerVerifyResetToken200>(
    {
      url: `/users/verify-reset-token`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: verifyResetTokenDto,
      signal,
    },
    options,
  );
};

export const getUserControllerVerifyResetTokenMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerVerifyResetToken>>,
    TError,
    { data: VerifyResetTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerVerifyResetToken>>,
  TError,
  { data: VerifyResetTokenDto },
  TContext
> => {
  const mutationKey = ['userControllerVerifyResetToken'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerVerifyResetToken>>,
    { data: VerifyResetTokenDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerVerifyResetToken(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerVerifyResetTokenMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerVerifyResetToken>>
>;
export type UserControllerVerifyResetTokenMutationBody = VerifyResetTokenDto;
export type UserControllerVerifyResetTokenMutationError = void;

/**
 * @summary Verify reset token
 */
export const useUserControllerVerifyResetToken = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerVerifyResetToken>>,
    TError,
    { data: VerifyResetTokenDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerVerifyResetToken>>,
  TError,
  { data: VerifyResetTokenDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerVerifyResetTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Resets the user's password using the provided token.
 * @summary Reset password
 */
export const userControllerResetPassword = (
  resetPasswordDto: ResetPasswordDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<UserControllerResetPassword200>(
    {
      url: `/users/reset-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: resetPasswordDto,
      signal,
    },
    options,
  );
};

export const getUserControllerResetPasswordMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    TError,
    { data: ResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerResetPassword>>,
  TError,
  { data: ResetPasswordDto },
  TContext
> => {
  const mutationKey = ['userControllerResetPassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    { data: ResetPasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerResetPassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerResetPassword>>
>;
export type UserControllerResetPasswordMutationBody = ResetPasswordDto;
export type UserControllerResetPasswordMutationError = void;

/**
 * @summary Reset password
 */
export const useUserControllerResetPassword = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerResetPassword>>,
    TError,
    { data: ResetPasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerResetPassword>>,
  TError,
  { data: ResetPasswordDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * @summary Update user password
 */
export const userControllerUpdatePassword = (
  updatePasswordDto: UpdatePasswordDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/users/update-password`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updatePasswordDto,
    },
    options,
  );
};

export const getUserControllerUpdatePasswordMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdatePassword>>,
    TError,
    { data: UpdatePasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdatePassword>>,
  TError,
  { data: UpdatePasswordDto },
  TContext
> => {
  const mutationKey = ['userControllerUpdatePassword'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdatePassword>>,
    { data: UpdatePasswordDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerUpdatePassword(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdatePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof userControllerUpdatePassword>>
>;
export type UserControllerUpdatePasswordMutationBody = UpdatePasswordDto;
export type UserControllerUpdatePasswordMutationError = void;

/**
 * @summary Update user password
 */
export const useUserControllerUpdatePassword = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdatePassword>>,
    TError,
    { data: UpdatePasswordDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdatePassword>>,
  TError,
  { data: UpdatePasswordDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdatePasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Updates the messaging and call settings for a creator
 * @summary Update creator monetization settings
 */
export const userControllerUpdateMonetizationSettings = (
  updateCreatorMonetizationSettingsDto: UpdateCreatorMonetizationSettingsDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/users/monetization-settings`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateCreatorMonetizationSettingsDto,
    },
    options,
  );
};

export const getUserControllerUpdateMonetizationSettingsMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>,
    TError,
    { data: UpdateCreatorMonetizationSettingsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>,
  TError,
  { data: UpdateCreatorMonetizationSettingsDto },
  TContext
> => {
  const mutationKey = ['userControllerUpdateMonetizationSettings'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>,
    { data: UpdateCreatorMonetizationSettingsDto }
  > = (props) => {
    const { data } = props ?? {};

    return userControllerUpdateMonetizationSettings(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UserControllerUpdateMonetizationSettingsMutationResult =
  NonNullable<
    Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>
  >;
export type UserControllerUpdateMonetizationSettingsMutationBody =
  UpdateCreatorMonetizationSettingsDto;
export type UserControllerUpdateMonetizationSettingsMutationError = void;

/**
 * @summary Update creator monetization settings
 */
export const useUserControllerUpdateMonetizationSettings = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>,
    TError,
    { data: UpdateCreatorMonetizationSettingsDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof userControllerUpdateMonetizationSettings>>,
  TError,
  { data: UpdateCreatorMonetizationSettingsDto },
  TContext
> => {
  const mutationOptions =
    getUserControllerUpdateMonetizationSettingsMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves the messaging and call settings for a creator
 * @summary Get creator monetization settings
 */
export const userControllerGetMonetizationSettings = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<CreatorMonetizationSettingsDto>(
    { url: `/users/monetization-settings`, method: 'GET', signal },
    options,
  );
};

export const getUserControllerGetMonetizationSettingsQueryKey = () => {
  return [`/users/monetization-settings`] as const;
};

export const getUserControllerGetMonetizationSettingsQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetMonetizationSettingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
  > = ({ signal }) =>
    userControllerGetMonetizationSettings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetMonetizationSettingsQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
>;
export type UserControllerGetMonetizationSettingsQueryError = void;

export function useUserControllerGetMonetizationSettings<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMonetizationSettings<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetMonetizationSettings<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get creator monetization settings
 */

export function useUserControllerGetMonetizationSettings<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getUserControllerGetMonetizationSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get creator monetization settings
 */
export const prefetchUserControllerGetMonetizationSettings = async <
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getUserControllerGetMonetizationSettingsQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetMonetizationSettingsSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetMonetizationSettingsQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
  > = ({ signal }) =>
    userControllerGetMonetizationSettings(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetMonetizationSettingsSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>
  >;
export type UserControllerGetMonetizationSettingsSuspenseQueryError = void;

export function useUserControllerGetMonetizationSettingsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMonetizationSettingsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMonetizationSettingsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get creator monetization settings
 */

export function useUserControllerGetMonetizationSettingsSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMonetizationSettings>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetMonetizationSettingsSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a user by ID
 */
export const userControllerGetUserById = (
  id: string,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<User>(
    { url: `/users/${encodeURIComponent(String(id))}`, method: 'GET', signal },
    options,
  );
};

export const getUserControllerGetUserByIdQueryKey = (id: string) => {
  return [`/users/${id}`] as const;
};

export const getUserControllerGetUserByIdQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserById>>
  > = ({ signal }) => userControllerGetUserById(id, requestOptions, signal);

  return {
    queryKey,
    queryFn,
    enabled: !!id,
    ...queryOptions,
  } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserByIdQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserById>>
>;
export type UserControllerGetUserByIdQueryError = void;

export function useUserControllerGetUserById<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    > &
      Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetUserById>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetUserById>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserById<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    > &
      Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof userControllerGetUserById>>,
          TError,
          Awaited<ReturnType<typeof userControllerGetUserById>>
        >,
        'initialData'
      >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetUserById<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get a user by ID
 */

export function useUserControllerGetUserById<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions = getUserControllerGetUserByIdQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get a user by ID
 */
export const prefetchUserControllerGetUserById = async <
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  queryClient: QueryClient,
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions = getUserControllerGetUserByIdQueryOptions(id, options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetUserByIdSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getUserControllerGetUserByIdQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetUserById>>
  > = ({ signal }) => userControllerGetUserById(id, requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetUserById>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetUserByIdSuspenseQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetUserById>>
>;
export type UserControllerGetUserByIdSuspenseQueryError = void;

export function useUserControllerGetUserByIdSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options: {
    query: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserByIdSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetUserByIdSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get a user by ID
 */

export function useUserControllerGetUserByIdSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetUserById>>,
  TError = void,
>(
  id: string,
  options?: {
    query?: Partial<
      UseSuspenseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetUserById>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions = getUserControllerGetUserByIdSuspenseQueryOptions(
    id,
    options,
  );

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  Get meetings space group info
 */
export const userControllerGetMeetingsSpaceGroupInfo = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<MeetingsSpaceGroupInfoDto>(
    { url: `/users/meetings-space-group-info`, method: 'GET', signal },
    options,
  );
};

export const getUserControllerGetMeetingsSpaceGroupInfoQueryKey = () => {
  return [`/users/meetings-space-group-info`] as const;
};

export const getUserControllerGetMeetingsSpaceGroupInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetMeetingsSpaceGroupInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
  > = ({ signal }) =>
    userControllerGetMeetingsSpaceGroupInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetMeetingsSpaceGroupInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
>;
export type UserControllerGetMeetingsSpaceGroupInfoQueryError = void;

export function useUserControllerGetMeetingsSpaceGroupInfo<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMeetingsSpaceGroupInfo<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
        TError,
        Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useUserControllerGetMeetingsSpaceGroupInfo<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary  Get meetings space group info
 */

export function useUserControllerGetMeetingsSpaceGroupInfo<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getUserControllerGetMeetingsSpaceGroupInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary  Get meetings space group info
 */
export const prefetchUserControllerGetMeetingsSpaceGroupInfo = async <
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getUserControllerGetMeetingsSpaceGroupInfoQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getUserControllerGetMeetingsSpaceGroupInfoSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getUserControllerGetMeetingsSpaceGroupInfoQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
  > = ({ signal }) =>
    userControllerGetMeetingsSpaceGroupInfo(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type UserControllerGetMeetingsSpaceGroupInfoSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>
  >;
export type UserControllerGetMeetingsSpaceGroupInfoSuspenseQueryError = void;

export function useUserControllerGetMeetingsSpaceGroupInfoSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMeetingsSpaceGroupInfoSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useUserControllerGetMeetingsSpaceGroupInfoSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary  Get meetings space group info
 */

export function useUserControllerGetMeetingsSpaceGroupInfoSuspense<
  TData = Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof userControllerGetMeetingsSpaceGroupInfo>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getUserControllerGetMeetingsSpaceGroupInfoSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}
