import { CheckIcon } from '@/assets/icon/check-alt';
import { CloseIcon } from '@/assets/icon/close';

type Props = {
  status: string;
  title: string;
  message: string;
  bottomComponent?: React.ReactNode | string;
};

const StatusMessage = ({ status, title, message, bottomComponent }: Props) => {
  return (
    <div className="flex w-[450px] flex-col items-center gap-10 rounded-4xl bg-white p-10 dark:bg-dark-1">
      {status === 'success' && (
        <div className="flex h-25 w-25 flex-shrink-0 items-center justify-center rounded-full bg-green-50">
          <CheckIcon className="h-12 w-12 stroke-green-100" />
        </div>
      )}
      {status === 'error' && (
        <div className="flex h-25 w-25 flex-shrink-0 items-center justify-center rounded-full bg-red-50">
          <CloseIcon className="h-12 w-12 stroke-error" />
        </div>
      )}
      <div className="flex flex-col items-center gap-2.5">
        <h1 className="text-2xl font-semibold">{title}</h1>
        <p className="text-sm font-normal text-textParagraph dark:text-dark-textParagraph">
          {message}
        </p>
      </div>
      {bottomComponent}
    </div>
  );
};

export default StatusMessage;
