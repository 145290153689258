import { XIcon } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useCoursesSpacesControllerCreateChapter } from '@/api';
import { PlusIcon } from '@/assets/icon/plusIcon';
import { TrashAltIcon } from '@/assets/icon/trash-alt';
import { useToast } from '@/hooks/use-toast';

import InputField from '../InputField';
import { Button } from '../button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../dialog';

const AddChaptersDialog = ({ refetch }: { refetch: () => void }) => {
  const { courseId } = useParams();

  const createChapterMutation = useCoursesSpacesControllerCreateChapter();

  const [open, setOpen] = useState(false);
  const [chaptersToAdd, setChaptersToAdd] = useState<string[]>([]);
  const [name, setName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (open) setChaptersToAdd([]);
  }, [open]);

  const { toast } = useToast();

  const handleAddChapter = () => {
    if (chaptersToAdd.length === 0) {
      toast({
        title: 'Error',
        description: 'Please add at least one chapter',
      });
      return;
    }
    try {
      setIsLoading(true);
      chaptersToAdd.forEach((chapterName, i) => {
        createChapterMutation.mutate(
          {
            courseId: courseId!,
            data: {
              name: chapterName,
            },
          },
          {
            onSuccess: () => {
              if (i === chaptersToAdd.length - 1) {
                refetch();
                setChaptersToAdd(['']);
                setOpen(false);
                setIsLoading(false);
              }
            },
          },
        );
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex justify-end">
        <Button className="flex w-fit items-center gap-2">
          <PlusIcon className="h-4 w-4 flex-shrink-0 stroke-white" />
          Add chapters
        </Button>
      </DialogTrigger>
      <DialogContent className="flex w-[90%] max-w-3xl flex-col gap-9 rounded-3xl md:w-3/4 md:p-12 lg:w-1/2">
        <DialogHeader className="flex-row items-center justify-between space-y-0">
          <DialogTitle className="text-2xl font-semibold">
            Add chapters
          </DialogTitle>
          <Button
            onClick={onClose}
            variant="icon"
            size="icon"
            className="m-0 rounded-sm p-0 ring-offset-background transition-opacity focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
          >
            <XIcon className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </Button>
        </DialogHeader>
        <DialogDescription className="flex flex-col gap-4 text-black dark:text-white">
          <InputField
            type="text"
            title="Chapter name"
            inputClassName="!border !border-light dark:!border-dark-light !bg-transparent"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            type="button"
            onClick={() => {
              setChaptersToAdd([...chaptersToAdd, name]);
              setName('');
            }}
            className="flex gap-2"
            disabled={!name}
          >
            <PlusIcon className="h-4 w-4 flex-shrink-0 stroke-black dark:stroke-white" />
            Add new
          </Button>
          {chaptersToAdd.map((chapterName, i) => (
            <div className="flex items-center justify-between text-lg font-medium">
              {chapterName}
              <TrashAltIcon
                className="h-5 w-5 flex-shrink-0 cursor-pointer stroke-black dark:stroke-white"
                onClick={() => {
                  const newChapters = [...chaptersToAdd];
                  newChapters.splice(i, 1);
                  setChaptersToAdd(newChapters);
                }}
              />
            </div>
          ))}
        </DialogDescription>
        <DialogFooter className="flex flex-row justify-end gap-2">
          <Button
            variant="outline"
            onClick={() => {
              setOpen(false);
            }}
            type="button"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button onClick={handleAddChapter} type="button" disabled={isLoading}>
            Add chapters
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AddChaptersDialog;
