import { useState } from 'react';

import {
  useUserControllerResetPassword,
  useUserControllerVerifyResetToken,
} from '@/api';
import { lockIcon } from '@/assets/icon';
import InputField from '@/components/ui/InputField';
import { Button } from '@/components/ui/button';

interface VerifyEmailProps {
  handleVerifyEmail: () => void;
  verificationCode: string;
  setVerificationCode: (verificationCode: string) => void;
  // handleResendCode: () => void;
  //hola adios
  email: string;
  error: string;
  requestError: string;
  isLoading: boolean;
}

type Props = {
  email: string;
  goBackToLogin: () => void;
};

const VerifyEmail = ({
  verificationCode,
  setVerificationCode,
  error,
  requestError,
  email,
  isLoading,
  handleVerifyEmail,
  // handleResendCode,
}: VerifyEmailProps) => {
  // const [resendDisabled, setResendDisabled] = useState(false);
  // const [countdown, setCountdown] = useState(0);

  // useEffect(() => {
  //   let timer: NodeJS.Timeout;
  //   if (countdown > 0) {
  //     timer = setTimeout(() => setCountdown(countdown - 1), 1000);
  //   } else if (countdown === 0) {
  //     setResendDisabled(false);
  //   }
  //   return () => clearTimeout(timer);
  // }, [countdown]);

  // const handleResendWithDelay = () => {
  //   handleResendCode();
  //   setResendDisabled(true);
  //   setCountdown(15);
  // };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleVerifyEmail();
      }}
      className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:border-dark-light dark:bg-dark-white sm:w-125 sm:p-10"
    >
      <div className="rounded-full bg-primary-50 p-4">
        <img src={lockIcon} className="h-12 w-12" alt="lock icon" />
      </div>
      <div>
        <h1 className="text-center text-2xl font-bold text-black dark:text-white">
          Check your email
        </h1>
        <p className="text-center text-base font-normal text-textParagraph dark:text-dark-textParagraph">
          We sent a verification code to{' '}
          <b className="font-bold text-black dark:text-white">{email}</b>
        </p>
      </div>
      <InputField
        title="Verification code"
        placeholder="e.g. 000000"
        onChange={(e) => setVerificationCode(e.target.value)}
        value={verificationCode}
        error={error}
        type="text"
      />
      {requestError && (
        <p className="w-full rounded-md bg-destructive p-4 text-center text-white">
          {requestError}
        </p>
      )}
      <div className="flex flex-col text-center text-textParagraph dark:text-dark-textParagraph">
        <p>Code expires in 5 minutes</p>
        {/* <p>
          Didn&apos;t recieve the email?{' '}
          <Button
            type="button"
            variant={'none'}
            onClick={handleResendWithDelay}
            className="p-0 text-base font-normal text-brand"
            disabled={isLoading || resendDisabled}
          >
            {resendDisabled ? `Resend in ${countdown}s` : 'Click to resend'}
          </Button>
        </p> */}
      </div>
      <div className="flex w-full flex-col gap-6">
        <Button
          type="submit"
          variant="default"
          className="w-full p-7 text-lg font-normal"
          disabled={isLoading}
        >
          Verify email
        </Button>
      </div>
    </form>
  );
};

const InputNewPassword = ({
  goBackToLogin,
  verificationCode,
  email,
}: {
  goBackToLogin: () => void;
  verificationCode: string;
  email: string;
}) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [errorConfirm, setErrorConfirm] = useState('');

  const resetPasswordMutation = useUserControllerResetPassword();

  const handleSetNewPassword = async () => {
    setErrorConfirm('');
    setError('');
    if (!newPassword) {
      setError('Please enter a new password');
      return;
    }
    if (newPassword.length < 8) {
      setError('Password must be at least 8 characters long');
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorConfirm('Passwords do not match');
      return;
    }

    try {
      await resetPasswordMutation.mutateAsync({
        data: { otpCode: verificationCode, email, newPassword },
      });

      // Redirect to login page
      goBackToLogin();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSetNewPassword();
      }}
      className="flex w-full flex-col items-center gap-10 rounded-2xl border border-light bg-white px-4 py-6 shadow dark:border-dark-light dark:bg-dark-white sm:w-125 sm:p-10"
    >
      <div className="rounded-full bg-primary-50 p-4">
        <img src={lockIcon} className="h-12 w-12" alt="lock icon" />
      </div>
      <div>
        <h1 className="text-center text-2xl font-bold text-black dark:text-white">
          Set a new password
        </h1>
        <p className="text-center text-base font-normal text-textParagraph dark:text-dark-textParagraph">
          Make sure it's at least 8 characters long
        </p>
      </div>
      <InputField
        title="New password"
        placeholder="Enter your new password"
        onChange={(e) => setNewPassword(e.target.value)}
        value={newPassword}
        error={error}
        type="password"
      />
      <InputField
        title="Repeat new password"
        placeholder="Enter your new password"
        onChange={(e) => setConfirmPassword(e.target.value)}
        value={confirmPassword}
        error={errorConfirm}
        type="password"
      />
      <div className="flex w-full flex-col gap-6">
        <Button
          type="submit"
          variant="default"
          className="w-full p-7 text-lg font-normal"
          disabled={resetPasswordMutation.isPending}
        >
          Change password
        </Button>
      </div>
    </form>
  );
};

const ForgotPasswordPage = ({ email, goBackToLogin }: Props) => {
  const [requestError, setRequestError] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const [step, setStep] = useState(0);

  const verifyResetTokenMutation = useUserControllerVerifyResetToken();

  const handleVerifyEmail = async () => {
    setRequestError('');
    setVerificationError('');

    if (!verificationCode) {
      setVerificationError('Please enter the verification code');
      return;
    }

    try {
      const response = await verifyResetTokenMutation.mutateAsync({
        data: { otpCode: verificationCode, email },
      });

      if (response.valid) {
        setStep(1);
      } else {
        setVerificationError('Invalid verification code');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {step === 0 && (
        <VerifyEmail
          verificationCode={verificationCode}
          setVerificationCode={setVerificationCode}
          error={verificationError}
          requestError={requestError}
          email={email}
          isLoading={verifyResetTokenMutation.isPending}
          handleVerifyEmail={handleVerifyEmail}
          // handleResendCode={() => {}}
        />
      )}
      {step === 1 && (
        <InputNewPassword
          goBackToLogin={goBackToLogin}
          verificationCode={verificationCode}
          email={email}
        />
      )}
    </div>
  );
};

export default ForgotPasswordPage;
