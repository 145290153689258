/**
 * Generated by orval v7.5.0 🍺
 * Do not edit manually.
 * Teachly API V2
 * Teachly API documentation
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query';
import type {
  DataTag,
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryClient,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
  UseSuspenseQueryOptions,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';

import { customFetch } from '../../../mutator/custom-fetch';
import type {
  ChatsSpace,
  CreateChatsSpaceDto,
  SuccessMessageResponseDto,
  UpdateChatsSpaceDto,
} from '../../model';

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Creates a new chat space within a specific space group and its corresponding group in CometChat.
 * @summary Create a new chat space
 */
export const chatsSpacesControllerCreateChatsSpace = (
  createChatsSpaceDto: CreateChatsSpaceDto,
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<ChatsSpace>(
    {
      url: `/chats-spaces`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createChatsSpaceDto,
      signal,
    },
    options,
  );
};

export const getChatsSpacesControllerCreateChatsSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>,
    TError,
    { data: CreateChatsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>,
  TError,
  { data: CreateChatsSpaceDto },
  TContext
> => {
  const mutationKey = ['chatsSpacesControllerCreateChatsSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>,
    { data: CreateChatsSpaceDto }
  > = (props) => {
    const { data } = props ?? {};

    return chatsSpacesControllerCreateChatsSpace(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChatsSpacesControllerCreateChatsSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>
>;
export type ChatsSpacesControllerCreateChatsSpaceMutationBody =
  CreateChatsSpaceDto;
export type ChatsSpacesControllerCreateChatsSpaceMutationError = void;

/**
 * @summary Create a new chat space
 */
export const useChatsSpacesControllerCreateChatsSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>,
    TError,
    { data: CreateChatsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof chatsSpacesControllerCreateChatsSpace>>,
  TError,
  { data: CreateChatsSpaceDto },
  TContext
> => {
  const mutationOptions =
    getChatsSpacesControllerCreateChatsSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Retrieves all chats spaces associated with a specific user.
 * @summary Get all chats spaces for a user
 */
export const chatsSpacesControllerGetUserChatsSpaces = (
  options?: SecondParameter<typeof customFetch>,
  signal?: AbortSignal,
) => {
  return customFetch<ChatsSpace[]>(
    { url: `/chats-spaces/my`, method: 'GET', signal },
    options,
  );
};

export const getChatsSpacesControllerGetUserChatsSpacesQueryKey = () => {
  return [`/chats-spaces/my`] as const;
};

export const getChatsSpacesControllerGetUserChatsSpacesQueryOptions = <
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChatsSpacesControllerGetUserChatsSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
  > = ({ signal }) =>
    chatsSpacesControllerGetUserChatsSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ChatsSpacesControllerGetUserChatsSpacesQueryResult = NonNullable<
  Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
>;
export type ChatsSpacesControllerGetUserChatsSpacesQueryError = void;

export function useChatsSpacesControllerGetUserChatsSpaces<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options: {
  query: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
        TError,
        Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): DefinedUseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useChatsSpacesControllerGetUserChatsSpaces<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
        TError,
        Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
      >,
      'initialData'
    >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
export function useChatsSpacesControllerGetUserChatsSpaces<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> };
/**
 * @summary Get all chats spaces for a user
 */

export function useChatsSpacesControllerGetUserChatsSpaces<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseQueryResult<TData, TError> & { queryKey: DataTag<QueryKey, TData> } {
  const queryOptions =
    getChatsSpacesControllerGetUserChatsSpacesQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: DataTag<QueryKey, TData>;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * @summary Get all chats spaces for a user
 */
export const prefetchChatsSpacesControllerGetUserChatsSpaces = async <
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(
  queryClient: QueryClient,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
        TError,
        TData
      >
    >;
    request?: SecondParameter<typeof customFetch>;
  },
): Promise<QueryClient> => {
  const queryOptions =
    getChatsSpacesControllerGetUserChatsSpacesQueryOptions(options);

  await queryClient.prefetchQuery(queryOptions);

  return queryClient;
};

export const getChatsSpacesControllerGetUserChatsSpacesSuspenseQueryOptions = <
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getChatsSpacesControllerGetUserChatsSpacesQueryKey();

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
  > = ({ signal }) =>
    chatsSpacesControllerGetUserChatsSpaces(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseSuspenseQueryOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
    TError,
    TData
  > & { queryKey: DataTag<QueryKey, TData> };
};

export type ChatsSpacesControllerGetUserChatsSpacesSuspenseQueryResult =
  NonNullable<
    Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>
  >;
export type ChatsSpacesControllerGetUserChatsSpacesSuspenseQueryError = void;

export function useChatsSpacesControllerGetUserChatsSpacesSuspense<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options: {
  query: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useChatsSpacesControllerGetUserChatsSpacesSuspense<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
export function useChatsSpacesControllerGetUserChatsSpacesSuspense<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
};
/**
 * @summary Get all chats spaces for a user
 */

export function useChatsSpacesControllerGetUserChatsSpacesSuspense<
  TData = Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
  TError = void,
>(options?: {
  query?: Partial<
    UseSuspenseQueryOptions<
      Awaited<ReturnType<typeof chatsSpacesControllerGetUserChatsSpaces>>,
      TError,
      TData
    >
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseSuspenseQueryResult<TData, TError> & {
  queryKey: DataTag<QueryKey, TData>;
} {
  const queryOptions =
    getChatsSpacesControllerGetUserChatsSpacesSuspenseQueryOptions(options);

  const query = useSuspenseQuery(queryOptions) as UseSuspenseQueryResult<
    TData,
    TError
  > & { queryKey: DataTag<QueryKey, TData> };

  query.queryKey = queryOptions.queryKey;

  return query;
}

/**
 * Updates an chats space for the authenticated user.
 * @summary Update an chats space
 */
export const chatsSpacesControllerUpdateChatsSpace = (
  chatsSpaceId: string,
  updateChatsSpaceDto: UpdateChatsSpaceDto,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<ChatsSpace>(
    {
      url: `/chats-spaces/${encodeURIComponent(String(chatsSpaceId))}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: updateChatsSpaceDto,
    },
    options,
  );
};

export const getChatsSpacesControllerUpdateChatsSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>,
    TError,
    { chatsSpaceId: string; data: UpdateChatsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>,
  TError,
  { chatsSpaceId: string; data: UpdateChatsSpaceDto },
  TContext
> => {
  const mutationKey = ['chatsSpacesControllerUpdateChatsSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>,
    { chatsSpaceId: string; data: UpdateChatsSpaceDto }
  > = (props) => {
    const { chatsSpaceId, data } = props ?? {};

    return chatsSpacesControllerUpdateChatsSpace(
      chatsSpaceId,
      data,
      requestOptions,
    );
  };

  return { mutationFn, ...mutationOptions };
};

export type ChatsSpacesControllerUpdateChatsSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>
>;
export type ChatsSpacesControllerUpdateChatsSpaceMutationBody =
  UpdateChatsSpaceDto;
export type ChatsSpacesControllerUpdateChatsSpaceMutationError = void;

/**
 * @summary Update an chats space
 */
export const useChatsSpacesControllerUpdateChatsSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>,
    TError,
    { chatsSpaceId: string; data: UpdateChatsSpaceDto },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof chatsSpacesControllerUpdateChatsSpace>>,
  TError,
  { chatsSpaceId: string; data: UpdateChatsSpaceDto },
  TContext
> => {
  const mutationOptions =
    getChatsSpacesControllerUpdateChatsSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * Deletes a chats space for the authenticated user.
 * @summary Delete a chats space
 */
export const chatsSpacesControllerDeleteChatsSpace = (
  chatSpaceId: string,
  options?: SecondParameter<typeof customFetch>,
) => {
  return customFetch<SuccessMessageResponseDto>(
    {
      url: `/chats-spaces/${encodeURIComponent(String(chatSpaceId))}`,
      method: 'DELETE',
    },
    options,
  );
};

export const getChatsSpacesControllerDeleteChatsSpaceMutationOptions = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>,
    TError,
    { chatSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>,
  TError,
  { chatSpaceId: string },
  TContext
> => {
  const mutationKey = ['chatsSpacesControllerDeleteChatsSpace'];
  const { mutation: mutationOptions, request: requestOptions } = options
    ? options.mutation &&
      'mutationKey' in options.mutation &&
      options.mutation.mutationKey
      ? options
      : { ...options, mutation: { ...options.mutation, mutationKey } }
    : { mutation: { mutationKey }, request: undefined };

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>,
    { chatSpaceId: string }
  > = (props) => {
    const { chatSpaceId } = props ?? {};

    return chatsSpacesControllerDeleteChatsSpace(chatSpaceId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ChatsSpacesControllerDeleteChatsSpaceMutationResult = NonNullable<
  Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>
>;

export type ChatsSpacesControllerDeleteChatsSpaceMutationError = void;

/**
 * @summary Delete a chats space
 */
export const useChatsSpacesControllerDeleteChatsSpace = <
  TError = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>,
    TError,
    { chatSpaceId: string },
    TContext
  >;
  request?: SecondParameter<typeof customFetch>;
}): UseMutationResult<
  Awaited<ReturnType<typeof chatsSpacesControllerDeleteChatsSpace>>,
  TError,
  { chatSpaceId: string },
  TContext
> => {
  const mutationOptions =
    getChatsSpacesControllerDeleteChatsSpaceMutationOptions(options);

  return useMutation(mutationOptions);
};
