export const SettingsIcon = (props) => (
  <svg
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.16667 3.83268C2.16667 3.14232 2.72631 2.58268 3.41667 2.58268C4.10702 2.58268 4.66667 3.14232 4.66667 3.83268C4.66667 4.52304 4.10702 5.08268 3.41667 5.08268C2.72631 5.08268 2.16667 4.52304 2.16667 3.83268ZM3.41667 0.916016C1.80583 0.916016 0.5 2.22185 0.5 3.83268C0.5 5.44352 1.80583 6.74935 3.41667 6.74935C5.0275 6.74935 6.33333 5.44352 6.33333 3.83268C6.33333 2.22185 5.0275 0.916016 3.41667 0.916016ZM8 4.66602H14.6667V2.99935H8V4.66602ZM11.3333 12.166C11.3333 11.4757 11.893 10.916 12.5833 10.916C13.2737 10.916 13.8333 11.4757 13.8333 12.166C13.8333 12.8563 13.2737 13.416 12.5833 13.416C11.893 13.416 11.3333 12.8563 11.3333 12.166ZM12.5833 9.24935C10.9725 9.24935 9.66667 10.5552 9.66667 12.166C9.66667 13.7768 10.9725 15.0827 12.5833 15.0827C14.1942 15.0827 15.5 13.7768 15.5 12.166C15.5 10.5552 14.1942 9.24935 12.5833 9.24935ZM1.33333 11.3327V12.9993H8V11.3327H1.33333Z" />
  </svg>
);
